<div class="content">
  <div class="contact">
    <div class="title">Kapcsolat</div>
    <div class="items">
      <div class="item">
        <a [href]="'mailto:' + emailAddress">
          {{ emailAddress }}
        </a>
      </div>
      <div class="item">
        <a [href]="'tel:' + phoneNumber">
          {{ displayedPhoneNumber }}
        </a>
      </div>
      <div class="item">
        <a [routerLink]="'/gyik/'"> GYIK </a>
      </div>
    </div>
  </div>
  <div class="social">


    <div>
      <a style="height:48px;width:48px;display:inline-block;" href="https://www.instagram.com/mrkresz">
        <img loading="lazy" alt="Mr. Kresz instagram" src="assets/footer/instagram-logo.webp" class="social-logo" />
      </a>
      <a style="height:48px;width:48px; display:inline-block;" href="https://www.facebook.com/mrkresz">
        <img loading="lazy" alt="Mr. Kresz facebook" src="assets/footer/facebook-logo.webp" class="social-logo" />
      </a>
      <a style="height:48px;width:48px; display:inline-block;" href="https://www.tiktok.com/@mrkresz">
        <img loading="lazy" alt="TikTok" src="assets/footer/tiktok-logo.webp" class="social-logo" />
      </a>
    </div>

    <div style="margin-bottom:20px;" class="blog-button-wrapper">
      <a class="cta" href="https://mrkresz.hu/blog/">
        <span>Blog</span>
      </a>
    </div>

  </div>
  <div class="information">
    <div class="title">Információ</div>
    <div class="items">
      <div class="item">
        <a [routerLink]="'/impresszum'" target="_blank"> Impresszum </a>
      </div>
      <div class="item">
        <a [routerLink]="'/feltetelek'" target="_blank">
          Felhasználási feltételek
        </a>
      </div>
      <div class="item">
        <a [routerLink]="'/adatvedelem'" target="_blank">
          Adatkezelési szabályzat
        </a>
      </div>
    </div>
  </div>
  <div class="company">
    <div class="company-name">© {{ year }} Magyar Digitális Oktatás Kft.</div>
    <div>Minden jog fenntartva.</div>
  </div>
</div>