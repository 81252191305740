<div class = "grid-wrapper" [ngClass] = "sideMediaAlignment">
    <div class = "main-content-container">
        <div class = "title">
            <ng-content select = "[title]"></ng-content>
        </div>
        <div class = "subtitle">
            <ng-content select = "[subtitle]"></ng-content>
        </div>
        <div>
            <ng-content select = "[misc]"></ng-content>
        </div>
    </div>
    <div class = "side-media-container">
       <ng-content select = "[side-media]"></ng-content>
    </div>
</div>
