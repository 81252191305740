import { ChangeDetectorRef, Component, createNgModuleRef, ElementRef, Injector, NgModuleRef, NgZone, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { awaitFor } from 'src/app/modules/shared/functions/misc-functions';

gsap.registerPlugin(ScrollTrigger);

type Timeline = gsap.core.Timeline;

@Component({
  selector: 'app-showcase-with-phone-section',
  templateUrl: './showcase-with-phone-section.component.html',
  styleUrls: ['./showcase-with-phone-section.component.scss']
})
export class ShowcaseWithPhoneSectionComponent implements OnInit {
  steps = [
    {
      camera: {
        "position": {
          "x": -3.76186649009189,
          "y": 0.6719913557374588,
          "z": 6.411577834350576
        },
        "rotation": {
          "x": -0.10458764386842827,
          "y": -0.5308709536243325,
          "z": -0.05309508381520991
        }
      },
      phone: {
        "rotationX": -0.227,
        "rotationY": 3.125063,
        "rotationZ": -0.023
      }
    },


    {
      camera: {
        "position": {
          "x": 0.7822258039799735,
          "y": 0.6089347430754484,
          "z": 7.397895743141132
        },
        "rotation": {
          "x": -0.08212674253855769,
          "y": 0.10499237248924222,
          "z": 0.008626037285792086
        }
      },
      phone: {
        "rotationX": -0.227,
        "rotationY": 3.125063,
        "rotationZ": -0.023
      }
    },

    {
      camera: {
        "position": {
          "x": 0.7822258039799735,
          "y": 0.6089347430754484,
          "z": 7.397895743141132
        },
        "rotation": {
          "x": -0.08212674253855769,
          "y": 0.10499237248924222,
          "z": 0.008626037285792086
        }
      },
      phone: {
        "rotationX": -0.362,
        "rotationY": 2.607,
        "rotationZ": 0.114
      }
    },

  ];


  mockupUrls = [
    "assets/practical-sales-page/iphone/mockup_picture2.webp",
    "assets/practical-sales-page/iphone/mockup_picture4.webp",
    "assets/practical-sales-page/iphone/mockup_picture3.webp",
  ];

  readonly mockupOpacity: BehaviorSubject<number> = new BehaviorSubject(1);
  readonly mockupRotation: BehaviorSubject<{ rotationX: number, rotationY: number, rotationZ: number }> = new BehaviorSubject(this.steps[0].phone);
  readonly mockupCameraSettings = new BehaviorSubject(this.steps[0].camera);

  @ViewChild('mobileMockupNgContainer', { static: false, read: ViewContainerRef })
  mobileMockupNgContainer: ViewContainerRef;

  @ViewChild("item0", { read: ElementRef, }) item0: ElementRef<HTMLDivElement>;
  @ViewChild("item1", { read: ElementRef, }) item1: ElementRef<HTMLDivElement>;
  @ViewChild("item2", { read: ElementRef, }) item2: ElementRef<HTMLDivElement>;

  textAnimationState = [
    {
      subheadOpacity: 1,
      textOpacity: 1,
      y: 0,
    },
    {
      subheadOpacity: 0,
      textOpacity: 0,
      y: 20,
    },
    {
      subheadOpacity: 0,
      textOpacity: 0,
      y: 20,
    }
  ];

  mockupLazilyLoaded = false;
  startFirstTimer = false;

  currentStepWaitId = 0;
  iphoneTimeline: gsap.core.Timeline;

  textureIndex: BehaviorSubject<number> = new BehaviorSubject(2);

  currentProgress = 0; // piros progress bar

  intersectionObservers: IntersectionObserver[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private element: ElementRef,
    private ngZone: NgZone,
    private injector: Injector,
  ) { }

  ngOnInit() {

  }



  changeDet() {
    console.log("Change detection: ShowCaseWithPhoneSection");
  }

  onDestory() {
    this.intersectionObservers.forEach((observer) => {
      observer.disconnect();
    });
  }


  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.addAutoStepper();

      // init mockup with first step
      this.mockupRotation.next(this.steps[0].phone);
      this.mockupCameraSettings.next(this.steps[0].camera);
      this.initTextAnimationState();

      // Lazy loading-olunk 500px-el a viewport előtt
      this.intersectionObservers.push(new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (!this.mockupLazilyLoaded) {
            this.lazyLoadMockup();
          }
        }
      }, { rootMargin: "0px 0px 500px 0px" }));

      // Akkor indítjuk a stepper countdown-t, ha a komponens teljesen beért a viewport-ba
      this.intersectionObservers.push(new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if(!this.startFirstTimer) {
            this.startFirstTimer = true;
            this.resetTimer(0, 6000, 0);
          }
        }
      }));

      this.intersectionObservers.forEach((observer) => {
        observer.observe(this.element.nativeElement)
      });
    });
  }

  async lazyLoadMockup() {
    this.mockupLazilyLoaded = true;

    import('../../../../../modules/three-mockup/three-mockup.module').then(async (importedFile) => {
      const module = importedFile.ThreeMockupModule;
      const targetComponent = module.components.mobileMockupComponent
      const moduleRef = createNgModuleRef(module, this.injector);

      const mockupLoaderService = moduleRef.injector.get(module.services.mockupLoaderService);


      const texturesPromise = [];
      for (const mockupUrl of this.mockupUrls) {
        texturesPromise.push(mockupLoaderService.loadTextureForMockupAndCache(mockupUrl));
      }
      const textures = await Promise.all(texturesPromise);


      const componentRef = this.mobileMockupNgContainer.createComponent(targetComponent, { ngModuleRef: moduleRef });

      componentRef.instance.textureIndex = this.textureIndex;
      componentRef.instance.textures = textures;
      componentRef.instance.currentPhoneRotation = this.mockupRotation;
      componentRef.instance.cameraSettings = this.mockupCameraSettings;
      componentRef.instance.mockupOpacity = this.mockupOpacity;

      this.changeDetectorRef.detectChanges();
    });
  }

  initTextAnimationState() {
    const items = [this.item0, this.item1, this.item2];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const textAnimationState = this.textAnimationState[i];

      const subhead = item.nativeElement.querySelector(".subhead");
      const text = item.nativeElement.querySelector(".text");

      gsap.set(subhead, {
        y: textAnimationState.y,
        opacity: textAnimationState.subheadOpacity,
      });

      gsap.set(text, {
        y: textAnimationState.y,
        opacity: textAnimationState.textOpacity,
      });
    }
  }


  addAutoStepper() {
    const animatedProperties = {
      rotationX: this.mockupRotation.value.rotationX,
      rotationY: this.mockupRotation.value.rotationY,
      rotationZ: this.mockupRotation.value.rotationZ,
      cameraPositionX: this.mockupCameraSettings.value.position.x,
      cameraPositionY: this.mockupCameraSettings.value.position.y,
      cameraPositionZ: this.mockupCameraSettings.value.position.z,
      cameraRotationX: this.mockupCameraSettings.value.rotation.x,
      cameraRotationY: this.mockupCameraSettings.value.rotation.y,
      cameraRotationZ: this.mockupCameraSettings.value.rotation.z,
    }

    // Create animation timeline
    this.iphoneTimeline = gsap.timeline({
      paused: true
    });

    // ADD IPHONE TO TIMELINE
    const items = [this.item0, this.item1, this.item2];

    for (let i = 2; i < this.steps.length; i++) {
      const step = this.steps[i];

      this.iphoneTimeline.to(animatedProperties,
        {
          rotationX: step.phone.rotationX,
          rotationY: step.phone.rotationY,
          rotationZ: step.phone.rotationZ,
          cameraPositionX: step.camera.position.x,
          cameraPositionY: step.camera.position.y,
          cameraPositionZ: step.camera.position.z,
          cameraRotationX: step.camera.rotation.x,
          duration: 3,
          cameraRotationY: step.camera.rotation.y,
          cameraRotationZ: step.camera.rotation.z,
          onUpdate: () => {
            this.mockupRotation.next({
              rotationX: animatedProperties.rotationX,
              rotationY: animatedProperties.rotationY,
              rotationZ: animatedProperties.rotationZ,
            });

            const currentValue: typeof this.mockupCameraSettings.value = JSON.parse(JSON.stringify(this.mockupCameraSettings.value));

            this.mockupCameraSettings.next({
              position: {
                x: animatedProperties.cameraPositionX,
                y: animatedProperties.cameraPositionY,
                z: animatedProperties.cameraPositionZ,
              },
              rotation: {
                x: animatedProperties.cameraRotationX,
                y: animatedProperties.cameraRotationY,
                z: animatedProperties.cameraRotationZ,
              }
            });
          }
        }, `>`,);
      // Az előző anim vége előtt váltsunk
    }


    // text timeline
    const textAnimationTimeline = gsap.timeline({ paused: true });
    this.iphoneTimeline.eventCallback("onUpdate", () => {
      // csak egy on update callback adható hozzá...
      // szóval itt csináljuk a textúra animot is
      const currentPhoneTimeline = this.iphoneTimeline.progress();

      if (currentPhoneTimeline < 0.3) {
        this.textureIndex.next(2);
      }

      if (currentPhoneTimeline > 0.3 && currentPhoneTimeline < 0.7) {
        this.textureIndex.next(1);
      }

      if (currentPhoneTimeline > 0.7) {
        this.textureIndex.next(0);
      }

      textAnimationTimeline.progress(currentPhoneTimeline);
    });

    for (let i = 1; i < items.length; i++) {
      textAnimationTimeline.to(
        this.textAnimationState[i - 1],
        {
          subheadOpacity: 0,
          delay: 0.4,
          duration: 1,
          textOpacity: 0,
          y: -20,
          ease: "power2.inOut",
          onUpdate: () => {
            // Query class with subhead and text from items[i-1]
            const subhead = items[i - 1].nativeElement.querySelector(".subhead") as HTMLSpanElement;
            const text = items[i - 1].nativeElement.querySelector(".text") as HTMLSpanElement;
            const progressbar = items[i - 1].nativeElement.querySelector(".progress-wrapper") as HTMLSpanElement;

            // Set opacity and y

            subhead.style.opacity = this.textAnimationState[i - 1].subheadOpacity.toString();
            text.style.opacity = this.textAnimationState[i - 1].textOpacity.toString();
            progressbar.style.opacity = this.textAnimationState[i - 1].textOpacity.toString();

            subhead.style.transform = `translateY(${this.textAnimationState[i - 1].y}px)`;
            text.style.transform = `translateY(${this.textAnimationState[i - 1].y}px)`;
            progressbar.style.transform = `translateY(${this.textAnimationState[i - 1].y}px)`;
          }
        }, ">",);

      textAnimationTimeline.to(
        this.textAnimationState[i],

        {
          subheadOpacity: 1,
          textOpacity: 1,
          duration: 2,
          y: 0,
          ease: "power2.inOut",
          onUpdate: () => {
            // Query class with subhead and text from items[i-1]
            const subhead = items[i].nativeElement.querySelector(".subhead") as HTMLSpanElement;
            const text = items[i].nativeElement.querySelector(".text") as HTMLSpanElement;
            const progressbar = items[i].nativeElement.querySelector(".progress-wrapper") as HTMLSpanElement;



            // Set opacity and y

            subhead.style.opacity = this.textAnimationState[i].subheadOpacity.toString();
            text.style.opacity = this.textAnimationState[i].textOpacity.toString();
            progressbar.style.opacity = this.textAnimationState[i].subheadOpacity.toString();

            subhead.style.transform = `translateY(${this.textAnimationState[i].y}px)`;
            text.style.transform = `translateY(${this.textAnimationState[i].y}px)`;
            progressbar.style.transform = `translateY(${this.textAnimationState[i].y}px)`;
          }
        }, "<40%",);
    }
  }

  // Az adott step-hez reseteli a timer-t
  // Azaz az adott step-en történő várakozáshoz indít egy timer-t
  resetTimer(forStep: number, durationMs: number, delayInMs: number) {
    if (this.currentAutoStepTimer) {
      clearTimeout(this.currentAutoStepTimer);
    }
    this.currentAutoStepGsapAnim?.kill();

    const items = [this.item0, this.item1, this.item2];
    const activeProgressbar = items[forStep].nativeElement.querySelector(".active") as HTMLElement;

    activeProgressbar.style.width = "0%";

    this.currentAutoStepGsapAnim = gsap.to(activeProgressbar.style, {
      width: "100%",
      duration: durationMs / 1000,
      delay: delayInMs / 1000,
      ease: "linear"
    })

    this.currentAutoStepTimer = setTimeout(() => {
      this.goNextShowcase(1.5, 1);
    }, durationMs + delayInMs);
  }

  currentAutoStepTimer: any;
  currentAutoStepGsapAnim: any;
  currentStep = 0;
  async goNextShowcase(animationDurationSec: number, animationDurationLastToFirstSec: number) {
    while (this.isInViewportPartOfTheComponent() == false) {
      await awaitFor(800);
    };

    let goToFirstFromEnd = false;
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
    } else {
      goToFirstFromEnd = true;
      this.currentStep = 0;
    }

    const animTimeToNextStep = goToFirstFromEnd ? animationDurationLastToFirstSec : animationDurationSec;
    const waitTimeMs = 6000;

    // és elindítjuk újra
    this.resetTimer(this.currentStep, waitTimeMs, animTimeToNextStep * 1000 - 400);

    if (this.currentStep == 0) {
      gsap.to(this.iphoneTimeline, { progress: 0, duration: animTimeToNextStep, ease: "power2.out" });
    }
    if (this.currentStep == 1) {
      gsap.to(this.iphoneTimeline, { progress: 0.44, duration: animTimeToNextStep, ease: "power2.out" });
    }
    if (this.currentStep == 2) {
      gsap.to(this.iphoneTimeline, { progress: 1, duration: animTimeToNextStep, ease: "power2.out" });
    }
  }

  onTapPhone() {
    this.goNextShowcase(1.5, 1);
  }


  isInViewportPartOfTheComponent() {
    // At least 70% of the component must be in the viewport

    const rect = this.element.nativeElement.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

    const overlapX = Math.max(0, Math.min(rect.right, windowWidth) - Math.max(rect.left, 0)) / rect.width;
    const overlapY = Math.max(0, Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0)) / rect.height;

    return overlapX > 0.25 && overlapY > 0.25;
  }


}
