import { Component, ViewEncapsulation } from '@angular/core';


// Erre azért van szükség, mert a default angular.json-ben adjuk meg a styles.scss
// mint globális css file-t, akkor a szerver oldali renderelés során (és a kliesn oldalinál se egyébként)
// nem kerül bele a pre-renderelt index.html-be, hanem teljesen külön tölti le styles.scss-ként (egy különálló http kérés során)
// így jár el a kliens oldali renderelés is, tehát nem kerül bele a main.js-be

// issue: https://github.com/angular/universal/issues/974
// solution (az issue-ban ajánlottak): https://github.com/Angular-RU/universal-starter/commit/dbb413b5422f23ba50b812522168ae7497b5d9ef

@Component({
  selector: 'app-inline-global-style',
  templateUrl: './inline-global-style.component.html',
  styleUrls: ['./inline-global-style.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InlineGlobalStyleComponent {

}
