<div class="main-container">
  <div class="flexbox">
    <div style="height: 35px">
      <!-- Placeholder for the close button -->
    </div>

    <div class="video-container-wrapper">
      <div class="video-container">
        <iframe
          style="width: 100%; height: 100%;background-color:transparent;"
          [src]="data.videoUrl + '?autoplay=1' | safe: 'resourceUrl'"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <span mat-dialog-close class="close-button"> × </span>
      </div>
    </div>
  </div>
</div>
