import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GlobalSassVariableProviderService } from 'src/app/modules/shared/services/global-sass-variable-provider.service';
import { PlatformService } from 'src/app/services/common/platform-service';
import { TiktokVideo } from '../../../models/tiktok-video.model';
import { TiktokVideoService } from '../../../services/tiktok-video.service';
import { GlobalScreenBreakpointProviderService } from '../../shared/services/global-srceen-breakpoint-provider.service';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("welcomeSection", { read: ElementRef }) welcomeSectionElement: ElementRef<HTMLElement>;
  @ViewChild("practiceSection", { read: ElementRef }) practiceSectionElement: ElementRef<HTMLElement>;
  @ViewChild("iconScroll", { read: ElementRef }) iconScrollElement: ElementRef<HTMLDivElement>;

  practiceTitleElement: HTMLDivElement | null = null;

  resizeObserver: ResizeObserver;

  tiktokVideos: Array<TiktokVideo> = [];

  shouldDisplayTiktokVideosSection: boolean = false;

  shouldShowScrollIcon: boolean = false;

  constructor(
    private globalScreenBreakpointProviderService: GlobalScreenBreakpointProviderService,
    private tiktokVideoService: TiktokVideoService,
    @Inject(DOCUMENT) private document: Document,
    private changeDetectorRef: ChangeDetectorRef,
    private platformService: PlatformService
  ) {
    if (this.platformService.getIsPlatformBrowser()) {
      this.resizeObserver = new ResizeObserver(this.handleScreenResize);
    }
  }

  public ngOnInit(): void {
    // Initialize the tiktok videos
    this.initializeTiktokVideos();
  }

  /**
   *  Initializes the tiktok videos. If the initialization fails the tiktok videos will be an empty array and
   * the titkok videos section will be not shown.
   */
  private async initializeTiktokVideos(): Promise<void> {
    try {
      this.tiktokVideos = await this.tiktokVideoService.getTiktokVideos();
    } catch (error: any) {
      // If an error happened during the tiktok videos query, they will not be visible on the page.
      // In this case we wouldn't want to show any error messages for the users so just for develompent
      // and debug propuses we log the error to the console.
      console.error(error);
    }

    this.shouldDisplayTiktokVideosSection = this.determineShouldDisplayTiktokVideosSection();
  }

  public ngAfterViewInit(): void {
    if (this.platformService.getIsPlatformBrowser()) {
      // Add an event listener to scrolling (to remove the scroll icon on scrolling)
      window.addEventListener("scroll", this.windowScrollEventHandler);
      this.resizeObserver.observe(document.body);
      
      this.showIconScrollElementIfNeeded();
    }
  }

  showIconScrollElementIfNeeded() {
    if (this.platformService.getIsPlatformServer()) return;

    // Calculate that the entire first section is shown
    const firstSectionBoundingClientRect: DOMRect = this.welcomeSectionElement.nativeElement.getBoundingClientRect();
    const isFirstSectionShown: boolean = firstSectionBoundingClientRect.bottom <= window.innerHeight;

    // Calculate that the second section's title is entirely shown
    const practiceTitleBoundingClientRect: DOMRect = this.practiceTitleElement.getBoundingClientRect();
    const isPracticeTitleShown: boolean = practiceTitleBoundingClientRect.y + practiceTitleBoundingClientRect.height < window.innerHeight;

    // We need to display the scoll icon if
    // The first section is shown, but the second section's title is not
    if (window.scrollY === 0 && isFirstSectionShown && !isPracticeTitleShown) {
      this.shouldShowScrollIcon = true;
    } else {
      this.shouldShowScrollIcon = false;
    }

    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
  }

  /**
   *  Callback to store the second section's title element.
   * 
   * @param titleElement the second section title element
   */
  public handleRenderedTitle(titleElement: HTMLDivElement): void {
    this.practiceTitleElement = titleElement;
  }

  private handleScreenResize = () => {
    const bodyWidth: number = this.document.body.clientWidth;

    if (bodyWidth < 1000) {
      this.iconScrollElement.nativeElement.style.display = "none";
    }

    this.shouldDisplayTiktokVideosSection = this.determineShouldDisplayTiktokVideosSection();

    this.changeDetectorRef.detectChanges();
  }

  /**
   * Determines if the tiktok videos section should be shown:
   *  - when the sreensize is a mobile-like size and there are at least one video
   *  - when the sreensize is a tablet-like size and there are at least six videos
   *  - when the sreensize is a desktop-like size and there are at least eight videos
   * 
   * @returns true if the section should be shown, false otherwise
   */
  private determineShouldDisplayTiktokVideosSection(): boolean {
    if (this.platformService.getIsPlatformServer()) return false;

    const bodyWidth: number = this.document.body.clientWidth;

    // We display the section on mobile size if there are any videos
    const shouldDisplayOnMobile: boolean =
      bodyWidth < 600 &&
      this.tiktokVideos.length > 0;

    // We display the section on tablet size if there are atleast 6 videos
    const shouldDisplayOnTablet =
      bodyWidth >= 600 &&
      bodyWidth <= this.globalScreenBreakpointProviderService.getTabletMaxWidthInPx() &&
      this.tiktokVideos.length >= 6;

    // We display the section on desktop size if there are atleast 8 videos
    const shouldDisplayOnDesktop =
      bodyWidth >= this.globalScreenBreakpointProviderService.getLaptopMinWidthInPx() &&
      this.tiktokVideos.length >= 8;

    return shouldDisplayOnMobile || shouldDisplayOnTablet || shouldDisplayOnDesktop;
  }

  private windowScrollEventHandler = () => {
    // If the scroll icon is visible ...
    if (this.iconScrollElement.nativeElement.classList.contains("hidden") === false) {
      // ... make it hidden
      this.iconScrollElement.nativeElement.classList.add("hidden");
    }

    // Remove the scrolling event listener
    window.removeEventListener("scroll", this.windowScrollEventHandler);
  };

  public handleScrollIconClick(): void {
    // Scroll slightly above the top of the target element
    window.scrollTo({ top: this.practiceSectionElement.nativeElement.getBoundingClientRect().y - 20, left: 0, behavior: "smooth" });
  }

}
