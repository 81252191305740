import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { HeaderService } from './header.component.service';
import { PlatformService } from 'src/app/services/common/platform-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private headerMobileMenuElement: ElementRef<HTMLElement>;
  /** Animation length of the mobil header menu */
  private menuAnimationLength: number = 250;

  @ViewChild('headerMobileMenu', { static: false, read: ElementRef }) set headerMobileMenu(headerMobileMenu: ElementRef<HTMLElement>) {
    if (headerMobileMenu) {
      this.headerMobileMenuElement = headerMobileMenu;
    }
  }

  public readonly menuItems: ReadonlyArray<MenuItem> = [
    { displayedName: "Vizsgaútvonalak", route: "vizsgautvonalak-videon", mobileMenuPosition: 3, text: "Az összes vizsgaútvonal videón." },
    { displayedName: "KRESZ TESZT", route: "kresz-teszt-app", mobileMenuPosition: 4, text: "Legújabb KRESZ kérdések magyarázattal." },
    { displayedName: "Classroom", route: "classroom", mobileMenuPosition: 5, text: "Interaktív KRESZ tesztelés autósiskoláknak." },
    { displayedName: "Partnereknek", route: "partnereknek", mobileMenuPosition: 6, text: "Legyél a hivatalos viszonteladónk!" },
    { displayedName: "Csomagbeváltás", route: "bevaltas", mobileMenuPosition: 2, text: "Itt tudod a vásárolt csomagot beváltani." },
    { displayedName: "Vásárlás", route: "vasarlas", mobileMenuPosition: 1, highlighted: true, text: "Hozzáférés vásárlása az alkalmazáshoz." },
  ];

  isMenuOpen: boolean = false;

  resizeObserver: ResizeObserver | undefined;

  constructor(
    private changeDetector: ChangeDetectorRef,
    protected headerService: HeaderService,
    private renderer: Renderer2,
    private platformService: PlatformService
  ) {
    if (this.platformService.getIsPlatformBrowser()) {
      this.resizeObserver = new ResizeObserver(() => {
        // If we are in small view and the menu is opened, close it automatically on resize
        setTimeout(() => {
          if (this.isMenuOpen && innerWidth >= 1000) {
            this.isMenuOpen = false;
            this.headerMobileMenuElement.nativeElement.style.opacity = "0%";
          }
        });
      });
    }

  }
  ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
  }

  ngOnInit(): void {
    this.resizeObserver?.observe(document.querySelector("body"));
    this.headerService.colorMode.subscribe((colorMode) => {
      this.changeDetector.detectChanges();
    });
  }

  public async openMobileMenu(): Promise<void> {
    if (!this.isMenuOpen) {
      this.isMenuOpen = true;
      // After the change detection the menu element will be available
      this.changeDetector.detectChanges();

      // Initialize the element properties for the animation
      this.headerMobileMenuElement.nativeElement.style.opacity = "0%";

      this.headerMobileMenuElement.nativeElement.style.transition = `all ${this.menuAnimationLength}ms`
      this.headerMobileMenuElement.nativeElement.clientWidth; // triggering a reflow

      // By removing the class, start the opening animation
      this.headerMobileMenuElement.nativeElement.style.opacity = "100%";
    }
  }

  public handleMenuClosedEvent(): void {
    if (this.isMenuOpen) {
      // By adding the class, start the closing animation
      this.headerMobileMenuElement.nativeElement.style.opacity = "0%";

      // At the end of the animation "remove" the element from the DOM
      setTimeout(() => {
        this.isMenuOpen = false;
      }, this.menuAnimationLength);
    }
  }

}

export class MenuItem {
  /** The visble name of the menupoint */
  displayedName: string;
  /** The route of the menupoint to navigate to */
  route: string;
  /** Position of the menupoint in the mobile menu */
  mobileMenuPosition: number;
  /** Determines if the menupoint is highlighted */
  highlighted?: boolean;

  text: string;
}