<div class = "title">
    Információ
</div>
<mat-dialog-content>
    <div class = "description-text">
        {{  data.descriptionLine.text }}
    </div>
    <div class = "description-explanation">
        {{  data.descriptionLine.explanation }}
    </div>
</mat-dialog-content>
<div class = "dialog-actions">
    <button
        blackButton
        class = "dialog-close-button"
        mat-dialog-close
    >
        Bezár
    </button>
</div>