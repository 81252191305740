import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[inputWithSeparator]'
})
export class InputWithSeparatorDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() separatorCharacter:string = "-";
  @Input() separatorPositionIndex:number = Infinity;

  private previousInputValue:string = "";
  private formControlValueChangeSubscription:Subscription;

  private maxInputLengthWithSeparator:number = Infinity;
  private maxInputLengthWithoutSeparator:number = Infinity;

  private inputSelectionStart:number = 0;
  private inputSelectionEnd:number = 0;

  private replaceCharactersInInputInProgress:boolean = false;

  constructor(
    @Inject(DOCUMENT) private document:Document,
    private formControl:NgControl,
    private inputElementRef:ElementRef<HTMLInputElement>,
  ) {}

  public ngOnInit():void {
    // Subscribe to the document selection change
    this.document.addEventListener("selectionchange", this.onSelectionChange);

    // Subscribe to the form control value change
    this.formControlValueChangeSubscription = this.formControl.control.valueChanges.subscribe(
      this.onFormControlValueChange
    );
  }

  /**
   * Handles the selection change event. It gets the selection start and end from the input field.
   */
  private onSelectionChange = () => {
    this.inputSelectionStart = this.inputElementRef.nativeElement.selectionStart ?? 0;
    this.inputSelectionEnd = this.inputElementRef.nativeElement.selectionEnd ?? 0;
  }

  /**
   * Handles the value change event of the form control.
   */
  private onFormControlValueChange:() => void = () => {
    // If the value change was triggered from the `replaceCharactersInInput` method
    if(this.replaceCharactersInInputInProgress) {
      // There is nothing to do
      return;
    }

    // Otherwise we captured an external change. If the change is triggered from an input or clipboard
    // event, we would like to process it with these functions. The `setTimeout` makes sure that the
    // `onInput` and `onPaste` method runs before this logic, so we can skip the handling.
    setTimeout(this.setValueFromFormControl);
  }

  /**
   * Sets the input's value from the form control.
   */
  private setValueFromFormControl = () => {
    // Get the actual value from the form control
    let value:string = this.formControl.control.value ?? "";

    // If the value is the same as the previous value, there is no need to do anything, because it
    // means that the change is irrelevant or already processed (by the `onInput` or the `onPaste`)
    if(value === this.previousInputValue) {
      // So there is nothing to do
      return;
    }

    // Check if the separator in the right place
    if(value[this.separatorPositionIndex] === this.separatorCharacter) {
      // If so, remove the separator (it will be readded)
      value = this.getStringWithDeletedRange(value, this.separatorPositionIndex, this.separatorPositionIndex + 1);
    }

    // Remove the extra charaters, if there is any
    value = value.substring(0, this.maxInputLengthWithoutSeparator);

    // Replace the entire input with the new value
    this.replaceCharactersInInput(0, Infinity, value);
  }

  public ngAfterViewInit(): void {
    // Set the maximum lengths (with and without the separator)
    this.setMaxLengths();

    // Set and format the value from the form control (this can modify the form control's value)
    this.setValueFromFormControl();
  }

  /**
   * Get the max length of the input and sets the related memeber variables.
   */
  private setMaxLengths():void {
    const inputMaxLength:number = this.inputElementRef.nativeElement.maxLength;
    if(inputMaxLength) {
      this.maxInputLengthWithSeparator = inputMaxLength;
      this.maxInputLengthWithoutSeparator = inputMaxLength - 1;
    }
  }

  public ngOnDestroy():void {
    this.formControlValueChangeSubscription?.unsubscribe();
    this.document.removeEventListener("selectionchange", this.onSelectionChange);
  }

  /**
   * Handles the input event of the input element. On the backward and forward deletions it adjusts
   * the selection to inculde the charater which will be removed.
   * Note: In the actual construct the paste event is handled elsewhere.
   * 
   * @param inputEvent the input event
   */
  @HostListener("input", [ "$event" ])
  private onInput(inputEvent:InputEvent):void {
    let inputSelectionStart:number = this.inputSelectionStart;
    let inputSelectionEnd:number = this.inputSelectionEnd;
    let newCharacters:string = inputEvent.data ?? "";
  
    // If the input originated from a paste event ...
    if(inputEvent.inputType === "insertFromPaste") {
      // Handle it with the onPaste function
      this.onPaste(inputSelectionStart, inputSelectionEnd, newCharacters);
      return;
    }

    // If the previous input value was at max length and the separator is selected ...
    if(
      this.previousInputValue.length === this.maxInputLengthWithSeparator &&
      inputSelectionStart === this.separatorPositionIndex &&
      inputSelectionEnd === this.separatorPositionIndex + 1
    ) {
      // Ignore the input chars
      newCharacters = "";
    }

    // If there is no selection
    if(inputSelectionEnd - inputSelectionStart === 0) {
      // We check if an deletion is happened, if so, modify the selection accordingly
      switch(inputEvent.inputType) {
        // If backward deletion happened (via backspace) select the character before the caret
        case "deleteContentBackward":
          --inputSelectionStart;
          // If the separator is became selected, move the selection before the separator
          if(inputSelectionStart === this.separatorPositionIndex) {
            --inputSelectionStart;
            --inputSelectionEnd;
          }
          break;
        // If forward deletion happened (via delete) select the character after the caret
        case "deleteContentForward":
          ++inputSelectionEnd;
          // If the separator is became selected, move the selection after the separator
          if(inputSelectionStart === this.separatorPositionIndex) {
            ++inputSelectionStart;
            ++inputSelectionEnd;
          }
          break;
      } 
    }

    this.replaceCharactersInInput(inputSelectionStart, inputSelectionEnd, newCharacters);
  }

  /**
   * Handles the paste event of the input element. It handles if the resulting value would have the
   * separator at the right spot and removes the extra characters from the pasted value's end if they won't
   * fit into the input (would exceed the max length).
   * 
   * @param inputSelectionStart the selection start of the input
   * @param inputSelectionEnd the selection end of the input
   * @param pastedText the pasted text
   */
  private onPaste(inputSelectionStart:number, inputSelectionEnd:number, pastedText:string):void {
    // Remove the invalid charaters from the pasted characters (case insensitive)
    pastedText = pastedText.replace(/[^A-Z0-9-]/gi, "");

    // Check if the pasted value has the separator charater in it
    if(pastedText.includes(this.separatorCharacter)) {
      // If so, simulate the replacement (remove the selected section and insert the new charaters)
      let value:string = this.getStringWithDeletedRange(this.previousInputValue, inputSelectionStart, inputSelectionEnd);
      value = this.insertCharatersAtIndex(value, pastedText, inputSelectionStart);
      // If the separator character from the pasted value is in the right spot
      if(
        inputSelectionStart <= this.separatorPositionIndex &&
        inputSelectionStart + pastedText.length > this.separatorPositionIndex &&
        value[this.separatorPositionIndex] === this.separatorCharacter
      ) {
        // Remove it from the new charaters (the replaceCharactersInInput fuction will provide it)
        const separatorIndexInNewCharaters:number = this.separatorPositionIndex - inputSelectionStart;
        pastedText = this.getStringWithDeletedRange(pastedText, separatorIndexInNewCharaters, separatorIndexInNewCharaters + 1);
      }
    }

    // Remove the characters from the new charaters which won't fit into the input
    const lengthBeforePasteWithoutSeparator:number = this.previousInputValue.length - (
      this.previousInputValue.length > this.separatorPositionIndex ? 1 : 0
    );
    const inputSelectionSize:number = (inputSelectionEnd - inputSelectionStart);
    const numberOfRemovedChararactersWithoutSeparator:number = inputSelectionSize - (
      inputSelectionStart <= this.separatorPositionIndex && inputSelectionEnd > this.separatorPositionIndex ? 1 : 0
    );
    const numberOfCharactersInInputAfterRemove:number = lengthBeforePasteWithoutSeparator - numberOfRemovedChararactersWithoutSeparator;
    const maxNumberOfInsertableCharaters:number = this.maxInputLengthWithoutSeparator - numberOfCharactersInInputAfterRemove;
    const cappedNumberOfInsertableCharaters:number = Math.min(
      pastedText.length,
      maxNumberOfInsertableCharaters
    );
    pastedText = pastedText.substring(0, cappedNumberOfInsertableCharaters);

    this.replaceCharactersInInput(inputSelectionStart, inputSelectionEnd, pastedText);
  }

  /**
   * Replaces the given section with the provided new characters. It puts the separator in the right spot if the
   * resulting value requires it, and updates the caret position to the end of the newly inserted charaters.
   * 
   * @param removeSectionStart the start position of the removed section (inclusive)
   * @param removeSectionEnd the end position of the removed section (exclusive)
   * @param newCharacters the new characters in the place of the removed ones
   */
   private replaceCharactersInInput(
    removeSectionStart:number,
    removeSectionEnd:number,
    newCharacters:string
  ):void {
    this.replaceCharactersInInputInProgress = true;

    // Get the previous value of the input (before the actual modification)
    let value:string = this.previousInputValue;

    // Remove the separator if it is present
    if(value[this.separatorPositionIndex] === this.separatorCharacter) {
      value = this.getStringWithDeletedRange(value, this.separatorPositionIndex, this.separatorPositionIndex + 1);
    }

    // We modify the selection that the same substring to be selected as before the separator deletion
    // If the selection end is after the separator index ...
    if(this.separatorPositionIndex < removeSectionEnd) {
      // We should move the selection end by one to the left
      --removeSectionEnd;

      // If the selection start also after the separator index ...
      if(removeSectionStart > this.separatorPositionIndex) {
        // Move the selection end
        --removeSectionStart;
      }
    }

    // Remove the selected part
    value = this.getStringWithDeletedRange(value, removeSectionStart, removeSectionEnd);

    // Insert the new characters
    value = this.insertCharatersAtIndex(value, newCharacters, removeSectionStart);

    // Add the separator if the string necessary
    if(value.length >= this.separatorPositionIndex) {
      value = this.insertCharatersAtIndex(value, this.separatorCharacter, this.separatorPositionIndex);
    }

    // Save the actual value
    this.previousInputValue = value;

    // Update the value of the form control
    this.formControl.control.setValue(value);

    // Calculate the new caret positoion
    let newCaretPosition:number = removeSectionStart + newCharacters.length;

    // If the caret is at or after the separator's index
    if(newCaretPosition >= this.separatorPositionIndex) {
      // Move the caret to the right by one
      ++newCaretPosition;
    }

    // Set the caret position to the new caret position
    this.inputElementRef.nativeElement.selectionStart = newCaretPosition;
    this.inputElementRef.nativeElement.selectionEnd = newCaretPosition;

    // Update the saved selection
    // This is required because the document's eventlistener may fires too late and before that
    // a new input event was generated and started to process
    this.inputSelectionStart = newCaretPosition;
    this.inputSelectionEnd = newCaretPosition;

    this.replaceCharactersInInputInProgress = false;
  }

  /**
   * Returns a new string of the `targetString` with the characters in the provided range removed.
   * 
   * @param targetString the target (initial) string
   * @param startIndex the start of the removed range (inclusive)
   * @param endIndex the end of the removed range (exclusive)
   * 
   * @returns the resulting string
   */
   private getStringWithDeletedRange(targetString:string, startIndex:number, endIndex:number):string {
    return targetString.substring(0, startIndex) + targetString.substring(endIndex);
  }

  /**
   * Returns a new string from the `targetString` with new characters inserted in the provided index.
   * 
   * @param targetString the target (initial) string
   * @param newCharacters the new characters to insert
   * @param index the position to insert (this is the index where the new characters will start)
   * 
   * @returns the resulting string
   */
  private insertCharatersAtIndex(targetString:string, newCharacters:string, index:number):string {
    return targetString.substring(0, index) + newCharacters + targetString.substring(index);
  }

}
