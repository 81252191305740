<div class="main-container">

  <div class="gradient-img" style="width:100%;height:100%;">
  </div>


  <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
    <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
      <path transform="scale(0.002,0.001428)"
        d="M 0 0 L 0 600 C 100 600 200 550 300 550 C 400 550 500 600 500 600 L 500 0 C 550 50 550 550 600 300 A 50 50 0 1 1 700 300 L 500 0 Z" />
    </clipPath>
  </svg>
</div>