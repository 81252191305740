import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {

  constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout:number
  ) { }

  /**
   * Timeouts the HTTP request if the `defaultTimeout` ms is passed and a server didn't respond.
   * 
   * @param request the request
   * @param next the next function
   * @returns the http event
   */
  public intercept(request:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>> {
    return next.handle(request).pipe(timeout(this.defaultTimeout));
  }
}
