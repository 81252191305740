import { ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TiktokVideo } from 'src/app/models/tiktok-video.model';
import SwiperCore, { FreeMode } from "swiper";
import { DOCUMENT } from '@angular/common';
import { GlobalSassVariableProviderService } from '../../services/global-sass-variable-provider.service';
import { PlatformService } from 'src/app/services/common/platform-service';

SwiperCore.use([FreeMode]);

@Component({
  selector: 'app-tiktok-videos',
  templateUrl: './tiktok-videos.component.html',
  styleUrls: ['./tiktok-videos.component.scss']
})
export class TiktokVideosComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tiktokVideos: Array<TiktokVideo>;

  displayedTiktokVideos: Array<TiktokVideo>;

  resizeObserver: ResizeObserver;

  visibleVideosContainer: "swiper" | "grid";

  gridDisplayMinWidthInPx: number = 600;
  swiperDisplayMaxWidthInPx: number = this.gridDisplayMinWidthInPx - 1;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private globalSassVariableProviderService: GlobalSassVariableProviderService,
    private platformService: PlatformService
  ) {
    this.resizeObserver = new ResizeObserver(this.onBodyResize);
  }

  ngOnInit(): void {
    this.resizeObserver.observe(this.document.body);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["tiktokVideos"].currentValue != changes["tiktokVideos"].previousValue) {
      this.updateDisplayedVideos();
    }
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  private onBodyResize = () => {
    this.updateDisplayedVideos();
  }

  /**
   * Updates the displayed videos list and their displaying method based on the screensize.
   */
  private updateDisplayedVideos() {
    if (this.platformService.getIsPlatformServer()) return;

    const bodyWidth: number = this.document.body.clientWidth;
    // On a mobile-like screensizes we use the swiper to display the videos, otherwise we use the grid
    this.visibleVideosContainer = bodyWidth < this.gridDisplayMinWidthInPx ? "swiper" : "grid";
    this.displayedTiktokVideos = this.getDisplayedVideos(bodyWidth);
    // Update the UI
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Gets the displayed videos list based on the provided screen size.
   * 
   * @param bodyWidth the width of the body element
   * 
   * @returns the videos to display
   */
  private getDisplayedVideos(bodyWidth: number): Array<TiktokVideo> {
    // On the mobile like screensizes we show all the videos
    if (bodyWidth < this.gridDisplayMinWidthInPx) {
      return this.tiktokVideos;
    }

    // On a tablet-like screensizes we only show 6 videos
    if (bodyWidth < this.globalSassVariableProviderService.getPixelSuffixedInjectedCssVariableValue("laptop-min-width")) {
      return this.tiktokVideos.slice(0, 3);
    }

    // Othewise (on a desktop-like screensizes) we show 8 videos
    return this.tiktokVideos.slice(0, 4);
  }

}
