import { Component, Input } from '@angular/core';

@Component({
  selector: 'pros-item-card',
  templateUrl: './pros-item-card.component.html',
  styleUrls: ['./pros-item-card.component.scss']
})
export class ProsItemCardComponent {
  @Input() iconPath:string;
  @Input() title:string;
  @Input() description:string;

  constructor() { }

}
