import { Component, ContentChildren, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { InstructorReviewItemComponent, InstructorReviewItemComponentInput } from '../instructor-review-item/instructor-review-item.component';

@Component({
  selector: 'app-instructor-review-horizontal-list',
  templateUrl: './instructor-review-horizontal-list.component.html',
  styleUrls: ['./instructor-review-horizontal-list.component.scss']
})
export class InstructorReviewHorizontalListComponent implements OnInit {
  @ViewChild("mainHorizontalFlexContainer", { static: true }) mainHorizontalFlexContainer: ElementRef<HTMLDivElement>;


  @Input()
  scrollPositionLeft: number = 0.1; // 0,1 között

  @Input()
  reviewItems: InstructorReviewItemComponentInput[];

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges() {
    // set scroll position of main flex container
    this.mainHorizontalFlexContainer.nativeElement.scrollLeft = this.mainHorizontalFlexContainer.nativeElement.scrollWidth * this.scrollPositionLeft;
  }

  ngAfterViewInit() {
    this.mainHorizontalFlexContainer.nativeElement.scrollLeft = this.mainHorizontalFlexContainer.nativeElement.scrollWidth * (this.scrollPositionLeft ?? 0);
  }

}
