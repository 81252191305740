import { Injectable, OnDestroy } from '@angular/core';
import { PlatformService } from './platform-service';

@Injectable({
  providedIn: 'root'
})
export class TidioChatService implements OnDestroy {
  private isTidioChatVisible: boolean = true;

  constructor(private platformService: PlatformService) {
    if (this.platformService.getIsPlatformBrowser()) {
      document.addEventListener("tidioChat-ready", this.onLoadedTidioChat);
    }

  }

  ngOnDestroy(): void {
    if (this.platformService.getIsPlatformBrowser()) {
      document.removeEventListener("tidioChat-ready", this.onLoadedTidioChat);
    }
  }

  readonly onLoadedTidioChat = () => {
    if (this.isTidioChatVisible == false) {
      this.hideChat();
    }
  }

  public showChat() {
    if (this.platformService.getIsPlatformBrowser()) {
      (window as any).tidioChatApi?.show();
    }
    this.isTidioChatVisible = true;
  }

  public hideChat() {
    if (this.platformService.getIsPlatformBrowser()) {
      (window as any).tidioChatApi?.hide();
    }
    this.isTidioChatVisible = false;
  }
}
