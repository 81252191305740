<div class="section-title-big">
  Nézz szét TikTok <span style="white-space: nowrap;"> oldalunkon  is! 😎</span> 
</div>

<app-tiktok-videos
  [tiktokVideos]="tiktokVideos"
  class="tiktok-videos"
></app-tiktok-videos>

<div class="swipe-animation-container">
  <app-lottie-animation
    [animationPath]="'assets/shared/lottie/horizontal-swipe.json'"
    [scale]="1"
    [loop]="true"
  ></app-lottie-animation>
</div>
