import { Component, Input, OnInit } from '@angular/core';
import { TiktokVideo } from 'src/app/models/tiktok-video.model';

@Component({
  selector: 'app-landing-tiktok-videos',
  templateUrl: './landing-tiktok-videos.component.html',
  styleUrls: [
    './landing-tiktok-videos.component.scss',
    '../../../../../styles/common-page-section-styles.scss'
  ]
})
export class LandingTiktokVideosComponent implements OnInit {
  @Input() tiktokVideos: Array<TiktokVideo>;

  constructor() { }

  ngOnInit(): void {
  }

}
