import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AvailableCitiesDialogComponent } from 'src/app/modules/shared/components/available-cities-dialog/available-cities-dialog.component';
import { PracticeCityService } from '../../../practice-page/services/practice-city.service';

@Component({
  selector: 'app-available-cities',
  templateUrl: './available-cities.component.html',
  styleUrls: ['./available-cities.component.scss']
})
export class AvailableCitiesComponent implements OnInit, OnDestroy {
  private availableCities: Array<string> = [
    "Budapest - Vas-Gereben utca", "Budapest - Írottkő park", "Budaörs - Virágpiac", "Vác", "Szeged", "Hódmezővásárhely", "Kecskemét"
  ];

  private isLoaded: boolean = false;

  constructor(
    private dialogService: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private practiceCityService: PracticeCityService,
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.isLoaded = true;
    this.practiceCityService.fetchPracticeCities().
      then(cities => {
        this.availableCities = cities.filter(city => city.isActive).map(city => city.name);
        console.log(this.availableCities);
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
  }


  // Ezek a városok amik látszódnak dialógus nélkül
  getCitiesName(): { all: string[], main: string[] } {
    const showFirstN = 6;

    return {
      main: this.availableCities.slice(0, showFirstN),
      all: this.availableCities
    }
  }


  onTapShowAllCities(): void {
    AvailableCitiesDialogComponent.open(this.dialogService, this.viewContainerRef);
  }

}
