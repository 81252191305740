import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-curvy-clipped-static-gradient',
  templateUrl: './curvy-clipped-static-gradient.component.html',
  styleUrls: ['./curvy-clipped-static-gradient.component.scss']
})
export class CurvyClippedStaticGradientComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
