import { Component, Input, OnInit } from '@angular/core';
import { ProsListItem } from './models/pros-list-item';

@Component({
  selector: 'pros-list',
  templateUrl: './pros-list.component.html',
  styleUrls: ['./pros-list.component.scss']
})
export class ProsListComponent implements OnInit {
  @Input() prosListItems:Array<ProsListItem> = [];

  constructor() { }

  ngOnInit(): void {
  }

}
