import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-welcome',
  templateUrl: './landing-welcome.component.html',
  styleUrls: ['./landing-welcome.component.scss']
})
export class LandingWelcomeComponent {

  constructor() { }

}
