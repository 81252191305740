import { AbstractControl, ValidationErrors, Validators } from "@angular/forms";

export class CustomValidators {
  public static email(control:AbstractControl):ValidationErrors|null {
    const emailValidatorRegExp:RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return (Validators.pattern(emailValidatorRegExp))(control) ? { email: true } : null;
  }

  public static phoneNumber(control:AbstractControl):ValidationErrors|null {
    const phoneNumberRegexp:RegExp = new RegExp(/^[\+]?[0-9]{8,}$/);
    return (Validators.pattern(phoneNumberRegexp))(control) ? { phoneNumber: true } : null;
  }
}