<div class="main-container">
  <div class="content">
    <app-landing-welcome #welcomeSection></app-landing-welcome>
    <app-store-downloads></app-store-downloads>
    <app-landing-practice #practiceSection (renderedTitle)="handleRenderedTitle($event)"></app-landing-practice>
    <app-landing-theory></app-landing-theory>
    <app-reviews></app-reviews>
    <app-landing-tiktok-videos *ngIf="shouldDisplayTiktokVideosSection" [tiktokVideos]="tiktokVideos"
      class="landing-tiktok-videos"></app-landing-tiktok-videos>
    <app-landing-download class="download"></app-landing-download>
  </div>

  <!--Ha a vizsgaútvonalk bejárása szekció nem látszik, akkor megjelenítjük ezt a scroll-t-->
  <div [style.visibility] = "shouldShowScrollIcon ? 'visible' : 'hidden'" #iconScroll class='icon-scroll' (click)="handleScrollIconClick()"></div>
</div>