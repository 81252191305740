import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type YoutubeDialogData = {
  videoUrl: string;
}

@Component({
  selector: 'app-youtube-video-dialog.',
  templateUrl: './youtube-video-dialog.component.html',
  styleUrls: ['./youtube-video-dialog.component.scss']
})
export class YoutubeVideoDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: YoutubeDialogData
  ) { }

  ngOnInit(): void { }


  static openDialog(matDialog: MatDialog, data: YoutubeDialogData): MatDialogRef<YoutubeVideoDialogComponent> {
    return matDialog.open(YoutubeVideoDialogComponent, {
      data,
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      disableClose: true,
      maxHeight: '100vh',
      scrollStrategy: new NoopScrollStrategy(),
      backdropClass: 'dark-backdrop',
      panelClass: 'transparent-dialog-container',
    });
  }

}