import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CssVariableExtractor } from './css-variable-extractor.service';

export const customInjectedCssVariablePrefix = '--custom-variable-for-injecting-';

@Injectable()
export class GlobalSassVariableProviderService {
  readonly pixelSuffixRegExp = /^[0-9]+px$/;

  constructor(
    private cssVariableExtractor: CssVariableExtractor,
    @Inject(DOCUMENT) private document: Document
  ) {}

  /**
   * Get the provided CSS variable's value.  If the variable cannot be found, an exception is thrown.
   *
   * @param name the name of the CSS variable
   *
   * @returns the value of the CSS variable
   */
  public getCssVariableValue(name: string): string {
    // Get all CSS variable values
    const rootCssVariables:Record<string, string> = this.cssVariableExtractor.getAllVariablesFromHtmlElement(
      this.document.documentElement
    );

    const value: string = rootCssVariables[name];
    if(!value) {
      throw Error(`CSS variable not exists: ${name}`);
    }

    return value;
  }

  /**
   * Get the provided injected CSS variable's value. If the variable cannot be found, an exception is thrown.
   *
   * @param name the name of the injected CSS variable
   *
   * @returns the value of the CSS variable
   */
  public getInjectedCssVariableValue(name: string): string {
    const injectedVariableName: string = customInjectedCssVariablePrefix + name;
    return this.getCssVariableValue(injectedVariableName);
  }

  /**
   * Get the provided injected pixel suffixed CSS varialbe's value. If the variable cannot be found or the value
   * is not pixel suffixed (or invalid), an exception is thrown.
   *
   * @param name the name of the injected CSS variable
   *
   * @returns the value of the CSS variable as a number
   */
  public getPixelSuffixedInjectedCssVariableValue(name: string): number {
    const propWithPixelSuffix = this.getInjectedCssVariableValue(name);
    
    const isValidPropertyValue = this.pixelSuffixRegExp.test(propWithPixelSuffix);
    if (!isValidPropertyValue) {
      throw Error("INVALID_PROP");
    }

    const numberAsString = propWithPixelSuffix.substring(0, propWithPixelSuffix.length - 2);

    try {
      return parseInt(numberAsString);
    } catch (e) {
      // Not possible branch
      throw Error("INVALID_PROP");
    }
  }
}
