<div class="wrapper">
  <div class="profile-image">
    <img loading="lazy" alt="Oktató profilképe" style="width:100%;height:100%;" [src]="this.data.profileImageUrl">
  </div>

  <span style="display:block;margin-top:10px;" class="name">
    {{ this.data.name }}
  </span>

  <span class="text">
    "{{ this.data.text }}"
  </span>
</div>
