import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { awaitFor } from 'src/app/modules/shared/functions/misc-functions';
import { MenuItem } from '../header.component';

@Component({
  selector: 'app-header-mobile-menu',
  templateUrl: './header-mobile-menu.component.html',
  styleUrls: ['./header-mobile-menu.component.scss'],
})
export class HeaderMobileMenuComponent implements OnInit, AfterViewInit {
  @Input() menuItems:Array<MenuItem>;

  @Output() menuClosedEvent:EventEmitter<void> = new EventEmitter<void>();

  @ViewChildren("menuItemLink") menuItemLinkList:QueryList<ElementRef<HTMLAnchorElement>>;

  constructor() { }

  ngOnInit(): void {
    this.menuItems = this.menuItems.slice();
    // Sort the menupoints based on their mobil menu position
    this.menuItems.sort((menuItem1:MenuItem, menuItem2:MenuItem) => {
      return menuItem1.mobileMenuPosition - menuItem2.mobileMenuPosition;
    });
  }

  public ngAfterViewInit():void {

  }
  

  public closeMenu():void {
    // Emit an event for the parent that the mobile menu is closed
    this.menuClosedEvent.emit();
  }

}
