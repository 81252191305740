import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  YoutubeVideoDialogComponent,
  YoutubeDialogData,
} from 'src/app/modules/shared/components/youtube-video-dialog/youtube-video-dialog.component';

@Component({
  selector: 'app-openable-youtube-video-preview',
  templateUrl: './openable-youtube-video-preview.component.html',
  styleUrls: ['./openable-youtube-video-preview.component.scss'],
})
export class OpenableYoutubeVideoPreviewComponent implements OnInit {
  @Input() imageSrc: string;
  @Input() videoUrl: string;

  // On host click, open the provided youtube video
  @HostListener('click', ['$event']) onClick() {
    this.openYoutubeDialog();
  }

  constructor(private dialogService: MatDialog) { }

  ngOnInit(): void { }

  /**
   * Opens the provided youtube video in a Youtube video dialog component.
   */
  public openYoutubeDialog(): void {
    YoutubeVideoDialogComponent.openDialog(this.dialogService, {
      videoUrl: this.videoUrl,
    });
  }
}
