import { Injectable } from '@angular/core';
import { PurchasablePackage } from '../models/purchasable-package';
import { BackendService } from 'src/app/services/common/backend.service';
import { PurchaseDialogComponent } from '../components/purchase-dialog/purchase-dialog.component';

@Injectable({
  providedIn:'root'
})
export class PackagePurchaseService {
  private readonly studentCheckoutApiUrlFragment: string = "/api/public/purchasable_packages/purchase";

  constructor(
    private backendService: BackendService
  ) { }

  /**
   * Requests the server to create a Stripe checkout. If everything is allright, it redirects to the Stripe's page.
   *
   * @returns nothing
   */
  public async goToCheckout(purchasablePackage: PurchasablePackage, email: string, isGift: boolean, crossSellingPackages: PurchasablePackage[], customerFacingPromotionCode?: string): Promise<void> {
    const httpBody: Object = {
      packages: [
        // Main package
        {
          packageDefinitionUuid: purchasablePackage.packageDefinitionUuid,
          offerUuid: purchasablePackage.offerUuid,
        },
        // Cross selling packages
        ...crossSellingPackages.map(
          (crossSellingPackage: PurchasablePackage) => {
            return {
              packageDefinitionUuid: crossSellingPackage.packageDefinitionUuid,
              offerUuid: crossSellingPackage.offerUuid,
            }
          }
        )
      ],
      customerEmail: email,
      isGift: isGift,
      customerFacingPromotionCode: customerFacingPromotionCode
    };

    try {
      const response: { checkoutUrl: string } = await this.backendService.callApi(
        this.studentCheckoutApiUrlFragment,
        "POST",
        {
          httpRequestBody: httpBody,
          useDefaultErrorHandlers: false
        }
      );

      // On success we redirect the user
      window.location.href = response.checkoutUrl;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }


  getCrossSellPackagesToSpecificPackage(allPackage: PurchasablePackage[], packageDefinitionUuid?: string): PurchasablePackage[] {
    if (packageDefinitionUuid == null) return [];

    // ehhez a csomaghoz keressük a cross selling csomagokat

    const pack = allPackage.find((p) => p.packageDefinitionUuid == packageDefinitionUuid);
    const result: PurchasablePackage[] = [];

    for (const crossSellPackageDefUuid of pack.packageDefinitionUuidsToCrossSelling) {
      const crossSellPackage = allPackage.find((currentPack) => currentPack.packageDefinitionUuid == crossSellPackageDefUuid);
      if (crossSellPackage != null) {
        result.push(crossSellPackage);
      }
    }

    return result;
  }
}
