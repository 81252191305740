import { Directive, Output, EventEmitter, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: 'img[loaded]'
})
export class LoadedDirective {

  @Output() loaded = new EventEmitter();

  @HostListener('load')
  onLoad() {
    // Várjuk meg az első kirenderelést is:
    // Requestelünk egy animation frame-et ami után garantáltan megtörténik egy renderelés
    // Majd mégegy animation frame-et requestelünk aminek a callbackje az 1. kirenderelt frame után hívódik

    // https://stackoverflow.com/questions/53423742/waiting-for-an-image-to-finish-rendering/53463980#53463980
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        // Ha szeretnénk kiolvasni az img tartalmát, ami pl egy svg
        // akkor ha nincs teljesen kirenderelve még, nem biztos, hogy a teljes betöltött kép van benne
        setTimeout(() => {
          this.loaded.emit();
        }, 300);
      });
    });
  }

  constructor(private elRef: ElementRef<HTMLImageElement>) {
    if (this.elRef.nativeElement.complete) {
      this.loaded.emit();
    }
  }
}