import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type JoinResultDialogInput = {
  isSuccess:boolean;
  displayedText:string;
};

@Component({
  selector: 'app-join-result-dialog',
  templateUrl: './join-result-dialog.component.html',
  styleUrls: ['./join-result-dialog.component.scss']
})
export class JoinResultDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: JoinResultDialogInput
  ) { }

  ngOnInit():void { }

  public static open(
    dialogService: MatDialog,
    data: JoinResultDialogInput
  ): MatDialogRef<JoinResultDialogComponent, void> {
    return dialogService.open<JoinResultDialogComponent, JoinResultDialogInput, void>(
      JoinResultDialogComponent,
      {
        data: data,
        disableClose: true,
        scrollStrategy: new NoopScrollStrategy(),
        hasBackdrop: true,
        backdropClass: 'invisible-backdrop'
      }
    );
  }

}
