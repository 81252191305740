
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PromotionCode } from 'src/app/modules/pages/pricing-page/models/promotion-code';
import { DescriptionLineItem, PurchasablePackage } from 'src/app/modules/pages/pricing-page/models/purchasable-package';
import { PromotionCodeUtilityService } from 'src/app/modules/pages/pricing-page/services/promotion-code-utility.service';
import { PackageInformationDialogComponent } from '../package-information-dialog/package-information-dialog.component';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {
  @Input() package: PurchasablePackage | null = null;
  @Input() appliedPromotionCode: PromotionCode | undefined = undefined;

  @Output() buyButtonClicked: EventEmitter<BuyButtonClickEvent>;

  changeDetectorTriggerInterval: any;


  constructor(
    private dialogService: MatDialog,
    private promotionCodeUtilityService: PromotionCodeUtilityService,
    private changeDetector: ChangeDetectorRef,
    private zone: NgZone
  ) {
    this.buyButtonClicked = new EventEmitter<BuyButtonClickEvent>();
  }


  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      this.changeDetectorTriggerInterval = setInterval(() => {
        // másodpercenként frissítjük a promo kód lejáratát a template-ben
        this.zone.run(() => this.changeDetector.detectChanges());
      }, 1000);
    });
  }

  ngOnDestroy() {
    clearInterval(this.changeDetectorTriggerInterval);
  }

  hasDiscount() {
    return this.appliedPromotionCode?.discountPercentage != null && this.appliedPromotionCode?.discountPercentage != 0;
  }

  /**
   * Opens a puchase dialog for the given package.
   */
  public onBuyButtonClick(): void {
    this.buyButtonClicked.emit({ purchasablePackage: this.package, appliedPromotionCode: this.appliedPromotionCode });
  }


  public getGrossPriceWithoutDiscount(): number | null {
    return this.package?.grossPrice;
  }

  public getGrossPriceCalculatedWithDiscount(): number | null {
    if (this.package == null || this.appliedPromotionCode == null) {
      return null;
    }

    return this.promotionCodeUtilityService.getGrossPriceCalculatedWithDiscount(
      this.package,
      this.appliedPromotionCode
    );
  }

  // Substract discountExpireTimestamp from current timestamp and convert it to hh:mm:ss format
  // use leading zero if needed
  public getDiscountExpireTime(): string {
    if (this.appliedPromotionCode?.expireTimestampInMs == null) return '';
    const discountExpireTimestampInSec = this.appliedPromotionCode?.expireTimestampInMs / 1000;
    const currentTimestampInSec = Date.now() / 1000;
    const timeLeftInSec = Math.round(discountExpireTimestampInSec - currentTimestampInSec);
    if (timeLeftInSec < 0) {
      return 'Lejárt';
    }

    const hours = Math.floor(timeLeftInSec / 3600);
    const minutes = Math.floor((timeLeftInSec - hours * 3600) / 60);
    const seconds = timeLeftInSec - hours * 3600 - minutes * 60;
    return `${hours.toString().padStart(2, '0')} : ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
  }

  /**
   * Opens an explanation dialog for the given package.
   */
  public openExplanationDialog(descriptionLine: DescriptionLineItem): void {
    if (!descriptionLine.explanation) {
      return;
    }

    this.dialogService.open(PackageInformationDialogComponent, {
      data: {
        descriptionLine: descriptionLine
      },
      disableClose: false,
      scrollStrategy: new NoopScrollStrategy(),
      hasBackdrop: true,
      backdropClass: 'invisible-backdrop'
    });
  }

}

export type BuyButtonClickEvent = {
  purchasablePackage: PurchasablePackage,
  appliedPromotionCode: PromotionCode | undefined
};