import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DescriptionLineItem } from 'src/app/modules/pages/pricing-page/models/purchasable-package';

@Component({
  selector: 'app-package-information-dialog',
  templateUrl: './package-information-dialog.component.html',
  styleUrls: ['./package-information-dialog.component.scss']
})
export class PackageInformationDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{ descriptionLine:DescriptionLineItem }
  ) { }

  ngOnInit(): void {
  }

}
