import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import gsap from "gsap";
import confetti from 'canvas-confetti';


@Component({
  selector: 'app-coupon-section',
  templateUrl: './coupon-section.component.html',
  styleUrls: ['./coupon-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponSectionComponent implements OnInit {
  @ViewChild("canvasCoupon", { static: true }) canvasCoupon: ElementRef<HTMLCanvasElement>;
  @ViewChild("frontCouponImg", { static: true },) frontCouponImg: ElementRef<HTMLImageElement>;
  @ViewChild("realCouponArea", { static: true }) realCouponArea: ElementRef<HTMLDivElement>;
  @ViewChild("scratchCardWrapper", { static: true }) scratchCardWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('redeemButton', { static: true }) redeemButton: ElementRef<HTMLButtonElement>;

  @Output()
  onPromotionCodeRedeemRequest : EventEmitter<string> = new EventEmitter(); // 

  // HARDCODED PROMOTION CODE
  readonly promotionCode = "MRKRESZ15";

  isScratched = false;

  constructor(
    private zone: NgZone
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  couponImageLoaded() {
    this.canvasCoupon.nativeElement.getContext("2d");

    // A canvas mérete legyen egyenlő a frontCouponImage méretével. 
    // Hiszen ezt a képet fogjuk betölteni a canvas-ba.
    const w = this.frontCouponImg.nativeElement.width;
    const h = this.frontCouponImg.nativeElement.height;
    this.canvasCoupon.nativeElement.width = w;
    this.canvasCoupon.nativeElement.height = h;

    console.log("loaded coupon img size (w,h): ", w, h);


    // iphone 6s-en valamiért ha az svg viewbox kisebb volt mint 270x270px
    // akkor a drawImage scale paraméterei nem működtke, nem méretezte át 270x270-re
    // ** Ha állítunk a coupon méretén, akkor mindenképp legyen kijavítva ez a bug iphone 6s-en (safari)
    // (jelenleg a viewbox mérete is 270, illetve mindenhol explicit be van állítva)
    this.canvasCoupon.nativeElement.getContext("2d").drawImage(this.frontCouponImg.nativeElement, 0, 0,w,h);

    // add scratch card effect (with touch gesture handler and circle drawing)
    this.zone.runOutsideAngular(() => {
      this.addScratchCardEffect();
    });
  }

  addScratchCardEffect() {
    // add touch gesture handler
    this.canvasCoupon.nativeElement.addEventListener("touchstart", this.handleTouchStart, false);
    this.canvasCoupon.nativeElement.addEventListener("touchmove", this.handleTouchMove, false);
    this.canvasCoupon.nativeElement.addEventListener("touchend", this.handleTouchEnd, false);

    // add mouse gesture handler
    this.canvasCoupon.nativeElement.addEventListener("mousedown", this.handleMouseDown, false);
    this.canvasCoupon.nativeElement.addEventListener("mousemove", this.handleMouseMove, false);
    this.canvasCoupon.nativeElement.addEventListener("mouseup", this.handleMouseUp, false);
  }

  isMousePressed = false;

  handleTouchStart = (e) => {
    // Disable vertical scroll
    if (e.cancelable) e.preventDefault();

    this.isMousePressed = true;
    console.log("touch start");
    this.handleTouchMove(e);
  }



  handleTouchMove = (e) => {
    if(e.cancelable){
      e.preventDefault();
    }
    // Screen x and Screen y to local value
    const localX = e.touches[0].clientX - this.canvasCoupon.nativeElement.getBoundingClientRect().left;
    const localY = e.touches[0].clientY - this.canvasCoupon.nativeElement.getBoundingClientRect().top;

    this.clearCircle(localX, localY);
  }

  clearCircle = (x, y) => {
    // Get real coupon area rect relative to canvas
    const realCouponAreaRect = this.realCouponArea.nativeElement.getBoundingClientRect();
    const canvasRect = this.canvasCoupon.nativeElement.getBoundingClientRect();

    // Get real coupon area rect relative to canvas
    const realCouponAreaRectRelative = {
      left: realCouponAreaRect.left - canvasRect.left,
      top: realCouponAreaRect.top - canvasRect.top,
      right: realCouponAreaRect.right - canvasRect.left,
      bottom: realCouponAreaRect.bottom - canvasRect.top,
    }

    const radius = 18;

    this.canvasCoupon.nativeElement.getContext("2d").beginPath();
    this.canvasCoupon.nativeElement.getContext("2d").arc(x, y, radius, 0, 2 * Math.PI);
    this.canvasCoupon.nativeElement.getContext("2d").fillStyle = "white";
    // Fill only within the realCouponArea

    this.canvasCoupon.nativeElement.getContext("2d").fill();
    this.canvasCoupon.nativeElement.getContext("2d").globalCompositeOperation = "destination-out";
    this.canvasCoupon.nativeElement.getContext("2d").fill();

    // Determine how much of the coupon is scratched within the real coupon area

    if (this.isScratched == false) {
      // Ha még nem volt lekaparva nézzük meg, hogy most le lett-e kaparva
      const imageData = this.canvasCoupon.nativeElement.getContext("2d").getImageData(realCouponAreaRectRelative.left, realCouponAreaRectRelative.top, realCouponAreaRectRelative.right - realCouponAreaRectRelative.left, realCouponAreaRectRelative.bottom - realCouponAreaRectRelative.top);
      const data = imageData.data;
      let count = 0;
      for (let i = 0; i < data.length; i += 4) {
        if (data[i] == 0 && data[i + 1] == 0 && data[i + 2] == 0 && data[i + 3] == 0) {
          count++;
        }
      }

      const percentage = count / (data.length / 4) * 100;
      // Ha igen, akkor mehet a confetti effect
      if (percentage > 93) {
        this.onScratched();
      }
    }
  }

  onScratched() {
    this.isScratched = true;
    console.log("Scratched");

    this.zone.runOutsideAngular(() => {
      confetti({
        particleCount: 100,
        spread: 70,
      });

      // Hide canvas with gsap
      gsap.to(this.canvasCoupon.nativeElement, {
        duration: 0.5,
        opacity: 0,
        onComplete: () => {
          this.canvasCoupon.nativeElement.style.display = "none";
        }
      });

      gsap.to(this.scratchCardWrapper.nativeElement, {
        duration: 0.5,
        height: 60
      });

      // Show redeem button
      
      gsap.to(this.redeemButton.nativeElement, {
        duration: 0.3,
        opacity: 1,
        ease: "ease.out",
        display: "block"
      });
    });
    
  }

  onTapRedeem(){
    this.onPromotionCodeRedeemRequest.emit(this.promotionCode);
  }

  handleTouchEnd = (e) => {
    this.handleMouseUp(e.touches[0]);
  }

  handleMouseDown = (e) => {
    this.isMousePressed = true;
    console.log("MOUSE DOWN");
    console.log(e);
    this.handleMouseMove(e);
  }

  handleMouseMove = (e) => {
    if (!this.isMousePressed) return;
    this.clearCircle(e.offsetX, e.offsetY);
  }

  handleMouseUp = (e) => {
    this.isMousePressed = false;
  }

}

