import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { theoryModuleYoutubeVideoUrl } from 'src/app/constants/youtube-video-urls';

@Component({
  selector: 'app-landing-theory',
  templateUrl: './landing-theory.component.html',
  styleUrls: [
    './landing-theory.component.scss',
    '../../../../../styles/common-page-section-styles.scss'
  ]
})
export class LandingTheoryComponent implements OnInit {

  readonly youtubeVideoUrl:string = theoryModuleYoutubeVideoUrl;

  constructor(
    private routerService:Router
  ) { }

  ngOnInit(): void {
  }

  public navigateTo(route:string) {
    this.routerService.navigate([ route ]);
  }
}
