<div class="package-info">
  <div class="package-info-name">
    {{ data.package.displayedName }}
  </div>
</div>

<!-- Fő csomag ár -->
<div>
  <div *ngIf="this.data.grossPrice == this.data.grossPriceWithDiscount">
    <span style="font-weight: 600" f>{{ this.data.grossPrice }} Ft</span>
  </div>

  <div *ngIf="this.data.grossPrice != this.data.grossPriceWithDiscount">
    <s>{{ this.data.grossPrice }}</s> <span> Ft helyett</span>,
    <span class="nowrap">most csak
      <span>{{ this.data.grossPriceWithDiscount }} Ft. </span>
    </span>
  </div>
</div>

<!-- Cross selling -->
<div style="margin-top:15px;">
  <app-cross-selling-row style="margin-top:10px;" #crossSellingRow
    *ngFor="let crossSellingPackage of this.data.crossSellingPackages"
    [purchasablePackageToCrossSell]="crossSellingPackage"
    (isCheckedChange)="crossSellCheckboxChange($event, crossSellingPackage.packageDefinitionUuid)">

  </app-cross-selling-row>
</div>

<!-- E-mail cím -->
<div>
  <mat-form-field class="email-form-field" hideRequiredMarker="true">
    <mat-label> E-mail cím </mat-label>
    <input matInput type="email" [formControl]="emailFC" />
    <mat-error *ngIf="emailFC.hasError('required')">Kötelező mező!</mat-error>
    <mat-error *ngIf="emailFC.hasError('email')">Nem megfelelő e-mail!</mat-error>
  </mat-form-field>
</div>
<mat-dialog-actions>
  <div class="actions-container">
    <button grayButton class="close-button" mat-dialog-close>Mégsem</button>
    <button gradientButton class="checkout-button" [disabled]="isCheckoutRequestLoading"
      [class.button-loading]="isCheckoutRequestLoading" (click)="handleCheckoutButtonClick()">
      <div class="checkout-button-content">
        <span class="material-icons"> shopping_cart_checkout </span>
        <span style="margin-left:5px;" class="purchase-button-text"> Tovább a fizetéshez </span>
      </div>
    </button>
  </div>
</mat-dialog-actions>