import { Injectable } from "@angular/core";
import { PackageDefinition, PackageOffer, PurchasablePackage } from "../models/purchasable-package";

@Injectable({
  providedIn: "root"
})
export class PackageDefinitionConverterService {
  public convertToPurchasablePackage(packageDefinition: PackageDefinition): PurchasablePackage | null {
    // Get the active single offer for the student
    const singleOffer:PackageOffer = packageDefinition.offers.find(
     offer => offer.isActive && offer.offerFor === "student" && offer.quantity === 1
    );

    // If there is no such offer, return null
    if(!singleOffer) {
      return null;
    }

    // Create a puchasable package of it
    return {
      name: packageDefinition.name,
      displayedName: packageDefinition.displayedName,
      grossPrice: Math.round(singleOffer.grossPrice / 100),
      currency: singleOffer.currency.toLocaleLowerCase() === "huf" ? "Ft" : singleOffer.currency,
      descriptionLines: packageDefinition.descriptionLines,
      packageDefinitionUuid: packageDefinition.uuid,
      packageDefinitionUuidsToCrossSelling: packageDefinition.packageDefinitionUuidsToCrossSelling,
      offerUuid: singleOffer.offerUuid,
      groupName: packageDefinition.groupName
    };
  }
}