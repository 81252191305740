import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InternetConnectionCheckerService {

  constructor() { }

  /**
   * Check that the defined (highly trusted) URL is available
   * 
   * @returns is there an internet connection
   */
  public async hasInternetConnection(): Promise<boolean> {
    const fetchUrl: string = "https://google.com/";

    try {
      const response:Response = await fetch(fetchUrl, { method: "HEAD", mode: 'no-cors' });
      return response.status >= 200 && response.status < 300 || response.status == 0;
    } catch(error:any) {
      return false;
    }
  }
}
