import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type';


@Component({
  selector: 'app-are-you-scared',
  templateUrl: './are-you-scared.component.html',
  styleUrls: ['./are-you-scared.component.scss']
})
export class AreYouScaredComponent implements OnInit, AfterViewInit{
  @ViewChild("headlineInactive", { static: true, read: ElementRef }) headlineInactive: ElementRef;
  @ViewChild("mainWrapper", { static: true, read: ElementRef }) mainWrapper: ElementRef;  

  @Output()
  purchaseClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }


  





}
