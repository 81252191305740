import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HeaderService } from 'src/app/components/header/header.component.service';
import { PlatformService } from 'src/app/services/common/platform-service';
import { TidioChatService } from 'src/app/services/common/tidio-service';

@Component({
  selector: 'app-christmas-page',
  templateUrl: './christmas-page.component.html',
  styleUrls: ['./christmas-page.component.scss']
})
export class ChristmasPageComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  constructor(
    private router: Router,
    private headerService: HeaderService,
    private tidioService: TidioChatService,
    protected platformService: PlatformService,
  ) { }


  ngOnInit() {
    this.headerService.setColorMode("dark");
    this.tidioService.hideChat();
    this.addNavigatorSubscription();
  }


  getHeaderColor() {
    const ratio = window.innerWidth / window.innerHeight;
    return ratio > 13/16 ? "light" : "dark";
  }

  ngAfterViewInit() {
    if (this.platformService.getIsPlatformBrowser()) {
      // If the device ratio below 13/16
      this.headerService.setColorMode(this.getHeaderColor());
    }
  }

  // add window resize event listener
  @HostListener('window:resize', ['$event'])
  onResizeWindow() {
    if (this.platformService.getIsPlatformBrowser()) {
      this.headerService.setColorMode(this.getHeaderColor());
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  addNavigatorSubscription() {
    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.headerService.setColorMode("light");
        }
      }));
  }
}
