<div class="header">
  <div class="title">
    <div class="icon">
      🏙️
    </div>
    <div>
      Városok listája
    </div>
  </div>
</div>
<mat-dialog-content>
  <ng-template [ngIf]="!isContentLoading">

    <ng-template [ngIf]="availableCities.length > 0">
      <div class="subtitle">
        Jelenleg elérhető városok
      </div>
      <div class="cities-list">
        <div *ngFor="let city of availableCities" class="city">
          {{ city.name }}
        </div>
      </div>

    </ng-template>

    <ng-template [ngIf]="availableCities.length === 0">
      <div class="error-texts-container">
        <div>
          Nem sikerült a városok lekérdezése.
        </div>
        <div>
          Kattints <span class="clickable" (click)="reloadData()"> ide </span> az újratöltéshez vagy nézz vissza később!
        </div>
      </div>
    </ng-template>

  </ng-template>
  <div *ngIf="isContentLoading" class="loading-container">
    <mat-spinner></mat-spinner>
  </div>
</mat-dialog-content>
<div class="dialog-actions">
  <div class="close-button" mat-dialog-close>
    Bezár
  </div>
</div>