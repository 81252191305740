import { AnimationEvent } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { PlatformService } from 'src/app/services/common/platform-service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, AfterViewInit, OnDestroy {
  /** ElementRefs of the background rectangles */
  @ViewChildren("backgroundRectangle") rectangleElements: QueryList<ElementRef<HTMLDivElement>>;
  /** Configurations of the background rectangles for the given routes */
  rectanglesConfigs: Array<RectangleConfig>;
  /** The native HTML elements of the background rectangles */
  rectangleDivs: Array<HTMLDivElement>;
  /** Array of routes where the background rectangles are shown */
  rectanglesVisibleRoutes: Array<string>;
  /** Determines the background rectangles actual visibility */
  isRectanglesShown: boolean = false;

  resizeObserver: ResizeObserver | undefined;

  @Output() routerAnimationEvent: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private router: Router,
    private platformService: PlatformService
  ) { }

  public ngOnInit(): void {
    // The routes that has the rectangle(s) in the background
    this.rectanglesVisibleRoutes = ["vasarlas", "bevaltas", "gyik", "kresz-teszt-app", "partnerek", "classroom"];
    // Load the configurations of the reactangles for each route.
    this.rectanglesConfigs = getRectanglesConfigs();

    // Subscribe to the change of the route
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {

          const actualRouteName: string = this.getActualRouteName(event.url);
          this.isRectanglesShown = actualRouteName !== "";

          this.setRectanglesDynamicProperties(actualRouteName);
          this.updateRectanglesVisibility();
        }
      }
    );

    // The provided callback for the resize observer is automatically runs when the element is being rendered
    // it has a size. We would like to prevent this behaviour, because at that point the route is not resolved
    // and the rectangles would be initilaized improperly.
    let firstResizeObserverRun: boolean = true;

    if (this.platformService.getIsPlatformBrowser()) {
      this.resizeObserver = new ResizeObserver(() => {
        if (firstResizeObserverRun) {
          firstResizeObserverRun = false;
          return;
        }

        setTimeout(() => {
          this.setRectanglesDynamicProperties(this.getActualRouteName(this.router.url));
        });
      });
    }

  }

  public ngAfterViewInit(): void {
    // Get the native elements of the rectangles
    this.rectangleDivs = this.rectangleElements.toArray().map(element => element.nativeElement);
    this.resizeObserver?.observe(this.elementRef.nativeElement);
  }

  public ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
  }

  /**
   * Sets the rectangles route-custom properties based on the actual route.
   * 
   * @param routeName the name of the route (result of `getActualRouteName()`)
   */
  private setRectanglesDynamicProperties(routeName: string): void {
    if (this.rectangleDivs?.length > 0) {
      this.rectangleDivs.forEach(
        (element: HTMLDivElement, index: number) => {
          // Get the properties if the rectangle for the actual route
          const rectangleProperties: RectangleProperties = this.rectanglesConfigs[index].routeConfigs.get(routeName) ?? this.rectanglesConfigs[index].defaultConfig;

          // Set the size and the border radius of the rectangle
          const size: number = this.elementRef.nativeElement.clientWidth * rectangleProperties.size;
          element.style.width = `${size}px`;
          element.style.height = `${size}px`;
          element.style.borderRadius = `${size / 10}px`;

          // Set the position and the rotation if the rectangle
          element.style.left = rectangleProperties.position.x * 100 + "%";
          element.style.top = rectangleProperties.position.y * 100 + "%";
          element.style.transform = `rotate(${rectangleProperties.rotation * 90}deg)`;

          if (!element.style.transition) {
            // Trigger a reflow - the rectangles will be in their initial position, so there will be no
            // animation at the moment of 'creation'
            element.clientWidth;
            element.style.transition = "all 500ms ease";
          }
        }
      );
    }
  }

  /**
   * Update all rectangles visibility based on the `isRectanglesShown` property.
   */
  private updateRectanglesVisibility(): void {
    this.rectangleDivs?.forEach(
      (element: HTMLDivElement) => {
        element.style.display = this.isRectanglesShown ? "block" : "none";
      }
    );
  }

  /**
   * Gets the name of the route based on the url. The name is used as a key to determine the
   * rectagles properties on the given route.
   * 
   * @param url the actual route's url
   * @returns the name of the route
   */
  private getActualRouteName(url: string): string {
    if (url.includes("callbacks") || url === "/") {
      return "landing"
    }

    return this.rectanglesVisibleRoutes.find(route => url.includes(route)) ?? "";
  }


}

class RectangleProperties {
  /** The position of a rectangle in proportion of the screen's axes. */
  position: { x: number; y: number; };
  /**  The size of a rectangle in proportion of the screen's width. */
  size: number;
  /** The rotation of the rectangle in proportion of a quarter (90deg) turn. */
  rotation: number;
}

class RectangleConfig {
  /** Describes how a rectangle should appear for a given route.  */
  routeConfigs: Map<string, RectangleProperties>;
  /** Describes how a rectangle should appear on an unconfigured route (by default). */
  defaultConfig: RectangleProperties;
}

/**
 * Gets the rectangles configurations for the routes.
 * 
 * @returns the rectangles configurations
 */
function getRectanglesConfigs(): Array<RectangleConfig> {
  return [
    {
      routeConfigs: new Map([
        ["landing", { position: { x: 0.75, y: 0.1 }, size: 0.35, rotation: 0.95 }],
        ["kresz-teszt-app", { position: { x: 0.6, y: 0 }, size: 0.25, rotation: 0.95 }],
        ["partnerek", { position: { x: 0.65, y: 0 }, size: 0.2, rotation: 0.9 }],
        ["vasarlas", { position: { x: 0.7, y: 0 }, size: 0.3, rotation: 0.9 }],
        ["bevaltas", { position: { x: 0.6, y: 0.1 }, size: 0.25, rotation: 1.2 }],
        ["gyik", { position: { x: 0.65, y: 0.15 }, size: 0.2, rotation: 0.8 }],
        ["classroom", { position: { x: 0.75, y: 0.05 }, size: 0.3, rotation: 0.85 }],
      ]),
      defaultConfig: { position: { x: 0.7, y: 0 }, size: 0.3, rotation: 0.9 },
    },
    {
      routeConfigs: new Map([
        ["landing", { position: { x: 0.1, y: 0.4 }, size: 0.15, rotation: 0.25 }],
        ["kresz-teszt-app", { position: { x: 0.2, y: 0.4 }, size: 0.2, rotation: 0.1 }],
        ["partnerek", { position: { x: 0.1, y: 0.3 }, size: 0.1, rotation: 0.15 }],
        ["vasarlas", { position: { x: 0.15, y: 0.4 }, size: 0.1, rotation: 0.2 }],
        ["bevaltas", { position: { x: 0.2, y: 0.35 }, size: 0.15, rotation: 0.75 }],
        ["gyik", { position: { x: 0.1, y: 0.3 }, size: 0.2, rotation: 0.5 }],
        ["classroom", { position: { x: 0.15, y: 0.3 }, size: 0.1, rotation: 0.3 }]
      ]),
      defaultConfig: { position: { x: 0.15, y: 0.4 }, size: 0.1, rotation: 0.2 }
    },
    {
      routeConfigs: new Map([
        ["landing", { position: { x: 0.4, y: 0.75 }, size: 0.1, rotation: 0.1 }],
        ["kresz-teszt-app", { position: { x: 0.5, y: 0.7 }, size: 0.15, rotation: 0.05 }],
        ["partnerek", { position: { x: 0.5, y: 0.65 }, size: 0.12, rotation: 0.2 }],
        ["vasarlas", { position: { x: 0.45, y: 0.75 }, size: 0.2, rotation: 0.05 }],
        ["bevaltas", { position: { x: 0.45, y: 0.7 }, size: 0.15, rotation: 0.2 }],
        ["gyik", { position: { x: 0.5, y: 0.6 }, size: 0.12, rotation: 0.4 }],
        ["classroom", { position: { x: 0.45, y: 0.7 }, size: 0.1, rotation: 0.3 }]
      ]),
      defaultConfig: { position: { x: 0.45, y: 0.75 }, size: 0.2, rotation: 0.05 }
    }
  ];

}
