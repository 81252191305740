import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-functionality-item',
  templateUrl: './functionality-item.component.html',
  styleUrls: ['./functionality-item.component.scss']
})
export class FunctionalityItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
