/**
 * Asynchronously waits for a given ms. 
 * 
 * @param millisec time to wait in ms
 * @returns a promise which is resolved when the given time is elapsed
 */
export function awaitFor(millisec:number):Promise<void> {
  return new Promise<void>(
    (resolve, reject) => {
      setTimeout(
        () => {
          resolve();
        },
        millisec
      )
    }
  );
}

/**
 * Clamps a middle value within a range of values between a defined minimum bound and a maximum bound.
 * It's behavour is the same as the CSS's clamp() function.
 * https://developer.mozilla.org/en-US/docs/Web/CSS/clamp
 * 
 * @param min the lower bound
 * @param prefered the prefered value
 * @param max the upper bound
 * 
 * @returns the calculated (clamped) value
 */
export function clamp(min: number, prefered: number, max: number): number {
  return Math.max(min, Math.min(prefered, max));
}


