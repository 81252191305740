import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { appendFileSync } from 'fs';
import { BuyButtonClickEvent } from 'src/app/modules/shared/components/packages/package.component';
import { awaitFor } from 'src/app/modules/shared/functions/misc-functions';
import { GoToCheckoutHandler } from '../../../pricing-page/components/purchase-dialog/purchase-dialog.component';
import { PromotionCode } from '../../../pricing-page/models/promotion-code';
import { PurchasablePackage } from '../../../pricing-page/models/purchasable-package';
import { PackageDefinitionApiService } from '../../../pricing-page/services/package-definition-api.service';
import { PackageDefinitionConverterService } from '../../../pricing-page/services/package-definition-converter.service';
import { PackagePurchaseService } from '../../../pricing-page/services/package-purchase.service';
import { PurchaseDialogOpenerService } from '../../../pricing-page/services/purchase-dialog-opener.service';
import { PlatformService } from 'src/app/services/common/platform-service';

@Component({
  selector: 'app-purchase-section',
  templateUrl: './purchase-section.component.html',
  styleUrls: ['./purchase-section.component.scss']
})
export class PurchaseSectionComponent implements OnInit, AfterViewInit {
  /**
   * error esetén: Megjelenítünk egy gombot ami a mrkresz.hu/vasarlas oldalra irányít
   * loading: töltő megjelenítése
   * ready: minden rendben, megjelenítjük a csomagokat
   * idle: semmi nem történt még,nem került bele a viewport-ba
   */
  protected componentState: "error" | "loading" | "ready" | "idle" = "idle";

  // Igaz az értéke, ha már legalább egyszer volt a viewport-ban
  isFirstIntersectionHappened: boolean = false;

  allPackages: Array<PurchasablePackage> = [];
  practicalPackages: Array<PurchasablePackage> = [];

  @Input()
  promotionCode: PromotionCode | null;


  constructor(
    private element: ElementRef,
    private packageDefinitionService: PackageDefinitionApiService,
    private packageDefinitionConverter: PackageDefinitionConverterService,
    private ngZone: NgZone,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private purchaseService: PackagePurchaseService,
    private purchaseDialogOpener: PurchaseDialogOpenerService,
    private packagePurchaseService: PackagePurchaseService,
    private platformService: PlatformService
  ) { }


  ngOnInit(): void { }


  ngAfterViewInit() {
    // Akkor fogjuk betölteni a csomagokat amikor a viewport a közelében van
    if (this.platformService.getIsPlatformBrowser()) {
      this.addIntersectionObserver();
    }
  }

  async loadComponent() {
    this.componentState = "loading";
    this.changeDetectorRef.detectChanges();

    const retryCount = 3;

    // Fontos, hogy megpróbáljuk betölteni, próbáljuk meg 3x
    for (let i = 0; i < retryCount; i++) {
      try {
        const packageDefinitions = await this.packageDefinitionService.fetchPackageDefinitions();
        this.allPackages = packageDefinitions.packageDefintiions
          .map(packageDefinition => this.packageDefinitionConverter.convertToPurchasablePackage(packageDefinition))

        this.practicalPackages = this.allPackages
          .filter(pack => packageDefinitions.groups.practical.includes(pack.packageDefinitionUuid));


        this.componentState = "ready";
        this.changeDetectorRef.detectChanges();
        return;
      } catch (e) {
        if (i != retryCount - 1) {
          await awaitFor(800);
          continue;
        }

        console.log("component state error");
        this.componentState = "error";
        this.changeDetectorRef.detectChanges();
        return;
      }
    }
  }

  onTapPurchase(buyButtonClickEvent: BuyButtonClickEvent) {
    this.purchaseDialogOpener.openDialog(
      buyButtonClickEvent.purchasablePackage,
      this.getCrossSellPackagesTo(buyButtonClickEvent.purchasablePackage.packageDefinitionUuid),
      this.onGoToCheckoutButtonClick,
      buyButtonClickEvent.appliedPromotionCode
    );
  }

  private onGoToCheckoutButtonClick: GoToCheckoutHandler = async (
    purchasablePackage: PurchasablePackage,
    crossSellingPackages: Array<PurchasablePackage>,
    email: string,
    isGift: boolean,
    initCustomerFacingPromotionCode?: string
  ) => {
    return await this.packagePurchaseService.goToCheckout(purchasablePackage, email, isGift, crossSellingPackages, initCustomerFacingPromotionCode);
  }



  getCrossSellPackagesTo(packageDefinitionUuid: string): PurchasablePackage[] {
    return this.purchaseService.getCrossSellPackagesToSpecificPackage(this.allPackages, packageDefinitionUuid);
  }


  addIntersectionObserver() {
    let observer = new IntersectionObserver(() => {
      // intersection és resize observer nincs monkey patch-elve
      // angular által, ezáltal angular zone-on kívül fut a callback

      this.ngZone.run(() => {
        if (this.isFirstIntersectionHappened == false) {
          this.isFirstIntersectionHappened = true;
          this.loadComponent();
        }
      });

    });
    observer.observe(this.element.nativeElement);
  }

  // Ha nem sikerült betölteni akkor egy gombot jelenítünk meg
  // amire ha rákattint akkor a default vasarlas oldalra visszük
  onTapBackupPlanPurchaseButton() {
    const absoluteTargetPath = "/vasarlas";
    // set window location href to absolute path
    window.location.href = absoluteTargetPath;
  }

}
