
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'numberShortFormNotation'
})
export class NumberShortFormNotationPipe implements PipeTransform {
  constructor() { }

  transform(value: number): string {
    // If the value is less, than ten thousand, return the number as is
    if(value < 10**4) {
      return value.toFixed(0);
    }

    // If the value greater than ten thousand, but less than a million
    // return the value with a K in the place of the thousands
    if(value < 10**6) {
      value = Math.trunc(value / 10**2) / 10;
      return value.toFixed(1) + "K";
    }

    // If the value greater than a million, but less than a billion
    // return the value with an M in the place of the millions
    if(value < 10**9) {
      value = Math.trunc(value / 10**5) / 10;
      return value.toFixed(1) + "M";
    }

    // Otherwise the value is greater than a billion
    // return the value with a B in the place of the billions
    value = Math.trunc(value / 10**8) / 10;
    return value.toFixed(1) + "B";
  }
}