import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-download',
  templateUrl: './landing-download.component.html',
  styleUrls: [
    './landing-download.component.scss',
    '../../../../../styles/common-page-section-styles.scss'
  ]
})
export class LandingDownloadComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
