<div class="main-container">
  <div class="header-wrapper">
    <span class="headline">
      TikTok 430K 🚀
    </span>
  
    <br>
    <span class="subtext">
      A TikTok-on több, mint <b>430.000 tanuló</b> követ minket, és összesen <b> 400 millió megtekintést</b> értünk el. Minden héten interaktív élő adásokkal várunk titeket! 🤩
    </span>
  
  </div>
  
  
  <app-tiktok-videos class="tiktok-container" *ngIf="tiktokVideos.length != 0" [tiktokVideos]="this.tiktokVideos">
  </app-tiktok-videos>
</div>




