<div class="main-wrapper">

  <div class="responsive-mobile">
    <div class="text-wrapper">
      <span class="headline">
        Készülj velünk a <br> vizsgádra és<br> GARANTÁLJUK a<br>sikered!
      </span>
      <br>

      <p style="margin-top:20px;display:block;" class="subtext">
        Mi a legjobb módja az otthoni készülésnek? <b>Interaktív vizsgaútvonalak videón!</b>
        Gyakorolj az appban minimum 15 órát és ha ezután sem sikerült a vizsgád, <b>akkor álljuk a vizsga díjat!</b> 😎
      </p>

      <button (click)="purchaseClicked.emit()" class="purchase-button">
        MEGVESZEM
      </button>

    </div>

    <div style="display:block;position:relative;margin-top:15px;">
      <img loading="lazy" fetchpriority="low" alt="Vizsgaútvonal videón" class="img" style="width:100%;" src="assets/practical-sales-page/images/wave-mockup.webp">
    </div>
  </div>


  <div class="responsive-laptop">
    <div style="display:flex;column-gap:50px;">
      <div style="display:block;flex:1;">
        <img loading="lazy" alt="Vizsgaútvonal videón" class="img" style="width:85%;" src="assets/practical-sales-page/laptop/girlmockup.webp">
      </div>

      <div style="flex:1;" class="text-wrapper">
        <h1 class="headline">
          Készülj velünk a vizsgádra és GARANTÁLJUK a sikered!
        </h1>
        <br>

        <p style="margin-top:20px;display:block;" class="subtext">
          Mi a legjobb módja az otthoni készülésnek? <b>Interaktív vizsgaútvonalak videón!</b>
          Gyakorolj az appban minimum 15 órát és ha ezután sem sikerült a vizsgád, <b>akkor álljuk a vizsga díjat!</b> 😎
        </p>

        <button (click)="purchaseClicked.emit()" class="purchase-button">
          MEGVESZEM
        </button>

      </div>


    </div>

  </div>
</div>