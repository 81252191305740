import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { PlatformService } from 'src/app/services/common/platform-service';

@Component({
  selector: 'app-christmas-hero',
  templateUrl: './christmas-hero.component.html',
  styleUrls: ['./christmas-hero.component.scss']
})
export class ChristmasHeroComponent {
  @ViewChild('videoDesktop', { static: true })
  videoDesktop: ElementRef<HTMLVideoElement>;

  @ViewChild('videoMobile', { static: true })
  videoMobile: ElementRef<HTMLVideoElement>;


  constructor(
    private changeDet: ChangeDetectorRef,
    protected platformService: PlatformService,
  ) { }


  // Add resize observer to the video
  @HostListener('window:resize', ['$event'])
  onResizeWindow() {
    if (this.platformService.getIsPlatformBrowser()) {
      const ratio = window.innerWidth / window.innerHeight;
      const isMobile = ratio < 13/16;

      if(isMobile && !this.videoDesktop.nativeElement.paused){
        this.videoDesktop.nativeElement.pause();
      }
      if(!isMobile && !this.videoMobile.nativeElement.paused){
        this.videoMobile.nativeElement.pause();
      }


    }
  }

  playVideo() {
    const ratio = window.innerWidth / window.innerHeight;
    const isMobile = ratio < 13/16;

    const target = isMobile ? this.videoMobile : this.videoDesktop;

    if (target.nativeElement.paused) {
      target.nativeElement.play();
    } else {
      target.nativeElement.pause();
    }
    
  }
}
