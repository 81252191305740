<div class = "animation-container">
    <app-lottie-animation
        [animationPath] = "'assets/shared/lottie/error.json'"
    ></app-lottie-animation>
</div>
<div class = "error-texts">
    <div style = "margin-bottom: 5px;">
        {{ data.line1 }}
    </div>
    <div>
        {{ data.line2 }}
    </div>
</div>
<div class = "dialog-actions">
    <button
        blackButton
        class = "dialog-close-button"
        mat-dialog-close
    >
        Oké
    </button>
</div>
