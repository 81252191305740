import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { awaitFor } from 'src/app/modules/shared/functions/misc-functions';

@Component({
  selector: 'app-loader-screen',
  templateUrl: './loader-screen.component.html',
  styleUrls: ['./loader-screen.component.scss']
})
export class LoaderScreenComponent implements OnInit, OnDestroy {
  @ViewChild('bg1', { static: true }) bg1: ElementRef<HTMLDivElement>;
  @ViewChild('bg2', { static: true }) bg2: ElementRef<HTMLDivElement>;
  //@ViewChild('logoCircleWrapper') logoCircleWrapper: ElementRef<HTMLDivElement>;

  @Input()
  isLoaded$: BehaviorSubject<boolean>;
  isLoadedSubscription: Subscription;

  isLoaded: boolean = false;

  constructor() { }

  ngOnDestroy(): void {
    this.isLoadedSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.isLoadedSubscription = this.isLoaded$.subscribe(async (loadingState) => {
      this.isLoaded = loadingState;

      if (loadingState == true) {
        this.bg1.nativeElement.style.transform = "translateY(-" + ((window.innerHeight / 1.5)) + "px)";
        this.bg2.nativeElement.style.transform = "translateY(" + (window.innerHeight / 1.5) + "px)";
      }
    });
  }


  changeDet() {
    console.log("loader change det");
  }
}
