import { AnimationEvent } from '@angular/animations';
import { Platform } from '@angular/cdk/platform';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { customerIdLocalStorageName } from './constants/local-storage-names';
import { LocalStorageWithExpiryService } from './services/common/local-storage-with-expiry.service';
import { SEOService } from './services/common/seo.service';
import { filter, take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { PlatformService } from './services/common/platform-service';
import { MatDialog } from '@angular/material/dialog';
import { CustomMatDialogService } from './services/common/custom-mat-dialog-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isFooterShown: boolean = true;

  constructor(
    private platform: Platform,
    private routerService: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageWithExpiryService: LocalStorageWithExpiryService,
    private seoService: SEOService,
    @Inject(DOCUMENT) private document: Document,
    private platformService: PlatformService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    // If the platform is iOS Safari ...
    if (this.platform.IOS || this.platform.SAFARI) {
      // ... add the maximum scale attribute toe the viewport meta
      this.addMaximumScaleToMetaViewport();
    }

    // On the route changes, update the `isFooterShown` value
    this.routerService.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isFooterShown = this.getIsFooterShownValue();
      }
    });

    // If the set_customer_id_cookie parameter is set on the actual route, save it's value in a cookie
    const subscription: Subscription =
      this.activatedRoute.queryParams
        .pipe(filter((params) => params.set_customer_id_cookie != null), take(1))
        .subscribe((params) => {
          const oneHourInMs: number = 1000 * 60 * 60;

          this.localStorageWithExpiryService.setItem(
            customerIdLocalStorageName,
            params.set_customer_id_cookie,
            oneHourInMs
          );
        });

    // Add seo title + description change
    this.routerService.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        const seoTitle: string = event.snapshot.data?.seo?.title;
        const seoDescription: string = event.snapshot.data?.seo?.description;
        const keywords = event.snapshot.data?.seo?.keywords;

        if (seoTitle) {
          this.seoService.updateTitle(seoTitle);
        }

        if (seoDescription) {
          this.seoService.updateDescription(seoDescription);
        }

        if (keywords) {
          this.seoService.updateKeywords(keywords);
        }
      }
    });
  }

  addGoogleAnalyticsTag() {
    // ha szerver renderelés van nem adjuk hozzá
    if (this.platformService.getIsPlatformServer()) return;
    // ha nem prod, akkor szintén nem adjuk hozzá
    if(environment.production === false) {
      console.log("Google analytics not added, because the environment is NOT production");
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-5S0VBZ8NNJ';
    script.async = true;
    document.body.appendChild(script);

    const script2 = document.createElement('script');
    script2.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-5S0VBZ8NNJ');`;
    document.body.appendChild(script2);
  }

  ngAfterViewInit() {
    this.addGoogleAnalyticsTag();
    this.integrateTidioChat();
  }


/*   isFirstScrollDone = false;
  @HostListener('window:scroll', ['$event'])
  onWindowScrollListener() {
    if (this.isFirstScrollDone) return;
    
    this.isFirstScrollDone = true;
  } */

  integrateTidioChat() {
    if (this.platformService.getIsPlatformServer()) return;

    console.log("start loading tidio chat...");
    const script = document.createElement('script');
    script.src = '//code.tidio.co/8viwofqchvxmfwsxc06qrjcmvrstykcz.js';
    // set fetch priority
    script.setAttribute('fetchpriority', 'low');
    script.async = true;
    this.document.body.appendChild(script);
  }

  /**
   * If a router animation event is fired, this callback is invoked.
   *
   * @param event the animation event
   */
  public onRouterAnimationEvent(event: AnimationEvent): void {
    switch (event.phaseName) {
      case 'start':
        this.isFooterShown = false;
        /*         setTimeout(() => {
                  window.scrollTo(0, 0);
                }, event.totalTime / 2); */


        setTimeout(() => {
          this.isFooterShown = this.getIsFooterShownValue();
        }, event.totalTime);

        break;
      case 'done':
        this.isFooterShown = this.getIsFooterShownValue();
        break;
    }
  }

  /**
   * Adds the `maximum-scale` attribute to the viewport meta tag. This disables the zooming on
   * iOS mobile devices.
   */
  private addMaximumScaleToMetaViewport() {
    const el = document.querySelector('meta[name=viewport]');

    if (el !== null) {
      let content = el.getAttribute('content');
      let re = /maximum\-scale=[0-9\.]+/g;

      if (re.test(content)) {
        content = content.replace(re, 'maximum-scale=1.0');
      } else {
        content = [content, 'maximum-scale=1.0'].join(', ');
      }

      el.setAttribute('content', content);
    }
  }

  /**
   * Determines that on the actual route the foo
   *
   * @returns
   */
  private getIsFooterShownValue(): boolean {
    return (
      !this.routerService.url.includes('webview_app_intro')
    );
  }
}
