import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GoToCheckoutHandler, PurchaseDialogComponent } from "../components/purchase-dialog/purchase-dialog.component";
import { PromotionCode } from "../models/promotion-code";
import { PurchasablePackage } from "../models/purchasable-package";
import { PromotionCodeUtilityService } from "./promotion-code-utility.service";


@Injectable({
  providedIn:'root'
})
export class PurchaseDialogOpenerService {

  constructor(
    private dialogService: MatDialog,
    private promotionCodeUtilityService: PromotionCodeUtilityService
  ) {}

  public openDialog(
    purchasablePackage: PurchasablePackage,
    crossSellPackages: PurchasablePackage[],
    goToCheckoutHandler: GoToCheckoutHandler,
    promotionCode?: PromotionCode
  ): MatDialogRef<PurchaseDialogComponent, void> {
    // Calculate the discount gross price
    let discountedGrossPrice: number;
    const isDiscountExpired = promotionCode == null ? true : this.promotionCodeUtilityService.isDiscountExpired(promotionCode);

    if(isDiscountExpired) {
      discountedGrossPrice = purchasablePackage.grossPrice
    } else {
      discountedGrossPrice = this.promotionCodeUtilityService.getGrossPriceCalculatedWithDiscount(purchasablePackage, promotionCode);
    }

    return PurchaseDialogComponent.open(
      this.dialogService,
      {
        crossSellingPackages: crossSellPackages,
        package: purchasablePackage,
        grossPrice: purchasablePackage.grossPrice,
        grossPriceWithDiscount: discountedGrossPrice,
        goToCheckoutHandler: goToCheckoutHandler,
        initCustomerFacingPromotionCode: promotionCode?.customerFacingPromotionCode
      }
    )
  }
}