<div class="wrapper-grid">
  <div class="checkbox">
    <mat-checkbox [checked]="isChecked" (change)="onChangeCheckbox($event)">

    </mat-checkbox>
  </div>

  <div class="name" (click)="toggleCheckbox()">
    <span>{{purchasablePackageToCrossSell.displayedName}}</span>
  </div>

  <div class="description" (click)="toggleCheckbox()">
    <span>{{purchasablePackageToCrossSell.descriptionLines[0].explanation}}</span>
  </div>

  <div class="price">
    <span>+{{purchasablePackageToCrossSell.grossPrice}} {{purchasablePackageToCrossSell.currency}}</span>
  </div>


</div>