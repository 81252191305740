import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[gradientButton]'
})
export class GradientButtonDirective {

  @HostBinding('class')
  elementClass = 'gradient-button';

  constructor() { }

}
