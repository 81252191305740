import { Injectable } from '@angular/core';
import { ErrorHandler } from './error-handlers/error-handler';
import { NoInternetConnectionErrorHandler } from './error-handlers/no-internet-connection-error-handler';
import { ServerNotAvailableErrorHandler } from './error-handlers/server-not-available-error-handler';

@Injectable({
  providedIn: 'root'
})
export class ResponseErrorHandlerService {

  private readonly errorHandlers:Array<ErrorHandler>;

  constructor(
    private noInternetConnectionErrorHandler:NoInternetConnectionErrorHandler,
    private serverNotAvailableErrorHandler:ServerNotAvailableErrorHandler
  ) {
    this.errorHandlers = [
      this.noInternetConnectionErrorHandler,
      this.serverNotAvailableErrorHandler
    ]
  }

  /**
   * Pushes through the response on all 'registrated' error handlers.
   * 
   * @param response the response
   */
  public processResponse(response:any):void {
    this.errorHandlers.forEach(
      (errorHander:ErrorHandler) => {
        errorHander.processResponse(response);
      }
    );

  }
}
