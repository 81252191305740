import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageWithExpiryService {
  private readonly expireOnKeySuffix:string = "expire-on";

  constructor() { }

  /**
   * Creates the key for a given item's expiry.
   * 
   * @param keyOfOriginalStoredItem the key of the original stored item
   * 
   * @returns the key of the expiry for the item
   */
  private getExpireOnKeyForItem(keyOfOriginalStoredItem:string):string {
    return `${keyOfOriginalStoredItem}-${this.expireOnKeySuffix}`;
  }

  /**
   * Determines if the item is expired using it's stored expiry. If the item or the item's
   * expiry not found an exception is thrown. in this case or if the item is determined to
   * be expired all related data removed (item and it's expiry).
   * 
   * @param key the key of the queried item
   * 
   * @returns true, if the item is expired, false otherwise
   */
  public isItemExpired(key:string):boolean {
    // Get the item and it's expiry
    const item:string|null = localStorage.getItem(key);
    const itemExpiry:string|null = localStorage.getItem(this.getExpireOnKeyForItem(key));

    // If the item not exists
    if(item == null) {
      // Remove all related data (in this case the expiry)
      this.removeItem(key);
      // And throw an exception
      throw new ItemExpiryCheckError("ItemNotFound");
    }
    
    // If the expiry not exists
    if(itemExpiry == null) {
      // Remove all related data (in this case the item)
      this.removeItem(key);
      // And throw an exception
      throw new ItemExpiryCheckError("ExpiryNotFound");
    }

    // Determine that the item is expired
    const isExired:boolean = Number(itemExpiry) < Date.now();
    if(isExired) {
      // If so, remove the item and it's expiry
      this.removeItem(key);
    } 

    // Return the expiry state
    return isExired;
  }

  /**
   * Sets the value of the pair identified by key to value, creating a new key/value pair
   * if none existed for key previously and the expiry data for it with the given value
   * (with `KEY-expire-on` key, where "KEY" is the key of the new item).
   * 
   * 
   * Throws a "QuotaExceededError" DOMException exception if the new value couldn't be set.
   * (Setting could fail if, e.g., the user has disabled storage for the site, or if the quota
   * has been exceeded.)
   * 
   * @param key the key of the new item
   * @param value the value if the new item
   * @param expireInMs the exiration in millisecond from now
   */
  public setItem(key:string, value:string, expireInMs:number):void {
    // Save the value
    localStorage.setItem(key, value);

    // Save the expire information
    const expireKey:string = this.getExpireOnKeyForItem(key);
    const expireValue:number = Date.now() + expireInMs;
    localStorage.setItem(expireKey, expireValue.toString());
  }

  /**
   * Returns the current value associated with the given key, or null if the given key
   * does not exist or it's value is expired.
   * 
   * @param key the key of the item
   * 
   * @returns the stored value for the item
   */
  public getItem(key:string):string|null {
    const item:string|null = localStorage.getItem(key);
    const itemExpiry:string|null = localStorage.getItem(this.getExpireOnKeyForItem(key));

    if(item == null || itemExpiry == null || this.isItemExpired(key)) {
      this.removeItem(key);
      return null;
    }

    return item;
  }

  /**
   * Removes the key/value pair with the given key, if a key/value pair with the given key exists.
   * It also removes the expiry data associated with the item.
   * 
   * @param key the key of the item
   */
  public removeItem(key:string):void {
    localStorage.removeItem(key);
    localStorage.removeItem(this.getExpireOnKeyForItem(key));
  }

}

export type ItemExpiryCheckErrorType = "ItemNotFound" | "ExpiryNotFound";

export class ItemExpiryCheckError extends Error {
  constructor(errorType:ItemExpiryCheckErrorType) {
    super(errorType);
  }
}
