<!-- {{changeDet()}} -->

<div class="main-container">
  <div class="grid">
    <div style="grid-area: headline;">
      <h2 class="headline">
        A sikeres forgalmi vizsga titka
      </h2>

      <p style="margin-top:30px;" class="successful-exam-text">
        Az, hogy <b>ne stresszelj a vizsgán</b>... Mi megmutatjuk neked az összes buktatót 3 különböző mód csoportosítva!
      </p>
    </div>




    <div class="subhead-wrapper">
      <div #item0 class="text-item">
        <div style="position:relative;display:inline-block;">
          <h3 class="subhead">
            1. Térképes kereső
          </h3>

          <div class="progress-wrapper">
            <div class="progress active">
            </div>

            <div class="progress inactive">
            </div>
          </div>
        </div>

        <p class="text">
          Megvan melyik helyszíntől parázol? <br>Az appban kattints és indul a videó! 🤩
        </p>
      </div>


      <div #item1 class="text-item">
        <div style="position:relative;display:inline-block;">
          <h3 class="subhead">
            2. Teljes útvonalak
          </h3>

          <div class="progress-wrapper" style="opacity:0">
            <div class="progress active">
            </div>

            <div class="progress inactive">
            </div>
          </div>
        </div>

        <p class="text">
          A konkrét útvonalak érdekelnek? Járd be az összes vizsgaútvonalat videón! 💥
        </p>
      </div>


      <div #item2 class="text-item">
        <div style="position:relative;display:inline-block;">
          <h3 class="subhead">
            3. Zónákra bontás
          </h3>

          <div class="progress-wrapper" style="opacity:0">
            <div class="progress active">
            </div>

            <div class="progress inactive">
            </div>
          </div>
        </div>

        <p class="text">
          A városod felbontottuk apróbb részekre, így könnyebb lesz a vizsgára felkészülni! 📍
        </p>
      </div>
    </div>


    <div class="mockup-wrapper" (click)="onTapPhone()">
      <ng-container #mobileMockupNgContainer>
      </ng-container>

      <!-- <app-mobile-mockup (onMockupLoaded)="onMockupLoaded();" [debugEnabled]="false" [textures]="this.mockupTextures"
        [textureIndex]="textureIndex" [cameraSettings]="this.mockupCameraSettings"
        style="height:100%;width:100%;display:block;" [mockupOpacity]="this.mockupOpacity"
        [currentPhoneRotation]="this.mockupRotation">
      </app-mobile-mockup> -->
    </div>
  </div>


  <!-- Telefonon ezt jelenítjük meg és tableten-->
  <div class="wave-mobile">
    <img loading="lazy" alt="" style="width:100%;display:block;" src="assets/practical-sales-page/waves.svg">
  </div>
</div>

<!-- Ez pedig laptop -->
<div class="wave-laptop">
  <img alt="" style="width:100%;display:block;" src="assets/practical-sales-page/laptop/waves_laptop.svg">
</div>