import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[grayButton]'
})
export class GrayButtonDirective {

  @HostBinding('class')
  elementClass = 'gray-button';

  constructor() { }

}
