
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

/**
 * Sanitize HTML
 */
@Pipe({
    name: 'joinArrayString'
})
export class JoinArrayStringPipe implements PipeTransform {
    constructor() { }

    transform(value: ReadonlyArray<string>, delimiter: string): string {
        return value.join(delimiter);
    }
}