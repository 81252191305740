<!-- Header in desktop view -->
<div class="desktop-header-items">
  <div>
    <a class="logo" [routerLink]="'/'">
      <img alt="" style="width:100%;height:100%;" src="/assets/shared/mrkresz-logo.webp"/>
    </a>
  </div>
  <a *ngFor="let menuItem of menuItems" [routerLink]="menuItem.route">
    <div [class.dark]="headerService.isDarkMode()" [class.light]="!headerService.isDarkMode()" class="menu-item"
      [class.highlighted]="menuItem.highlighted">
      {{ menuItem.displayedName }}
    </div>
  </a>
</div>

<!-- Header in mobile/tablet view -->
<div class="mobile-header-items">
  <div>
    <a class="logo" style="text-decoration:none" [routerLink]="'/'">
      <img alt="" src="/assets/shared/mrkresz-logo.webp" style="width:100%;height:100%;" />
    </a>
  </div>


  <div [class.dark]="headerService.isDarkMode()" [class.light]="!headerService.isDarkMode()" (click)="openMobileMenu()"
    class="hamburger-container"
  >
    <div [class.dark]="headerService.isDarkMode()" [class.light]="!headerService.isDarkMode()" class="hamburger-line">
    </div>

    <div [class.dark]="headerService.isDarkMode()" [class.light]="!headerService.isDarkMode()" class="hamburger-line">
    </div>

    <div [class.dark]="headerService.isDarkMode()" [class.light]="!headerService.isDarkMode()" class="hamburger-line">
    </div>
  </div>

</div>



<!-- Menu for the mobil/tablet view -->
<div #headerMobileMenu [style.pointerEvents] = "isMenuOpen ? 'all' : 'none'"
 class="header-mobile-menu">
  <app-header-mobile-menu [menuItems]="menuItems"
    (menuClosedEvent)="handleMenuClosedEvent()"></app-header-mobile-menu>
</div>