import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';
import { MatDialogModule } from '@angular/material/dialog'
import { DetailsButtonComponent } from './components/details-button/details-button.component';
import { SlideshowComponent } from './components/slideshow/slideshow.component';
import { SlideshowItemComponent } from './components/slideshow/slideshow-content/slideshow-item.component';
import { FunctionalityItemComponent } from './components/functionality-item/functionality-item.component';
import { GradientButtonDirective } from './directives/gradient-button.directive';
import { BlackButtonDirective } from './directives/black-button.directive';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { LottieAnimationComponent } from './components/lottie-animation/lottie-animation.component';
import { ProsListComponent } from './components/pros-list/pros-list.component';
import { ProsItemCardComponent } from './components/pros-list/pros-item-card/pros-item-card.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { GrayButtonDirective } from './directives/gray-button.directive';
import { MediaPreviewComponent } from './components/file-selector-with-preview/file-selector-with-preview.component';
import { YoutubeVideoDialogComponent } from './components/youtube-video-dialog/youtube-video-dialog.component';
import { SafePipe } from './pipes/safe.pipe';
import { JoinArrayStringPipe } from './pipes/join-array-string-pipe';
import { CommonPageWelcomeComponent } from './components/common-page-welcome/common-page-welcome.component';
import { InputWithSeparatorDirective } from './directives/input-with-separator.directive';
import { NeonTextComponent } from './components/neon-text/neon-text.component';
import { OpenableYoutubeVideoPreviewComponent } from './components/openable-youtube-video-preview/openable-youtube-video-preview.component';
import { TiktokVideosComponent } from './components/tiktok-videos/tiktok-videos.component';
import { TiktokVideoCardComponent } from './components/tiktok-videos/tiktok-video-card/tiktok-video-card.component';
import { NumberShortFormNotationPipe } from './pipes/number-short-form-notation.pipe';
import { SwiperModule } from "swiper/angular";
import { PageIndicatorComponent } from './components/page-indicator/page-indicator.component';
import { GlobalSassVariableProviderService } from 'src/app/modules/shared/services/global-sass-variable-provider.service';
import { GlobalScreenBreakpointProviderService } from './services/global-srceen-breakpoint-provider.service';
import { CssVariableExtractor } from './services/css-variable-extractor.service';
import { JoinDetailsItemComponent } from './components/join-details-item/join-details-item.component';
import { JoinResultDialogComponent } from './components/classroom-join-result-dialog/join-result-dialog.component';
import { AvailableCitiesComponent } from '../pages/practical-sales-page/components/available-cities/available-cities.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AvailableCitiesDialogComponent } from './components/available-cities-dialog/available-cities-dialog.component';
import { PackageComponent } from './components/packages/package.component';
import { PackageInformationDialogComponent } from './components/package-information-dialog/package-information-dialog.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

// Note: We need a separate function as it's required by the AOT compiler.
export function playerFactory(): LottiePlayer {
  return player;
}

@NgModule({
  declarations: [
    DetailsButtonComponent,
    SlideshowComponent,
    SlideshowItemComponent,
    JoinArrayStringPipe,
    FunctionalityItemComponent,
    UnderConstructionComponent,
    LottieAnimationComponent,
    ProsListComponent,
    ProsItemCardComponent,
    GradientButtonDirective,
    BlackButtonDirective,
    GrayButtonDirective,
    ErrorDialogComponent,
    AvailableCitiesDialogComponent,
    MediaPreviewComponent,
    YoutubeVideoDialogComponent,
    SafePipe,
    CommonPageWelcomeComponent,
    InputWithSeparatorDirective,
    NeonTextComponent,
    OpenableYoutubeVideoPreviewComponent,
    TiktokVideosComponent,
    PackageComponent,
    PackageInformationDialogComponent,
    TiktokVideoCardComponent,
    NumberShortFormNotationPipe,
    PageIndicatorComponent,
    JoinDetailsItemComponent,
    JoinResultDialogComponent
  ],
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }),
    MatDialogModule,
    MatProgressSpinnerModule,
    SwiperModule,
    NgScrollbarModule
  ],
  providers: [
    GlobalSassVariableProviderService,
    GlobalScreenBreakpointProviderService,
    CssVariableExtractor
  ],
  exports: [
    DetailsButtonComponent,
    SlideshowComponent,
    SlideshowItemComponent,
    FunctionalityItemComponent,
    GradientButtonDirective,
    BlackButtonDirective,
    GrayButtonDirective,
    UnderConstructionComponent,
    LottieAnimationComponent,
    ProsListComponent,
    ErrorDialogComponent,
    MediaPreviewComponent,
    YoutubeVideoDialogComponent,
    JoinArrayStringPipe,
    SafePipe,
    NumberShortFormNotationPipe,
    CommonPageWelcomeComponent,
    InputWithSeparatorDirective,
    NeonTextComponent,
    OpenableYoutubeVideoPreviewComponent,
    TiktokVideosComponent,
    TiktokVideoCardComponent,
    JoinDetailsItemComponent,
    JoinResultDialogComponent,
    PackageComponent,
  ]
})
export class SharedModule { }
