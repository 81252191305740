<div class="header">

  <div>
    <p class="could-we-help-text">
      Segíthetünk valamiben?
    </p>


    <span style="color: rgba(0, 0, 0, 0.374);font-weight:400;font-size:13px;display:block;">
      +36 30 207 6099
    </span>
    
    
  </div>


  <div class="header-close-button-wrapper" (click)="closeMenu()">
    <img loading="lazy"  alt="Menü" class="header-close-button" src="assets/shared/svg/x.svg">
  </div>
</div>



<div class="menu-items">
  <a #menuItemLink *ngFor="let menuItem of menuItems" [routerLink]="menuItem.route" (click)="closeMenu()"
    class="menu-item-link">
    <div class="menu-item">
      <div style="flex:1;">
        <span class="menu-item-headline">
          {{ menuItem.displayedName }}
        </span>

        <span class="menu-item-text">
          {{menuItem.text}}
        </span>
      </div>


      <!-- right arrow -->
      <mat-icon style="color: rgba(128, 128, 128, 0.602);">keyboard_arrow_right</mat-icon>
    </div>
    <!-- Divider -->
    <div class="menu-item-divider"></div>
  </a>
</div>