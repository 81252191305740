import { Component, Input, OnInit } from '@angular/core';

export type InstructorReviewItemComponentInput = {
  name: string;
  text: string;
  profileImageUrl: string;
}

@Component({
  selector: 'app-instructor-review-item',
  templateUrl: './instructor-review-item.component.html',
  styleUrls: ['./instructor-review-item.component.scss']
})
export class InstructorReviewItemComponent implements OnInit {
  @Input()
  data: InstructorReviewItemComponentInput;

  constructor() { }

  ngOnInit(): void {
  }

}
