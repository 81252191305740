import { Injectable } from "@angular/core";
import { GlobalSassVariableProviderService } from "./global-sass-variable-provider.service";

@Injectable()
export class GlobalScreenBreakpointProviderService {
  // Theese values has to be as the same as the exported css variable names in the media-query.scss
  private readonly maxMobile: string = "mobile-max-width";
  private readonly minTablet: string = "tablet-min-width";
  private readonly maxTablet: string = "tablet-max-width";
  private readonly minLaptop: string = "laptop-min-width";
  private readonly maxLaptop: string = "laptop-max-width";
  private readonly minFullHd: string = "fullhd-min-width";
  private readonly maxFullHd: string = "fullhd-max-width";
  private readonly minFourH: string = "four-k-min-width";
  
  constructor(
    private globalSassVariableProviderService: GlobalSassVariableProviderService
  ) {}

  /**
   * Gets the maximal width in pixels for mobile-like devices from the SCSS.
   * 
   * @returns the width value in pixels
   */
  public getMobileMaxWidthInPx(): number {
    return this.globalSassVariableProviderService.getPixelSuffixedInjectedCssVariableValue(this.maxMobile);
  }

   /**
   * Gets the minimal width in pixels for tablet-like devices from the SCSS.
   * 
   * @returns the width value in pixels
   */
  public getTabletMinWidthInPx(): number {
    return this.globalSassVariableProviderService.getPixelSuffixedInjectedCssVariableValue(this.minTablet);
  }

   /**
   * Gets the maximal width in pixels for tablet-like devices from the SCSS.
   * 
   * @returns the width value in pixels
   */
  public getTabletMaxWidthInPx(): number {
    return this.globalSassVariableProviderService.getPixelSuffixedInjectedCssVariableValue(this.maxTablet);
  }

  /**
   * Gets the minimal width in pixels for laptop-like devices from the SCSS.
   * 
   * @returns the width value in pixels
   */
  public getLaptopMinWidthInPx(): number {
    return this.globalSassVariableProviderService.getPixelSuffixedInjectedCssVariableValue(this.minLaptop);
  }

  /**
   * Gets the maximal width in pixels for laptop-like devices from the SCSS.
   * 
   * @returns the width value in pixels
   */
  public getLaptopMaxWidthInPx(): number {
    return this.globalSassVariableProviderService.getPixelSuffixedInjectedCssVariableValue(this.maxLaptop);
  }

  /**
   * Gets the minimal width in pixels for full HD-like devices from the SCSS.
   * 
   * @returns the width value in pixels
   */
  public getFullHdMinWidthInPx(): number {
    return this.globalSassVariableProviderService.getPixelSuffixedInjectedCssVariableValue(this.minFullHd);
  }

  /**
   * Gets the maximal width in pixels for full HD-like devices from the SCSS.
   * 
   * @returns the width value in pixels
   */
  public getFullHdMaxMaxWidthInPx(): number {
    return this.globalSassVariableProviderService.getPixelSuffixedInjectedCssVariableValue(this.maxFullHd);
  }

  /**
   * Gets the minimal width in pixels for 4k-like devices from the SCSS.
   * 
   * @returns the width value in pixels
   */
  public getFourkMinMaxWidthInPx(): number {
    return this.globalSassVariableProviderService.getPixelSuffixedInjectedCssVariableValue(this.minFourH);
  }
}