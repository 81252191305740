
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(
    @Inject(DOCUMENT) private dom,
    private titleSvc: Title,
    private metaSvc: Meta,
  ) { }


  updateTitle(title: string){
    this.titleSvc.setTitle(title)
  }

  updateDescription(content: string) {
    this.metaSvc.updateTag({ name: 'description', content })
  }


  updateKeywords(keywords: string[]){
    this.metaSvc.updateTag({ name: 'keywords', content: keywords.join(',') });
  }
}