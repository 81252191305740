<div class = "background-rectangles-container">
    <div
        #backgroundRectangle
        *ngFor = "let rectangle of rectanglesConfigs"
        class = "background-rectangle"
    ></div>
</div>
<div

>
    <router-outlet #outlet = "outlet"></router-outlet>
</div>