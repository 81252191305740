<!-- For tablet and desktop -->
<div
  *ngIf="visibleVideosContainer === 'grid'"
  class="grid-container"
>
  <app-tiktok-video-card
    *ngFor="let tiktokVideo of displayedTiktokVideos"
    [tiktokVideo]="tiktokVideo"
    class="tiktok-video-card"
  ></app-tiktok-video-card>
</div>

<!-- For mobile -->
<swiper
  *ngIf="visibleVideosContainer === 'swiper'"
  [slidesPerView]="'auto'"
  [spaceBetween]="10"
  slideClass="my-slide-class"
  [freeMode]="true"
  class="swiper"
>
  <ng-template
    swiperSlide
    *ngFor="let tiktokVideo of displayedTiktokVideos"
  >
    <app-tiktok-video-card
      class="tiktok-video-card"
      [tiktokVideo]="tiktokVideo"
    ></app-tiktok-video-card>
  </ng-template>
</swiper>