import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChristmasPageComponent } from './christmas-page.component';
import { PracticalSalesPageModule } from '../practical-sales-page/practical-sales-page.module';
import { ChristmasHeroComponent } from './components/christmas-hero/christmas-hero.component';
import { SharedModule } from '../../shared/shared.module';



@NgModule({
  declarations: [
    ChristmasPageComponent,
    ChristmasHeroComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    PracticalSalesPageModule
  ]
})
export class ChristmasModule { }
