<div class="main-container" *ngIf="package">
  <div class="gradient-background">
    <div class="name">
      {{ package.displayedName }}
    </div>
  </div>
  <div class="content">
    <!-- sima ár, vagy kedvezményes ha van discount -->
    <div style="display: flex">
      <div style="flex: 1">
        <!--  Üres box, csak arra kell,hogy 3 részre felosszuk a containert így az ár középpen lesz, mellette pedig a discount -->
      </div>

      <!-- Ár -->
      <div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 7px;
          "
          *ngIf="hasDiscount()"
        >
          <!-- Lehúzott összeg -->
          <div style="text-align: center">
            <s style="font-size: 22px; font-weight: 500; color: #b9b8b8">{{
              this.package.grossPrice
            }}</s>
            <span style="font-size: 22px; font-weight: 500; color: #b9b8b8">
              Ft
            </span>
          </div>

          <div class="price">
            {{ this.getGrossPriceCalculatedWithDiscount() }}
            {{ package.currency }}
          </div>
        </div>

        <div style="flex: 1" *ngIf="!hasDiscount()">
          <div style="margin-top: 15px" class="price">
            {{ this.getGrossPriceWithoutDiscount() }} {{ package.currency }}
          </div>
        </div>
      </div>

      <!-- Discount circle -->
      <div style="display: block; flex: 1; display: block; position: relative">
        <div
          *ngIf="hasDiscount()"
          class="promotion-percentage-circle"
          style="display: flex; justify-content: center; align-items: center"
        >
          <span class="glow-discount-percentage-text">
            -{{ this.appliedPromotionCode.discountPercentage.toString() }}%
          </span>
        </div>
      </div>
    </div>

    <!-- Visszaszámlálás ha van expire a promóciós kódon -->
    <div
      style="text-align: center"
      *ngIf="
        hasDiscount() && this.appliedPromotionCode.expireTimestampInMs != null
      "
    >
      <span
        style="
          margin-top: 5px;
          display: inline-block;
          width: 127px;
          border-radius: 5px;
          background-color: #f8f8f6;
          padding: 5px;
        "
      >
        {{ getDiscountExpireTime() }}
      </span>
    </div>

    <ng-scrollbar [autoHeightDisabled]="false" class="package-contents">
      <div
        *ngFor="let packageDescriptionLine of package.descriptionLines"
        class="package-content-item"
      >
        <div class="package-content-item-bullet">
          <img loading="lazy" alt="" src="assets/shared/svg/check.svg" class="check" />
        </div>
        <div
          [class.clickable]="packageDescriptionLine.explanation"
          (click)="openExplanationDialog(packageDescriptionLine)"
        >
          {{ packageDescriptionLine.text }}
          <span
            *ngIf="packageDescriptionLine.explanation"
            class="explanation-star"
          >
            *
          </span>
        </div>
      </div>
    </ng-scrollbar>
    <div class="purchase-container">
      <div class="purchase-button" (click)="onBuyButtonClick()">
        Megveszem
      </div>
    </div>
  </div>
</div>
<div *ngIf="!package" class="package-loading">
  <!-- Loading -->
</div>
