<div class = "section-title-big">
    Ti írtátok ❤️
</div>

<div class = "slideshow-container">
    <app-slideshow
        [showPositionDots] = "true"
        [stepperClass] = "'review-slideshow-stepper'"
        [contentClass] = "'review-slideshow-content'"
    >
        <app-slideshow-item *ngFor = "let review of reviews">
            <app-review-item
                [review] = "review"
            ></app-review-item>
        </app-slideshow-item>
    </app-slideshow>
</div>
