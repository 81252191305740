// ########## Modules and services ##########
// Built-in components and modules
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
// Material modules
import { MatDialog, MatDialogModule } from '@angular/material/dialog'

// ########## Components ##########
// Built-in components
import { AppComponent } from './app.component';
// Custom components
import { FooterComponent } from './components/footer/footer.component';
import { ContentComponent } from './components/content/content.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderMobileMenuComponent } from './components/header/header-mobile-menu/header-mobile-menu.component';
import { LandingPageModule } from './modules/pages/landing-page/landing-page.module';
// Custom provided services
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './services/common/timeout-interceptor';
import { SharedModule } from './modules/shared/shared.module';
import { TidioChatService } from './services/common/tidio-service';
import { CustomMatDialogService } from './services/common/custom-mat-dialog-service';

import { MatIconModule } from '@angular/material/icon';
import { PracticalSalesPageModule } from './modules/pages/practical-sales-page/practical-sales-page.module';
import { ToastrModule } from 'ngx-toastr';
import { InlineGlobalStyleComponent } from './styles/inline-global-style/inline-global-style.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PlaygroundComponent } from './components/playground/playground.component';
import { ChristmasModule } from './modules/pages/christmas/christmas.module';

@NgModule({
  declarations: [
    PlaygroundComponent,
    AppComponent,
    FooterComponent,
    ContentComponent,
    HeaderComponent,
    HeaderMobileMenuComponent,
    InlineGlobalStyleComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    MatIconModule,
    HttpClientModule,
    MatButtonModule,

    PracticalSalesPageModule,
    ChristmasModule,
    LandingPageModule,

    SharedModule,
    MatDialogModule,
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: NoCachingInterceptor, multi: true },
    provideClientHydration(
      // Default kikapcsoljuk ameddig nem találunk lehetőséget arra, hogy specifikus komponensekre kikapcsoljuk
      withNoHttpTransferCache()
    ),
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 60000 },
    CustomMatDialogService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
