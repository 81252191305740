<div class="main-container">
  <div class="main-flex-item">
    <ng-container *ngIf="this.componentState == 'idle'">
      IDLE
    </ng-container>
  
  
    <ng-container *ngIf="this.componentState == 'loading'">
      <div style="display:flex; justify-content: center;">
        <mat-spinner>
  
        </mat-spinner>
      </div>
  
    </ng-container>
  
  
    <ng-container *ngIf="this.componentState == 'error'">
      <button (click)="onTapBackupPlanPurchaseButton()" class="purchase-button">
        MEGVESZEM
      </button>
    </ng-container>
  
    <ng-container *ngIf="this.componentState == 'ready'">
  
      <span class="headline">
        AJÁNLATAINK
      </span>
      
      <br>
  
      <span class="text">
        Nézd végig az <b>összes útvonalat</b> korlátlanul, <b>kevesebb mint egy</b> gyakorlati <b>óra áráért</b>.
      </span>
  
      <div class="package-container">
        <app-package [appliedPromotionCode]="promotionCode" (buyButtonClicked)="onTapPurchase($event)" class="package" *ngFor="let package of this.practicalPackages"
         [package]="package">
        </app-package>
      </div>
  
    </ng-container>
  </div>


</div>