import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import SplitType from 'split-type';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { PlatformService } from 'src/app/services/common/platform-service';
gsap.registerPlugin(ScrollTrigger);


@Component({
  selector: 'app-dont-teach-but',
  templateUrl: './dont-teach-but.component.html',
  styleUrls: ['./dont-teach-but.component.scss']
})
export class DontTeachButComponent implements OnInit {
  @ViewChild("headlineText", { static: true, read: ElementRef }) headlineText: ElementRef;

  textLineItems = [
    "Felhívjuk a figyelmed az ÖSSZES bukást okozó tényezőre.",
    "Városonként legalább 700 kritikus pontra hívjuk fel a figyelmed.",
    "Ha eleget gyakorolsz az appban, akkor a vizsgán már nem érhet meglepetés.",
    "Minden 1 kattintásra van! Nem kell hosszasan keresgélned."
  ];


  @Output()
  purchaseClicked = new EventEmitter();

  constructor(
    private platformService: PlatformService,
    private ngZone: NgZone) { }

  ngOnInit(

  ): void {
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      if (this.platformService.getIsPlatformBrowser()) {
        this.addScrollTriggerTextAnimation(this.headlineText.nativeElement);
      }
    });
  }



  addScrollTriggerTextAnimation(textHtmlElement: HTMLElement) {
    gsap.registerPlugin(ScrollTrigger);

    const split = new SplitType(textHtmlElement, { types: ['words'], wordClass: "valami-word-class" });


    const wordMasks: HTMLElement[] = [];

    for (const wordNode of split.words) {
      const wrapper = wordNode;
      const word = wrapper.textContent;
      wrapper.textContent = "";

      // MASK
      const mask = document.createElement("span");
      wordMasks.push(mask);
      mask.classList.add("mask");
      mask.textContent = word;

      mask.style.fontSize = "30px";
      mask.style.lineHeight = "38px";
      mask.style.fontWeight = "700";
      mask.style.color = "white";


      mask.style.position = "absolute";
      mask.style.clipPath = "circle(0% at 0% 50%)";

      wrapper.append(mask);

      // EREDETI
      const wordSpan = document.createElement("span");
      wordSpan.textContent = word;

      wordSpan.style.fontSize = "30px";
      wordSpan.style.lineHeight = "38px";
      wordSpan.style.fontWeight = "700";
      wordSpan.style.color = "black";

      wordSpan.style.color = "#8B9296";

      wrapper.append(wordSpan);
    }

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: textHtmlElement,
        start: "top 85%",
        end: "top 30%",
        scrub: 1.2,
        markers: false
      }
    });

    for (const mask of wordMasks) {
      timeline.to(mask, {
        duration: mask.textContent.length,
        clipPath: `circle(160% at -15% -15%)`,
      });
    }
  }

}
