<div class = "main-content">
    <div class = "section-title-big">
        Ne parázz
        <div>
            a KRESZ
            <div style = "display: inline-block; white-space: nowrap;">
                vizsgán! <img alt="" src = "assets/landing-page/emojis/relieved-face.webp" draggable = "false" class = "title-emoji">
            </div>
        </div>
    </div>
    <div class = "subtitle">
        180.000 tanuló választotta eddig KRESZ teszt appunkat. Velünk szórakozás lesz a jogsira készülés!
    </div>
    <div class = "video-placeholder-image-container">
        <app-openable-youtube-video-preview
            [imageSrc] = "'assets/landing-page/landing-theory-video-preview.webp'"
            [videoUrl] = "youtubeVideoUrl"
        ></app-openable-youtube-video-preview>
    </div>
    <div class = "details-button-wrapper">
        <app-details-button
            (click) = "navigateTo('/kresz-teszt-app')"
        >
            <span class = "details-button-text">
                Tudj meg többet
            </span>
        </app-details-button>
    </div>
</div>
<div class = "mockup-container">
    <img alt="Elméleti felkészítőhöz mockup" src = "assets/landing-page/landing-theory-mockup.webp" class = "mockup" draggable = "false" />
</div>
