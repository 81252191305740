import { Renderer2 } from "@angular/core";

/**
 * Renders the star value of a rating inside a given container with the given renderer service.
 * 
 * @param renderer the component's renderer
 * @param container the container to render into
 * @param rating the rating's value
 */
export function getStarImages(rating:number) : string[]{
  let result:string[] = [];
  for(let i = 0; i < 5; ++i) {
    const starValue:number = Math.min(rating, 1.0);
    result.push(getStarImage(starValue));
    rating -= starValue;
  }
  return result;
}

/**
 * Helper function for the `insertStars()` method. Inserts one start with the given value
 * into the given container with the given renderer service.
 * 
 * @param renderer the component's renderer
 * @param container the container to render into
 * @param value the actual rendered value
 */
function getStarImage(value:number) : string{
  value = parseFloat(value.toFixed(1));
  return `assets/landing-page/stars/star${Math.floor(value * 10)}.webp`;
}