import { AfterViewInit, Component, ContentChildren, ElementRef, Input, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { SlideshowItemComponent } from './slideshow-content/slideshow-item.component';

export type SlideshowSteppingAninmationStep = (previousHtmlElement:HTMLElement, nextHtmlElement:HTMLElement, progress:number) => void;

/**
 * Type that holds the animations data
 */
export type SlideshowSteppingAninmation = {
  /** The length of the animation in ms. */
  duration:number;
  /** Step function that describes how the elements should look at a given animation progress value */
  animationStep:SlideshowSteppingAninmationStep;
}

/**
 * Type that contains the forward and backward stepping animations
 */
export type SlideshowSteppingAninmations = {
  forwardStepAnimation:SlideshowSteppingAninmation;
  backwardStepAnimation:SlideshowSteppingAninmation;
}

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements AfterViewInit, OnDestroy {
  @Input() animations:SlideshowSteppingAninmations = createDefaultSlideshowSteppingAnimation(300, 300);
  @Input() showForwardStepper:boolean = true;
  @Input() showBackwardStepper:boolean = true;
  @Input() showPositionDots:boolean = false;
  @Input() contentClass:string = "";
  @Input() stepperClass:string = "";

  @ContentChildren(SlideshowItemComponent, { read: ElementRef }) slideshowContentElements:QueryList<ElementRef<HTMLElement>>;
  slideshowContentNativeElements:Array<HTMLElement>;

  @ViewChildren("stepperContainer") stepperContainerElements:QueryList<ElementRef<HTMLElement>>;
  @ViewChild("contentContainer", { static: true }) contentContainerElement:ElementRef<HTMLElement>;
  
  actualItemIndex:number = 0;
  isAnimationRunning:boolean = false;
  actualAnimationId:number|null = null;

  constructor() { }

  public ngAfterContentInit(){
    // Get the native elements of the SideshowContent content elements
    this.slideshowContentNativeElements = this.slideshowContentElements.toArray().map(
      (elementRef:ElementRef<HTMLElement>) => { return elementRef.nativeElement }
    );
  }

  public ngAfterViewInit():void {
    // Hide all elements by default exept the actual item
    for(let index:number = 0; index < this.slideshowContentNativeElements.length; ++index) {
      if(index !== this.actualItemIndex) {
        this.slideshowContentNativeElements[index].style.display = 'none';
      }
    }

    // Apply the content class if provided
    if(this.contentClass) {
      this.contentContainerElement.nativeElement.classList.add(this.contentClass);
    }

    // Apply the stepper class for each stepper elements
    if(this.stepperClass) {
      this.stepperContainerElements.forEach(
        (element:ElementRef<HTMLElement>) => {
          element.nativeElement.classList.add(this.stepperClass);
        }
      );
    }
  }

  public ngOnDestroy():void {
    // Remove the scheduled animation frame if there is any
    if(this.actualAnimationId !== null) {
      cancelAnimationFrame(this.actualAnimationId);
    }
  }

  public stepForward:() => void = () => {
    // Check that an animation is already running
    // If so, do nothing
    if(this.isAnimationRunning) {
      return;
    }

    // Determine the index relations
    const previousIndex:number = this.actualItemIndex;
    const nextIndex:number = (this.actualItemIndex + 1) % this.slideshowContentNativeElements.length;
    // Run the animation between the specified contents
    this.runAnimation(previousIndex, nextIndex, this.animations.forwardStepAnimation, this.onAnimationDone);
    // Update the actual index
    this.actualItemIndex = nextIndex;
  }

  public stepBackward:() => void = () => {
    // Check that an animation is already running
    // If so, do nothing
    if(this.isAnimationRunning) {
      return;
    }

    // Determine the index relations
    const previousIndex:number = this.actualItemIndex;
    const nextIndex:number = (this.actualItemIndex + this.slideshowContentNativeElements.length - 1) % this.slideshowContentNativeElements.length;
    // Run the animation between the specified contents
    this.runAnimation(previousIndex, nextIndex, this.animations.backwardStepAnimation, this.onAnimationDone);
    // Update the actual index
    this.actualItemIndex = nextIndex;
  }

  /**
   * Runs an animation between the specified content elements.
   * 
   * @param previousIndex the index of the leaving element
   * @param nextIndex the index of the entering element
   * @param animation the animation's properties
   * @param onAnimationDone callback to invoke when the animation finished it's running
  */
  private runAnimation(
    previousIndex:number,
    nextIndex:number,
    animation:SlideshowSteppingAninmation,
    onAnimationDone:(previousIndex:number, nextIndex:number) => void
  ):void {
    this.isAnimationRunning = true;
    // Set the intital values to animate the elements
    this.slideshowContentNativeElements[previousIndex].style.display = "block";
    this.slideshowContentNativeElements[nextIndex].style.display = "block";

    // Determine the animation start and end times
    const startTime:number = Date.now();
    const endTime:number = startTime + animation.duration;
    let animationDone:boolean = false;

    // Describe a recursive animation step
    let animationStep = () => {
      // Check the actual time and determine the progress
      const time:number = Date.now();
      const progress:number = (time - startTime) / (endTime - startTime);

      // If the animation is not done do an animation step
      if(!animationDone) {
        // Check if the animation is in a finished state (there will be always a step with progress 1.0)
        if(progress >= 1.0) {
          animationDone = true;
        }

        // Animate the elements with the provided animation function
        animation.animationStep(this.slideshowContentNativeElements[previousIndex], this.slideshowContentNativeElements[nextIndex], Math.min(progress, 1.0));
        // Call the next animation step
        this.actualAnimationId = requestAnimationFrame(animationStep);
      } else {
        // When the animation finished, call the respective callback
        onAnimationDone(previousIndex, nextIndex);
      }
    }

    // Start the animation
    this.actualAnimationId = requestAnimationFrame(animationStep);
  }

  /**
   * After the animation finished, this function is called to finalize the content items
  */
  private onAnimationDone = (previousIndex:number, nextIndex:number) => {
    this.slideshowContentNativeElements[previousIndex].style.display = 'none';
    this.slideshowContentNativeElements[nextIndex].style.display = 'block';
    this.isAnimationRunning = false;
  }

}

/**
 * Creates a default fade-in and out animation for the Slideshow component with the given durations.
 * 
 * @param forwardStepAnimationDuration duration of the forward step anination
 * @param backwardStepAnimationDuration duration of the backward step animation
 * @returns the stepping animations object
 */
export function createDefaultSlideshowSteppingAnimation(forwardStepAnimationDuration:number, backwardStepAnimationDuration:number):SlideshowSteppingAninmations {
  const animationStep:SlideshowSteppingAninmationStep = (previousHtmlElement:HTMLElement, nextHtmlElement:HTMLElement, progress:number) => {
    if(progress < 0.5) {
      previousHtmlElement.style.opacity = (1 - (progress * 2)).toString();
      nextHtmlElement.style.opacity = "0";
    } else {
      previousHtmlElement.style.opacity = "0";
      nextHtmlElement.style.opacity = ((progress - 0.5) * 2).toString();
    }
  };
  
  return {
    forwardStepAnimation: {
      duration: forwardStepAnimationDuration,
      animationStep: animationStep
    },
    backwardStepAnimation: {
      duration: backwardStepAnimationDuration,
      animationStep: animationStep
    },
  }
}