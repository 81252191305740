// Service which check isPlatformBrowser and isPlatformServer

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private isPlatformBrowser: boolean;
  private isPlatformServer: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isPlatformBrowser = isPlatformBrowser(platformId);
    this.isPlatformServer = isPlatformServer(platformId);
  }

  public getIsPlatformBrowser(): boolean {
    return this.isPlatformBrowser;
  }

  public getIsPlatformServer(): boolean {
    return this.isPlatformServer;
  }
}

