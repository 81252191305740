import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-page-welcome',
  templateUrl: './common-page-welcome.component.html',
  styleUrls: ['./common-page-welcome.component.scss']
})
export class CommonPageWelcomeComponent implements OnInit {
  @Input() sideMediaAlignment:"left"|"right" = "left";

  constructor() { }

  ngOnInit(): void {
  }

}
