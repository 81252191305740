import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

@Injectable()
export class CssVariableExtractor {
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}

  /**
   * Gets all CSS variables for a target HTML element.
   * 
   * @param element the target HTML element
   * @param pseudo the pseudo class of the element
   * 
   * @returns a record (mapping) for the resulting CSS variable names and values
   */
  public getAllVariablesFromHtmlElement(element: HTMLElement, pseudo?: string) {
    // Get all variable names of document
    const cssVariableNames: Array<string> = this.getAllCssVariableNamesOfDocument();
    // Get the element style declaration
    const elementStyles: CSSStyleDeclaration = window.getComputedStyle(element, pseudo);

    // Create an object to map the resulting values to keys
    const cssVariables: Record<string,string> = {};

    // Iterate over the variable names
    for(const cssVariableName of cssVariableNames) {
      // Get the value (with the removed leading and trailing spaces)
      const value: string = elementStyles.getPropertyValue(cssVariableName).trim();
      // If the variable has a value, save it
      if(value){
        cssVariables[cssVariableName] = value;
      }
    }

    // Return the resulting variables
    return cssVariables;
  }

  /**
   * Gets all CSS variable names of the enitre document.
   * 
   * @returns an array of the names of the CSS variables
   */
  public getAllCssVariableNamesOfDocument(): Array<string> {
    const cssVars: Array<string> = [];
    const styleSheets: StyleSheetList = this.document.styleSheets;
    if((styleSheets?.length ?? 0) == 0) return cssVars;

    // Loop each stylesheet
    for(let styleSheetIndex: number = 0; styleSheetIndex < styleSheets.length; styleSheetIndex++){
      // loop stylesheet's cssRules
      try{ // try/catch used because 'hasOwnProperty' doesn't work
          for(let cssRuleIndex: number = 0; cssRuleIndex < styleSheets[styleSheetIndex].cssRules.length; cssRuleIndex++){
            try{
                // loop stylesheet's cssRules' style (property names)
                for(let styleIndex: number = 0; styleIndex < (styleSheets[styleSheetIndex].cssRules[cssRuleIndex] as any).style.length; styleIndex++){
                  const name: string = (styleSheets[styleSheetIndex].cssRules[cssRuleIndex] as any).style[styleIndex];
                  // test name for css variable signiture and uniqueness
                  if(name.startsWith('--') && cssVars.indexOf(name) == -1){
                      cssVars.push(name);
                  }
                }
            } catch (error) {}
          }
      } catch (error) {}
    }

    return cssVars;
  }
}