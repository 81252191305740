import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PracticeCity } from 'src/app/modules/pages/practice-page/models/practice-city';
import { PracticeCityService } from 'src/app/modules/pages/practice-page/services/practice-city.service';

@Component({
  selector: 'app-available-cities-dialog',
  templateUrl: './available-cities-dialog.component.html',
  styleUrls: ['./available-cities-dialog.component.scss']
})
export class AvailableCitiesDialogComponent implements OnInit {

  isContentLoading:boolean = false;
  availableCities:Array<PracticeCity> = [];
  pendingCities:Array<PracticeCity> = [];

  constructor(
    private practiceCityService:PracticeCityService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  public ngOnInit():void {
    this.loadData();
  }

  public static open(matDialogService: MatDialog, viewContainerRef: ViewContainerRef){
    matDialogService.open(AvailableCitiesDialogComponent, {
      disableClose: false,
      scrollStrategy: new NoopScrollStrategy(),
      hasBackdrop: true,
      backdropClass: 'invisible-backdrop',
      // Ahhoz, hogy a dialógus logikailag a PracticeFunctionalityComponent szintjén
      // legyen a component tree-ben
      // így minden provider-t használhat, amit a PracticeFunctionality is
      viewContainerRef: viewContainerRef
    });
  }

  public async reloadData():Promise<void> {
    await this.loadData();
  }

  public async loadData():Promise<void> {
    this.isContentLoading = true;
    try {
      const cities = await this.practiceCityService.fetchPracticeCities();
      this.availableCities = cities.filter(city => city.isActive);
      this.pendingCities = cities.filter(city => !city.isActive);
    } catch(error:any) {}
    this.isContentLoading = false;

    this.changeDetectorRef.detectChanges();
  }

}
