import { Injectable } from "@angular/core";
import { PromotionCode } from "../models/promotion-code";
import { PurchasablePackage } from "../models/purchasable-package";

@Injectable({
  providedIn:'root'
})
export class PromotionCodeUtilityService {

  public getGrossPriceCalculatedWithDiscount(
    purchasablePackage: PurchasablePackage,
    promotionCode: PromotionCode
  ):number {
    if(promotionCode.discountPercentage == 0 || promotionCode.discountPercentage == null) return purchasablePackage.grossPrice;
    return Math.round(purchasablePackage.grossPrice * (1 - promotionCode.discountPercentage / 100));
  }

  public isDiscountExpired(promotionCode: PromotionCode):boolean {
    if(promotionCode.expireTimestampInMs == null) return false;
    return Date.now() > promotionCode.expireTimestampInMs;
  }

}