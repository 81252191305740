<!-- Poster esetén amikor a poster->video váltás történrt villant egyet a kép (1-2 framere nem volt se poster, se video)
       ezért használunk img-t
  -->


<div class="desktop-version">

  <div class="main-content">
    <div class="title">
      <div class="primary-gradient-colored-text line">
        Segítünk
      </div>
      <div class="primary-gradient-colored-text line">
        a jogsiban!
      </div>
    </div>
    <div class="subtitle">
      Legújabb, legnehezebb KRESZ kérdések, és vizsgaútvonal videók magyarázattal🔥
    </div>
    <div class="recommendation">
      <div class="recommendation-image-container">
        <img alt="" width="50" height="50" src="assets/landing-page/kreszprof.webp" class="recommendation-image"
          draggable="false" />
      </div>
      <div class="recommendation-text">
        A Kreszprofesszor ajánlásával
      </div>
    </div>
  </div>
  <div (click)="this.playVideo()" class="mockups">
    <!-- 
    oncanplay:
    https://stackoverflow.com/questions/47638344/muted-autoplay-in-chrome-still-not-working
  -->
    <video #videoDesktop [controls]="false" preload="auto" style="cursor:pointer;width:100%;border-radius:18px;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;" class="video" playsinline>
      <source src="assets/christmas/final5-5.mp4" type="video/mp4">
    </video>

    <app-lottie-animation [style.opacity]="this.videoDesktop.paused ? '100%' : '0%'" class="play-button-on-video"
      animationPath="assets/practical-sales-page/pulse.json" [loop]="true">
    </app-lottie-animation>
  </div>

</div>

<div (click)="this.playVideo()" class="mobile-version" style="position:relative;">
  <img fetchpriority="high" alt="Vizsgaútvonal videó"
    style="position:absolute; width:100%;height:100%;z-index:-0;object-fit:cover;"
    src="assets/christmas/first-img.webp">

  <!-- 
    oncanplay:
    https://stackoverflow.com/questions/47638344/muted-autoplay-in-chrome-still-not-working
  -->
  <video #videoMobile [controls]="false" preload="auto" class="video" playsinline>
    <source src="assets/christmas/final5-5.mp4" type="video/mp4">
  </video>

  <app-lottie-animation [style.opacity]="this.videoMobile.paused ? '100%' : '0%'" class="play-button-on-video"
    animationPath="assets/practical-sales-page/pulse.json" [loop]="true">
  </app-lottie-animation>
</div>