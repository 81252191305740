<div class="main-wrapper">

  <div class="grid">
    <div class="img-wrapper">
      <img loading="lazy" alt="Elérhető városok" class="img on-mobile" src="assets/practical-sales-page/images/city.webp">

      <img loading="lazy" alt="Elérhető városok" class="img on-laptop" src="assets/practical-sales-page/images/city-laptop.webp">
    </div>
  
    <div class="bottom-section">
      <div class="bottom-section-bg">
      </div>
  
  
      <div class="bottom-section-content">
        <span class="headline-text">
          ELÉRHETŐ VÁROSAINK
        </span>
  
        <div class="cities-wrapper">
          <div class="city-line" *ngFor="let city of this.getCitiesName().all">
            <!-- Little white dot -->
            <div class="dot">
            </div>
  
            <h2 class="city-text">
              <b>{{city}}</b> vizsgaútvonalak videón
            </h2>
          </div>

        </div>
    
      </div>
    </div>
  </div>



</div>