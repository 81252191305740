import { ChristmasPageComponent } from './modules/pages/christmas/christmas-page.component';
import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { PlaygroundComponent } from './components/playground/playground.component';
import { LandingPageComponent } from './modules/pages/landing-page/landing-page.component';
import { PracticalSalesPageComponent } from './modules/pages/practical-sales-page/practical-sales-page.component';

// azért, hogy az url-ek /-el végződhessenek így kell megadni a path-eket
// https://stackoverflow.com/questions/48425111/angular-5-allow-trailing-slash-in-routes

const routes: Routes = [
  {
    path: '',
    data: {
      seo: {
        title: "KRESZ teszt és forgalmi vizsga felkészítő app - Mr. Kresz",
        description: "Forgalmi és KRESZ vizsga segítő alkalmazás! Velünk garantáltan sikerülni fog mindkettő vizsgád! Készülj az ország legkedveltebb KRESZ alkalmazásából.",
        keywords: ["kresz teszt", "forgalmi", "vizsgaútvonal tippek", "vizsgaútvonal videók", "viszgaútvonal segítség", "vizsga videók", "forgalmi vizsgaútvonal videók", "kresz app", "kresz kérdések", "kresz kérdések magyarázat", "nehéz kresz kérdések"]
      }
    },
    component: LandingPageComponent
  },
  {
    path: 'playground',
    component: PlaygroundComponent
  },
  {
    path: 'karacsony',
    data: {
      seo: {
        title: "Karácsony 2023! - Mr. Kresz",
        description: "Lepd meg szeretteidet tudással!",
        keywords: ["vizsgaútvonalak", "forgalmi vizsga tippek", "budapest vizsgaútvonalak", "vizsgaútvonalak videón", "forgalmi útvonalak videón"]
      }
    },
    component: ChristmasPageComponent
  },
  {
    path: 'vizsgautvonalak-videon',
    data: {
      seo: {
        title: "Vizsgaútvonalak videón ott ahol vizsgázol! - Mr. Kresz",
        description: "Járd be az összes vizsgaútvonalat a városodban egy virtuális oktatóval aki az ÖSSZES buktatóra felhívja a figyelmed.",
        keywords: ["vizsgaútvonalak", "forgalmi vizsga tippek", "budapest vizsgaútvonalak", "vizsgaútvonalak videón", "forgalmi útvonalak videón"]
      }
    },

    component: PracticalSalesPageComponent
  },
  {
    path: 'kresz-teszt-app',
    data: {
      seo: {
        title: "KRESZ teszt app ÚJ kérdésekkel, magyarázattal - Mr. Kresz",
        description: "Gyakorolj a legújabb vizsgakérdésekből, a legkedveltebb KRESZ teszt alkalmazásból. A vizsgádra GARANCIÁT vállalunk!",
        keywords: ["kresz teszt", "kresz teszt app", "kresz vizsga app", "új KRESZ kérdések", "legújabb KRESZ kérdések", "kresz vizsga", "kresz nehéz kérdések", "kresz magyarázat"]
      }
    },
    loadChildren: () =>
      import('./modules/pages/theory-page/theory-page.module').then(
        (m) => m.TheoryPageModule
      )
  },
  {
    path: 'partnereknek',
    data: {
      seo: {
        title: "Legyél hivatalos Mr. Kresz viszonteladó!",
        description: "Jelentkezz ingyenesen és legyen saját viszonteladói oldalat amiből profitálhatsz! Segíts te is a tanulódnak!",
        keywords: ["mr. kresz viszonteladó", "mr. kresz partner", "mr. kresz partner program"]
      }
    },
    loadChildren: () =>
      import('./modules/pages/partners-page/partners-page.module').then(
        (m) => m.PartnersPageModule
      ),
  },
  {
    path: 'partnereknek/:invitatorVendorId',
    data: {
      seo: {
        title: "Legyél hivatalos Mr. Kresz viszonteladó!",
        description: "Jelentkezz ingyenesen és legyen saját viszonteladói oldalat amiből profitálhatsz! Segíts te is a tanulódnak!",
        keywords: ["mr. kresz viszonteladó", "mr. kresz partner", "mr. kresz partner program"]
      },
      redirectUrlIfVendorNotFound: "partnereknek"
    },
    loadChildren: () =>
      import('./modules/pages/partners-page/partners-page.module').then(
        (m) => m.PartnersPageModule
      ),
  },
  {
    path: 'classroom',
    data: {
      seo: {
        title: "Oktatóknak digitális támogatás tantermi oktatáshoz - Mr. Kresz",
        description: "Tedd interaktívabbá a tantermi oktatást, garantáljuk, hogy ettől felpörögnek a tanulók!",
        keywords: ["kresz tanfolyam", "elmélet tanterem", "tantermi oktatás", "elméleti oktatás", "KRESZ oktatás", "interaktív KRESZ teszt"]
      }
    },
    loadChildren: () =>
      import('./modules/pages/classroom-page/classroom-page.module').then(
        (m) => m.ClassroomPageModule
      ),
  },
  {
    path: 'bevaltas',
    data: {
      seo: {
        title: "Hozzáférés aktiválása, csomag beváltása - Mr. Kresz",
        description: "Váltsd be a vásárolt csomagodat és kezdd el használni az alkalmazást!",
        keywords: ["mrkresz csomag beváltás", "mrkresz aktiválás", "mr. kresz csomagok", "mr. kresz hozzáférés"]
      }
    },
    loadChildren: () =>
      import('./modules/pages/redeem-package-page/redeem-package-page.module').then(
        (m) => m.RedeemPackagePageModule
      ),
  },
  {
    path: 'vasarlas',
    data: {
      seo: {
        title: "KRESZ teszt app és vizsgaútvonal videó vásárlás - Mr. Kresz",
        description: "Vásárolj KRESZ teszt appot és vizsgaútvonal videókat, velünk készülve garantáltan nem érhet meglepetés!",
        keywords: ["vizsgaútvonal videó vásárlás", "kresz teszt app vásárlás", "kresz teszt app", "kresz gyakorlás vásárlás", "kresz kérdések vásárlás"]
      }
    },
    loadChildren: () =>
      import('./modules/pages/pricing-page/pricing-page.module').then(
        (m) => m.PricingPageModule
      ),
  },
  {
    path: 'gyik',
    data: {
      seo: {
        title: "Gyakori kérdések az alkalmazással kapcsolatban - Mr. Kresz",
        description: "Olvasd át a GYIK-et ha kérdésed merült fel, vagy keress minket telefonon!"
      }
    },
    loadChildren: () =>
      import('./modules/pages/faq-page/faq-page.module').then(
        (m) => m.FaqPageModule
      ),
  },
  {
    path: 'webview_app_intro',
    loadChildren: () =>
      import('./modules/webview-app-intro/webview-app-intro.module').then(
        (m) => m.WebviewAppIntroModule
      ),
  },
  {
    path: 'callbacks',
    loadChildren: () =>
      import('./modules/callbacks-handler/callbacks-handler.module').then(
        (m) => m.CallbacksHandlerModule
      ),
  },
  {
    path: 'adatvedelem',
    loadChildren: () =>
      import('./modules/pages/privacy-page/privacy-page-module.module').then(
        (m) => m.PrivacyPageModuleModule
      )
  },
  {
    path: 'feltetelek',
    component: LandingPageComponent,
    resolve: { url: 'externalUrlRedirectResolver' },
    data: { externalUrl: '/assets/pdf/conditions.pdf' },
  },
  {
    path: 'impresszum',
    component: LandingPageComponent,
    resolve: { url: 'externalUrlRedirectResolver' },
    data: { externalUrl: '/assets/pdf/impressum.pdf' },
  },
  {
    path: ':vendor-defined-selling-page-id',
    data: {
      seo: {
        title: "Hivatalos Mr. Kresz viszonteladó",
        description: "Kedvezményes árak hivatalos viszonteladón keresztül",
        keywords: ["mr. kresz viszonteladó", "mr. kresz partner", "mr. kresz partner program", "kedvezőbb árak kresz teszt", "forgalmi vizsgaútvonal árak"]
      }
    },
    loadChildren: () =>
      import('./modules/pages/vendor-selling-page/vendor-selling-page.module').then(
        (m) => m.VendorSellingPageModule
      ),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.replace((route.data as any).externalUrl);
      }
    },
  ],
})
export class AppRoutingModule { }