import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/modules/shared/components/error-dialog/error-dialog.component';
import { BackendServiceException } from '../backend.service';
import { ErrorHandler } from './error-handler';

@Injectable({
  providedIn: 'root'
})
export class ServerNotAvailableErrorHandler implements ErrorHandler {

  constructor(
    private dialogService:MatDialog
  ) { }

  public processResponse(response:any):void {
    // Only process the SERVER_NOT_AVAILABLE errors
    if(response !== BackendServiceException.SERVER_NOT_AVAILABLE) {
      return;
    }

    this.dialogService.open(
      ErrorDialogComponent,
      {
        data: {
          displayedText: "A szerver nem elérhető. Próbálkozz később!"
        },
        scrollStrategy: new NoopScrollStrategy(),
        hasBackdrop: true,
        backdropClass: 'invisible-backdrop'
      }
    );
  }
}
