<!-- Profile image -->
<div
    *ngIf = "review.profileImageUrl"
    class = "profile-image-container"
>
    <div
        class = "profile-image"
        [ngStyle] = "{ 'background-image': 'url(' + review.profileImageUrl + ')' }"
    >
        <!-- Profile image goes here -->
    </div>
</div>

<!-- Name -->
<div class = "name">
    {{ review.reviewerName }}
</div>

<!-- Star rating -->
<div
    #starRatingContainer
    *ngIf = "review.score"
    class = "star-rating"
>
    <!-- Star rating goes here-->
    <img *ngFor="let imgSource of getStarImages(review?.score)" [src]="imgSource" alt="" draggable="false">
</div>

<!-- Review content -->
<div class = "content">
    {{ review.content }}
</div>