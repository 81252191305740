import { Injectable } from "@angular/core";
import { awaitFor } from "src/app/modules/shared/functions/misc-functions";
import { TiktokVideo } from "../models/tiktok-video.model";
import { BackendService } from "./common/backend.service";

@Injectable({
  providedIn: "root"
})
export class TiktokVideoService {

  constructor(
    private backendService: BackendService
  ) {}

  /**
   * Gets the tiktok videos from the backend in order to display them.
   * 
   * @returns the list of the displayed tiktok videos
   */
  public async getTiktokVideos(): Promise<Array<TiktokVideo>> {
    const response: { tiktokVideos: Array<TiktokVideo>} = await this.backendService.callApi(
      "/api/public/website_tiktok_videos",
      "GET"
    );
    return response.tiktokVideos;
  }
}