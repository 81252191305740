<div class="main-wrapper">
  <span class="headline">
    VAN EGY AJÁNDÉKUNK SZÁMODRA
  </span>

  <span class="text">
    Felkeltettük az érdeklődésed? Akkor itt egy <b>meglepetés kupon</b>, amelyet beválthatsz a vásárlásodhoz. <b>Kapard
      le gyorsan!</b> 🤩
  </span>

  <div #scratchCardWrapper class="scratch-card-wrapper">
    <div class="below-coupon">
      <div>
        <span class="promo-code">
          <span #realCouponArea class="real-coupon-area">

            <b>{{promotionCode}}</b>
          </span>

          <span class="promo-code-description">
            15%-os kedvezmény
          </span>
        </span>
      </div>
    </div>
    <!-- touch-action: none azért kell, hogy a default touch handler  ne fusson le az elemen -->
    <canvas style="width:270px;height:270px;display:block;touch-action: none;" #canvasCoupon></canvas>

    <img alt="" width="270px" height="270px" (loaded)="couponImageLoaded()" #frontCouponImg class="front-coupon"
      src="assets/practical-sales-page/images/coupon.svg">
  </div>

  <button (click)="onTapRedeem();" #redeemButton class="redeem-button">
    BEVÁLTÁS
  </button>



</div>