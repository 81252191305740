import { Injectable, forwardRef } from "@angular/core";
import { BackendService } from "src/app/services/common/backend.service";
import { PromotionCode } from "../models/promotion-code";
import { MockedImplementation } from "src/app/decorators/mock-class-decorator";

// Promóciós kóddal kapcsolatos API requesteket végez

@Injectable({
  providedIn:'root',
  useClass: forwardRef(() => PromotionCodeApiServiceImpl)
})
export abstract class PromotionCodeApiService{
  abstract fetchPromotionCode(customerFacingCode: string): Promise<PromotionCode>;
  abstract isPromotionCodeExpired(promotionCode: PromotionCode): boolean;
}

@Injectable({
  providedIn:'root'
})
export class PromotionCodeApiServiceImpl extends PromotionCodeApiService {
  constructor(private backendService: BackendService) {
    super();
  }

  private getApiUrl(customerFacingCode: string) {
    return `/api/public/promotion_codes/${customerFacingCode}`;
  }

  // Ha nem található akkor kivételt dob az endpoint
  public async fetchPromotionCode(customerFacingCode: string): Promise<PromotionCode> {
    const result : {promotionCode: PromotionCode}= await this.backendService.callApi(this.getApiUrl(customerFacingCode), "GET", { httpRequestBody: {} });
    return result.promotionCode;
  }

  public isPromotionCodeExpired(promotionCode: PromotionCode): boolean {
    if(promotionCode.expireTimestampInMs == null)return false;
    return promotionCode.expireTimestampInMs < Date.now();
  }
}



@MockedImplementation()
export class MockedPromotionCodeApiService extends PromotionCodeApiService{
  async fetchPromotionCode(customerFacingCode: string): Promise<PromotionCode> {
    return {
      customerFacingPromotionCode: customerFacingCode,
      discountPercentage:30,
      expireTimestampInMs: Date.now() + 10000, // Jelenlegi idő + 4 óra
    }
  }
  isPromotionCodeExpired(promotionCode: PromotionCode): boolean {
    return promotionCode.expireTimestampInMs < Date.now();
  }
}
