<!-- Backwards stepper -->
<div
    #stepperContainer
    class = "stepper-container"
>
    <div
        class = "stepper-action-area"
        *ngIf = "showBackwardStepper"
        (click) = "stepBackward()"
    >
        <span class = "material-icons stepper">
            arrow_back_ios_new
        </span>
    </div>
    
</div>


<!-- Content -->
<div
    #contentContainer
    class = "content-container"
>
    <ng-content></ng-content>
    <div
        *ngIf = "showPositionDots"
        class = "position-dots-container"
    >
        <div
            *ngFor = "let item of slideshowContentNativeElements; let i = index"
            class = "slideshow-dot"
        >
            <div
                class = "slideshow-dot-active-color"
                [class.active] = "actualItemIndex === i"
            >
            </div>
        </div>
    </div>
</div>

<!-- Forward stepper -->
<div
    #stepperContainer
    class = "stepper-container"
>
    <div
        class = "stepper-action-area"
        *ngIf = "showForwardStepper"
        (click) = "stepForward()"
    >
        <span class = "material-icons stepper">
            arrow_forward_ios
        </span>
    </div>
</div>