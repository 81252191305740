import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PracticalSalesPageComponent } from './practical-sales-page.component';
import { PracticalSalesPageRoutingModule } from './practical-sales-page-routing.module';
import { IntroSectionComponent } from './components/intro-section/intro-section.component';
import { ExamGuaranteeSectionComponent } from './components/exam-guarantee-section/exam-guarantee-section.component';
import { ShowcaseWithPhoneSectionComponent } from './components/showcase-with-phone-section/showcase-with-phone-section.component';
import { LoaderScreenComponent } from './components/loader-screen/loader-screen.component';
import { InstructorReviewsComponent } from './components/instructor-reviews/instructor-reviews.component';
import { DontTeachButComponent } from './components/dont-teach-but/dont-teach-but.component';
import { InstructorReviewHorizontalListComponent } from './components/instructor-reviews/instructor-review-horizontal-list/instructor-review-horizontal-list.component';
import { InstructorReviewItemComponent } from './components/instructor-reviews/instructor-review-item/instructor-review-item.component';
import { PurchaseSectionComponent } from './components/purchase-section/purchase-section.component';
import { AreYouScaredComponent } from './components/are-you-scared/are-you-scared.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 
import {MatIconModule} from '@angular/material/icon';
import { AvailableCitiesComponent } from './components/available-cities/available-cities.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { TiktokComponent } from './components/tiktok/tiktok.component';
import { CouponSectionComponent } from './components/coupon-section/coupon-section.component';
import { LoadedDirective } from './components/img-load.directive';
import { CurvyClippedStaticGradientComponent } from './components/curvy-clipped-static-gradient/curvy-clipped-static-gradient.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomMatDialogService } from 'src/app/services/common/custom-mat-dialog-service';


@NgModule({
  exports:[
    AvailableCitiesComponent,
    InstructorReviewsComponent,
    TiktokComponent,
    PurchaseSectionComponent
  ],  
  declarations: [
    PracticalSalesPageComponent,
    IntroSectionComponent,
    ExamGuaranteeSectionComponent,
    ShowcaseWithPhoneSectionComponent,
    LoadedDirective,
    LoaderScreenComponent,
    InstructorReviewsComponent,
    DontTeachButComponent,
    InstructorReviewHorizontalListComponent,
    InstructorReviewItemComponent,
    PurchaseSectionComponent,
    AreYouScaredComponent,
    AvailableCitiesComponent,
    TiktokComponent,
    CouponSectionComponent,
    CurvyClippedStaticGradientComponent
  ],
  imports: [
    CommonModule,
    
    PracticalSalesPageRoutingModule,
    SharedModule,

    MatProgressSpinnerModule,
    MatIconModule,
  ],
})
export class PracticalSalesPageModule { }
