import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { getStarImages } from '../../../functions/star-rating';
import { Review } from '../../../models/review';

@Component({
  selector: 'app-review-item',
  templateUrl: './review-item.component.html',
  styleUrls: ['./review-item.component.scss']
})
export class ReviewItemComponent implements AfterViewInit {
  @Input() review:Review;

  @ViewChild("starRatingContainer", { static: false }) starRatingContainerElement:ElementRef<HTMLDivElement>;

  constructor(
    private renderer:Renderer2
  ) { }

  public ngAfterViewInit():void {
  
  }

  getStarImages(rating: number){
    rating = Math.max(0,Math.min(5,rating));
    return getStarImages(rating);
  }

}
