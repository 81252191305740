<div class = "main-content">
    <div class = "title">
            <div class = "primary-gradient-colored-text line">
                Segítünk
            </div>
            <div class = "primary-gradient-colored-text line">
              a jogsiban!
            </div>
    </div>
    <div class = "subtitle">
        Legújabb, legnehezebb KRESZ kérdések, és vizsgaútvonal videók magyarázattal🔥
    </div>
    <app-store-downloads class = "store-downloads"></app-store-downloads>
    <div class = "recommendation">
        <div class = "recommendation-image-container">
            <img alt="" width = "50" height = "50" src = "assets/landing-page/kreszprof.webp" class = "recommendation-image" draggable = "false" />
        </div>
        <div class = "recommendation-text">
            A Kreszprofesszor ajánlásával
        </div>
    </div>
</div>
<div class = "mockups">
    <img alt="Mr. Kresz mockup" src = "assets/landing-page/landing-welcome-mockup-front.webp" class = "mockup front" draggable = "false" />
    <img alt="Mr. Kresz mockup" src = "assets/landing-page/landing-welcome-mockup-back.webp" class = "mockup back" draggable = "false" />
</div>