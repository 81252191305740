import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { TiktokVideo } from 'src/app/models/tiktok-video.model';
import { TiktokVideoService } from 'src/app/services/tiktok-video.service';

@Component({
  selector: 'app-tiktok',
  templateUrl: './tiktok.component.html',
  styleUrls: ['./tiktok.component.scss']
})
export class TiktokComponent implements OnInit {
  tiktokVideos: TiktokVideo[] = [];
  underLoad = false;

  constructor(
    private tiktokService: TiktokVideoService,
    private zone: NgZone,
    private elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    const intersectionObserver = new IntersectionObserver((entries) => {
      this.zone.run(async () => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting) {
            if (this.underLoad) return;
            this.underLoad = true;
            this.tiktokVideos = await this.tiktokService.getTiktokVideos();
            this.changeDetector.detectChanges();
          }
        });
      });
    },{
      // Azért, hogy ne akkor töltődjön be, amikor éppen beér a viewport-ba, hanem hamarabb
      rootMargin: '300px 300px 300px 300px',
    });

    intersectionObserver.observe(this.elementRef.nativeElement);
  }


}
