import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as assert from 'assert';
import { getStarImages } from '../../functions/star-rating';

@Component({
  selector: 'app-store-downloads',
  templateUrl: './store-downloads.component.html',
  styleUrls: ['./store-downloads.component.scss']
})
export class StoreDownloadsComponent implements OnInit {
  @ViewChild ("googlePlayStars") googlePlayStarsContainerElement:ElementRef<HTMLDivElement>;
  @ViewChild ("appStoreStars") appStoreStarsContainerElement:ElementRef<HTMLDivElement>;

  googlePlayRating:number = 4.8;
  appStoreRating:number = 4.7;

  constructor(
    private renderer:Renderer2
  ) { }

  ngOnInit(): void { }

  public ngAfterViewInit():void {
  }

  // value should between 0-5
  getStarImagesFromRating(rating: number) : string[]{
    return getStarImages(rating);
  }
}
