import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { InstructorReviewHorizontalListComponent } from './instructor-review-horizontal-list/instructor-review-horizontal-list.component';
import { InstructorReviewItemComponentInput } from './instructor-review-item/instructor-review-item.component';

// Register scroll trigger
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { PlatformService } from 'src/app/services/common/platform-service';
gsap.registerPlugin(ScrollTrigger);


@Component({
  selector: 'app-instructor-reviews',
  templateUrl: './instructor-reviews.component.html',
  styleUrls: ['./instructor-reviews.component.scss']
})
export class InstructorReviewsComponent implements OnInit {
  readonly reviews: InstructorReviewItemComponentInput[] = [

    {
      name: "Kamerrer János",
      profileImageUrl: "assets/practical-sales-page/instructor-reviews/kammerer_janos.webp",
      text: "Csak ezzel az alkalmazással oktatok. A stresszmentes vizsgához elengedhetetlen!"
    }
    ,
    {
      name: "Rimóczi Zsolt",
      profileImageUrl: "assets/practical-sales-page/instructor-reviews/rimoczi_zsolt.webp",
      text: "Oktatóként azt mondom, hogy a videók a tanulónak legalább 10 vezetési órát érnek."
    },
    {
      name: "Farkas Máté",
      profileImageUrl: "assets/practical-sales-page/instructor-reviews/farkas_mate.webp",
      text: "Végre egy innovatív segítség a tanulóknak. A városok buktatóit részletesen bemutató app."
    },
    {
      name: "Várkonyi Zsolt",
      profileImageUrl: "assets/practical-sales-page/instructor-reviews/varkonyi_zsolt.webp",
      text: "Újdonság nekem és a tanulóknak is és nagyon nagy segítség az oktatásban!"
    },

  ];

  readonly reviews2: InstructorReviewItemComponentInput[] = [

    {
      name: "Szilágyi Józsefné",
      profileImageUrl: "assets/practical-sales-page/instructor-reviews/szilagyi_jozsefne.webp",
      text: "35 évig dolgoztam vizsgabiztosként, az ott megszerzett tapasztalattal segítettem a csapatot!"
    },
    {
      name: "Varga Kitti",
      profileImageUrl: "assets/practical-sales-page/instructor-reviews/varga_kitti.webp",
      text: "A tanulóimnak a legnagyobb segítség, hogy az órák visszanézhetőek az appban részletes magyarázattal!"
    },
    {
      name: "Pozsgai Szilvia",
      profileImageUrl: "assets/practical-sales-page/instructor-reviews/pozsgai_szilvia.webp",
      text: "Minden tanulóm esetében a cél, a magabiztos, sikeres vizsga. Az app nagy segítségünkre van!"
    },
    {
      name: "Dabasi Zoltán",
      profileImageUrl: "assets/practical-sales-page/instructor-reviews/dabasi_zoltan.webp",
      text: "Azok a tanulóim akik szorgalmasan átnézik a videókat, sokkal hamarabb mennek át a vizsgán!"
    }

  ];

  // A scrollbar itt legyen a horizontális review listákban
  scrollProperties = {
    scrollPositionList1: 0.2,
    scrollPositionList2: 0.25
  };

  @ViewChild("reviewList1", { read: ElementRef, static: true }) reviewList1: ElementRef<HTMLDivElement>;
  @ViewChild("reviewList2", { read: ElementRef, static: true }) reviewList2: ElementRef<HTMLDivElement>;
  @ViewChild("listsWrapper", { read: ElementRef, static: true }) listsWrapper: ElementRef<HTMLDivElement>;



  constructor(
    private changeDetRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private elementRef: ElementRef,
    private platformService: PlatformService
  ) { }

  ngOnInit(): void {
    // add intersection observer
    if (this.platformService.getIsPlatformBrowser()) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.ngZone.run(() => {
              this.startAnimation();
            });
          }
        });
      }, { rootMargin: "0px 0px 30px 0px" });

      observer.observe(this.listsWrapper.nativeElement);
    }
  }

  startAnimation() {
    const reviewItemInList1 = this.reviewList1.nativeElement.querySelectorAll("app-instructor-review-item");
    const reviewItemInList2 = this.reviewList2.nativeElement.querySelectorAll("app-instructor-review-item");

    gsap.fromTo(reviewItemInList2, { x: 150 }, {
      x: 0,
      ease: "power3.out",

      duration: 1.8,
      onUpdate: () => {
      }
    });

    gsap.fromTo(reviewItemInList1, { x: -150 }, {
      x: 0,
      ease: "power3.out",
      duration: 1.2,
      onUpdate: () => {
      }
    });
  }

  ngAfterViewInit() {
    // Create new scroll trigger
  }

}
