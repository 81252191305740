<div class="main-container">
  <app-curvy-clipped-static-gradient class="curvy-clipped-gradient">
  </app-curvy-clipped-static-gradient>


  <div class="text-mockup-wrapper">
    <div class="text-and-button-wrapper">
      <h1 style="display:block;" class="headline headline-row1">
        JÁRD BE A VÁROSOD
      </h1>
      <h1 style="display:block;" class="headline headline-row2">
        VIZSGAÚTVONALAIT
      </h1>

      <h1 style="display:block;" class="headline headline-row3">
        OTTHONRÓL!
      </h1>


      <div style="margin-top:10px;">
        <p #descriptionText class="description">
        <b>Videón</b> mutatjuk be a <b>legnehezebb<br> szituációkat</b>, oktatói magyarázattal! <br>
        <!--   <span class="highlight">A vizsgá🚀</span> -->
        </p>
      </div>

      <button (click)="onTapShow();" #actionButton style="margin-top:30px;" class="white-action-button">
        MUTASD
      </button>
    </div>

    <div #mockupWrapper class="mobile-mockup-wrapper">
      <img fetchpriority="high" alt="Vizsgaútvonalak videón" class="mockup-img" #mockup
        src="assets/practical-sales-page/intro_mockup.webp">
    </div>
  </div>
</div>

<div (click)="playVideo()" class="video-below-gradient">

  <!-- Poster esetén amikor a poster->video váltás történrt villant egyet a kép (1-2 framere nem volt se poster, se video)
       ezért használunk img-t
  -->
  <img fetchpriority="high" alt="Vizsgaútvonal videó" style="position:absolute; width:100%;"
    src="assets/practical-sales-page/first_frame.webp">

  <!-- 
    oncanplay:
    https://stackoverflow.com/questions/47638344/muted-autoplay-in-chrome-still-not-working
  -->
  <video [controls]="false" preload="auto" #video class="video" oncanplay="this.muted=true" loop muted playsinline>
    <source src="assets/practical-sales-page/intro_video.mp4" type="video/mp4">
  </video>

  <app-lottie-animation [style.opacity]="this.video.paused ? '100%' : '0%'" class="play-button-on-video"
    animationPath="assets/practical-sales-page/pulse.json" [loop]="true">

  </app-lottie-animation>
</div>