import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LandingPageRoutingModule } from './landing-page-routing.module';
import { LandingPageComponent } from './landing-page.component';
import { LandingPracticeComponent } from './components/landing-practice/landing-practice.component';
import { LandingTheoryComponent } from './components/landing-theory/landing-theory.component';
import { LandingWelcomeComponent } from './components/landing-welcome/landing-welcome.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { LandingDownloadComponent } from './components/landing-download/landing-download.component';
import { StoreDownloadsComponent } from './components/store-downloads/store-downloads.component';
import { SharedModule } from '../../shared/shared.module';
import { ReviewItemComponent } from './components/reviews/review-item/review-item.component';
import { LandingTiktokVideosComponent } from './components/landing-tiktok-videos/landing-tiktok-videos.component';

@NgModule({
  declarations: [
    LandingPageComponent,
    LandingPracticeComponent,
    LandingTheoryComponent,
    LandingWelcomeComponent,
    ReviewsComponent,
    ReviewItemComponent,
    LandingDownloadComponent,
    StoreDownloadsComponent,
    LandingTiktokVideosComponent
  ],
  imports: [
    CommonModule,
    LandingPageRoutingModule,
    SharedModule
  ],
  exports: [
    LandingPageComponent,
    LandingPracticeComponent,
    LandingTheoryComponent
  ]
})
export class LandingPageModule { }
