<div class="main-container">
  <div
    *ngFor="let item of [].constructor(pageCount); let i = index"
  >
  <div
  class="circle"
  [style.background-color]="(currentPage|async) === i ? activeColor : inactiveColor"
  >
  </div>
  </div>
</div>