import { Component, OnInit } from '@angular/core';
import { Review } from '../../models/review';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: [
    './reviews.component.scss',
    '../../../../../styles/common-page-section-styles.scss'
  ]
})
export class ReviewsComponent implements OnInit {

  reviews:Array<Review> = [];

  constructor() { }

  public ngOnInit(): void {
    this.reviews = testReviews;
  }

}

const testReviews:Array<Review> = [
  {
    reviewerName: "Görgei Auróra Nelly",
    profileImageUrl: "assets/landing-page/review-profile-images/gan.webp",
    score: 5.0,
    content: "Szuper alkalmazás! 🙂 Kresz vizsgára készülök, ezen az appon gyakorlok, én nagyon szeretem! 🤗"
  },
  {
    reviewerName: "Pintér Attila",
    profileImageUrl: "assets/landing-page/review-profile-images/pa.webp",
    score: 5.0,
    content: "Nagyon szuper az alkalmazás, csak ajánlani tudom és fogom is mind tanuló, kezdő, és bizony a tapasztalt autóvezetőknek is.",
  },
  {
    reviewerName: "Horváth Andrea",
    profileImageUrl: "assets/landing-page/review-profile-images/ha.webp",
    score: 5.0,
    content: "Nagyon klassz kis app. Felfrissíti az alapokat, és gyakorlati kérdésekel is segít a rögzítésben. Jó rajzok, lényegretörő magyarázatok! 💯"
  },
  {
    reviewerName: "Bodó Zoltán",
    profileImageUrl: "assets/landing-page/review-profile-images/bz.webp",
    score: 5.0,
    content: "Életszerű szituációs kérdések, Pető úrtól megszokott világos magyarázattal!"
  }
]
