import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[blackButton]'
})
export class BlackButtonDirective {

  @HostBinding('class')
  elementClass = 'black-button';

  constructor() { }

}
