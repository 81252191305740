import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TiktokVideo } from 'src/app/models/tiktok-video.model';
import { clamp } from '../../../functions/misc-functions';

@Component({
  selector: 'app-tiktok-video-card',
  templateUrl: './tiktok-video-card.component.html',
  styleUrls: ['./tiktok-video-card.component.scss']
})
export class TiktokVideoCardComponent implements OnInit, OnDestroy {
  @Input() tiktokVideo: TiktokVideo;

  @ViewChild("rightSideContainer", { static: true })
  private rightSideContainer: ElementRef<HTMLDivElement>;

  @ViewChild("textContainer", { static: true })
  private textContainer: ElementRef<HTMLDivElement>;

  elementSizes: TiktokCardElementSizes;

  resizeObserver: ResizeObserver;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.resizeObserver = new ResizeObserver(this.onCardResize)
  }


  ngOnInit(): void {
    this.resizeObserver.observe(this.elementRef.nativeElement);
    const cardWidth = this.elementRef.nativeElement.clientWidth;
    this.elementSizes = this.calulateElementSizes(cardWidth);
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  /**
   * Handles the resizing event of the card and updates the sizes of the inner elements.
   */
  private onCardResize = () => {
    const cardWidth = this.elementRef.nativeElement.clientWidth;
    // Calculate the element sizes
    this.elementSizes = this.calulateElementSizes(cardWidth);
    // Update the UI with the newly calculated element sizes
    this.changeDetectorRef.detectChanges();
    // Update the card's border radius
    this.elementRef.nativeElement.style.borderRadius = this.elementSizes.bottomBarHeightInPx * 0.65 + "px";
    // Update the title and description container's width
    this.textContainer.nativeElement.style.width = this.getTextContainerElementWidth() + "px";
  }

  /**
   * Calculates the individual inner elements' sizes based on the provided card width.
   * 
   * @param cardWidth the width of the (entire) card
   * 
   * @returns `TiktokCardElementSizes` object which describes the individual element sizes
   */
  private calulateElementSizes(cardWidth: number): TiktokCardElementSizes {
    const rightSideIconSize: number = clamp(25, cardWidth * 0.1, 50);
    const rightSideCounterFontSize: number = clamp(10, cardWidth * 0.03, 15);

    const bottomBarIconSize: number = clamp(12, cardWidth * 0.035, 24);
    const bottomBarCounterFontize: number = clamp(11, cardWidth * 0.03, 20);

    return {
      profileImageSizeInPx: clamp(30, cardWidth * 0.128, 64),
      likesIconSizeInPx: rightSideIconSize,
      likesCounterFontSizeInPx: rightSideCounterFontSize,
      commentsIconSizeInPx: rightSideIconSize,
      commentsCounterFontSizeInPx: rightSideCounterFontSize,
      savesIconSizeInPx: rightSideIconSize,
      savesCounterFontSizeInPx: rightSideCounterFontSize,

      titleFontSizeInPx: clamp(11, cardWidth * 0.035, 24),
      publicationDateFontSizeInPx: clamp(10, cardWidth * 0.03, 20),
      desciptionFontSizeInPx: clamp(10, cardWidth * 0.03, 20),

      bottomBarHeightInPx: clamp(30, cardWidth * 0.1, 50),
      viewsIconSizeInPx: bottomBarIconSize,
      viewsCounterFontSizeInPx: bottomBarCounterFontize,
      sharesIconSizeInPx: bottomBarIconSize,
      sharesCounterFontSizeInPx: bottomBarCounterFontize
    };
  }

  /**
   * Determines the width for the container for the title and description. It's size is based on the
   * cards width, the container's left value, the right side containers width and right value, and the
   * gap between them.
   * 
   * @returns the supposed width of the container in pixels
   */
  private getTextContainerElementWidth(): number {
    // Get the text container's left value in pixels
    const titleAndDescriptionElementLeftValue: number =
    Number(window.getComputedStyle(this.textContainer.nativeElement).left.replace("px", ""));

    // Get the right side container's right value in pixels
    const rightSideContainerRightValue: number =
    Number(window.getComputedStyle(this.rightSideContainer.nativeElement).right.replace("px", ""));

    // Get the width of the right side container
    const rightSideContainerWidth: number =
    Number(window.getComputedStyle(this.rightSideContainer.nativeElement).width.replace("px", ""));

    // Get the gap between the former containers
    const gap: number = 10;

    // Calculate the text container's size
    const resultElementSize: number = this.elementRef.nativeElement.clientWidth - (
      titleAndDescriptionElementLeftValue +
      gap +
      rightSideContainerWidth +
      rightSideContainerRightValue
    );

    return resultElementSize;
  }
}

type TiktokCardElementSizes = {
  profileImageSizeInPx: number;
  likesIconSizeInPx: number;
  likesCounterFontSizeInPx: number;
  commentsIconSizeInPx: number;
  commentsCounterFontSizeInPx: number;
  savesIconSizeInPx: number;
  savesCounterFontSizeInPx: number;

  titleFontSizeInPx: number;
  publicationDateFontSizeInPx: number;
  desciptionFontSizeInPx: number;

  bottomBarHeightInPx: number;
  viewsIconSizeInPx: number;
  viewsCounterFontSizeInPx: number;
  sharesIconSizeInPx: number;
  sharesCounterFontSizeInPx: number;
}

