import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { practicalModuleYoutubeVideoUrl } from 'src/app/constants/youtube-video-urls';

@Component({
  selector: 'app-landing-practice',
  templateUrl: './landing-practice.component.html',
  styleUrls: [
    './landing-practice.component.scss',
    '../../../../../styles/common-page-section-styles.scss'
  ]
})
export class LandingPracticeComponent implements OnInit, AfterViewInit {

  @Output() renderedTitle:EventEmitter<HTMLDivElement> = new EventEmitter<HTMLDivElement>();

  @ViewChild("title") titleElement:ElementRef<HTMLDivElement>;

  readonly youtubeVideoUrl:string = practicalModuleYoutubeVideoUrl;

  constructor(
    private routerService:Router
  ) { }

  public ngOnInit():void { }

  public ngAfterViewInit():void {
    this.renderedTitle.emit(this.titleElement.nativeElement);
  }

  public navigateTo(route:string) {
    this.routerService.navigate([ route ]);
  }
}
