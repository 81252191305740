import { ApplicationRef, ChangeDetectorRef, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn:'root'
})
export class HeaderService {
  constructor(private changeDetector: ApplicationRef){
  
  }

  
  public colorMode: BehaviorSubject<'dark' | 'light'> = new BehaviorSubject('light');

  public isDarkMode(){
    return this.colorMode.value === 'dark';
  }

  public setColorMode(colorMode: 'dark' | 'light'){
    this.colorMode.next(colorMode);
  }
}