import { Component, EventEmitter, Inject, inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PurchasablePackage } from '../../../models/purchasable-package';

@Component({
  selector: 'app-cross-selling-row',
  templateUrl: './cross-selling-row.component.html',
  styleUrls: ['./cross-selling-row.component.scss']
})
export class CrossSellingRowComponent implements OnInit {
  @Input() 
  protected purchasablePackageToCrossSell: PurchasablePackage;

  @Input()
  protected isChecked: boolean = false;

  @Output()
  protected isCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onChangeCheckbox(event: any){
    this.isChecked = event.checked;
    this.isCheckedChange.emit(this.isChecked);
  }

  toggleCheckbox(){
    this.isChecked = !this.isChecked;
    this.isCheckedChange.emit(this.isChecked);
  }

}
