import { Injectable } from '@angular/core';
import { PackageDefinition } from '../models/purchasable-package';
import { BackendService } from 'src/app/services/common/backend.service';

@Injectable({
  providedIn:'root'
})
export class PackageDefinitionApiService {
  private readonly publicPackagesApiFragment: string = "/api/public/purchasable_packages";

  constructor(
    private backendService: BackendService
  ) { }

  /**
   * Gets the publicly available packages from the server
   *
   * @returns list of the publicly available packages
   */
  public async fetchPackageDefinitions(): Promise<{ packageDefintiions: Array<PackageDefinition>, groups: { practical: string[], theory: string[], other: string[] } }> {
    let packageDefinitions: Array<PackageDefinition> = [];

    try {
      const response: {
        packageDefinitions: Array<PackageDefinition>, groups: { practical: string[], theory: string[], other: string[] }
      } = await this.backendService.callApi(this.publicPackagesApiFragment, "GET", { useDefaultErrorHandlers: false });

      return {
        packageDefintiions: response.packageDefinitions,
        groups: response.groups
        };
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }
}

