<div class = "mockup-container">
    <img alt="" src = "assets/landing-page/landing-practice-mockup.webp" class = "mockup" draggable = "false" />
</div>
<div class = "main-container">
    <div #title class = "section-title-big">
        Vizsgaútvonalak
        <div class = "title-break-outer">
            virtuális
            <div style = "display: inline-block; white-space: nowrap;">
                bejárása?
                <img alt="" src = "assets/landing-page/emojis/exploding-head.webp" draggable = "false" class = "title-emoji">
            </div>
        </div>
    </div>
    <div class = "subtitle">
        Tekintsd meg az összes vizsgaútvonalról készült videót kiváló minőségben abban a városban, ahol vizsgázol!
    </div>
    <div class = "video-placeholder-image-container">
        <app-openable-youtube-video-preview
            [imageSrc] = "'assets/landing-page/landing-practice-video-preview.webp'"
            [videoUrl] = "youtubeVideoUrl"
        ></app-openable-youtube-video-preview>
    </div>
    <div class = "details-button-wrapper">
        <app-details-button
            (click) = "navigateTo('/vizsgautvonalak-videon')"
        >
            <span class  = "details-button-text">
                Tudj meg többet
            </span>
        </app-details-button>
    </div>

</div>
