import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-page-indicator',
  templateUrl: './page-indicator.component.html',
  styleUrls: ['./page-indicator.component.scss']
})
export class PageIndicatorComponent implements OnInit, OnChanges{
  @Input() pageCount: number;
  @Input() currentPage: Observable<number>;

  @Input() inactiveColor: string;
  @Input() activeColor: string;


  public shit: number = -1;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    // TODO: átnézni hogy milyen hiba van még ebben a komponensben
    throw Error("BAD IMPLEMENTATION");
  }


  ngOnChanges(simpleChanges: SimpleChanges){
    if(simpleChanges['currentPage']?.currentValue != simpleChanges['currentPage']?.previousValue){
      simpleChanges['currentPage']?.previousValue?.unsubscribe();
      this.subscribeToCurrentPage();
    }
  }

  ngOnInit(): void {

  }

  subscribeToCurrentPage(){
    this.currentPage.subscribe((value: number) => {
      setTimeout(() => {
        this.changeDetectorRef.detectChanges();
      }); 
    });
  }
}
