<div class = "store">
    <a href = "https://play.google.com/store/apps/details?id=com.mrkresz.app">
        <img alt="Play áruház" src = "assets/shared/stores/google-play.webp" class = "store-badge" draggable = "false" />
    </a>
    <div class = "store-rating">
        <div #googlePlayStars class = "store-stars">
            <img alt="" draggable="false" *ngFor="let imgSource of getStarImagesFromRating(googlePlayRating)" [src]="imgSource">
            <!-- The stars goes here -->
        </div>
        <div class = "store-rating-value">
            {{ googlePlayRating }}
        </div>
    </div>
</div>
    <div class = "store">
    <a href = "https://apps.apple.com/us/app/mr-kresz/id1532242609">
        <img alt="App Store" src = "assets/shared/stores/app-store.webp" class = "store-badge" draggable = "false" />
    </a>
    <div class = "store-rating">
        <div #appStoreStars class = "store-stars">
            <img alt="" draggable="false" *ngFor="let imgSource of getStarImagesFromRating(appStoreRating)" [src]="imgSource">
        </div>
        <div class = "store-rating-value">
            {{ appStoreRating }}
        </div>
    </div>
</div>
