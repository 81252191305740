import { animate, style, transition, trigger } from '@angular/animations';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, ElementRef, Inject, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import gsap from 'gsap';
import { CustomValidators } from 'src/app/misc/custom-validators';
import { BackendServiceException } from 'src/app/services/common/backend.service';
import { PurchasablePackage } from '../../models/purchasable-package';
import { PackageResolvingErrorDialogComponent } from '../package-resolving-error-dialog/package-resolving-error-dialog.component';
import confetti from 'canvas-confetti';

export type PurchaseDialogComponentInputData = {
  package: PurchasablePackage,
  grossPrice: number,
  grossPriceWithDiscount: number,
  initCustomerFacingPromotionCode?: string,
  goToCheckoutHandler: GoToCheckoutHandler;
  crossSellingPackages: Array<PurchasablePackage>,
}

export type GoToCheckoutHandler = (
  purchasablePackage: PurchasablePackage,
  crossSellingPackages: Array<PurchasablePackage>,
  email: string,
  isGift: boolean,
  initCustomerFacingPromotionCode?: string
) => Promise<void>;

@Component({
  selector: 'app-purchase-dialog',
  templateUrl: './purchase-dialog.component.html',
  styleUrls: ['./purchase-dialog.component.scss']
})
export class PurchaseDialogComponent implements OnInit {
  emailFC: FormControl<string>;
  isGift: boolean = false;
  isCheckoutRequestLoading: boolean = false;

  // Bepipált cross sell package, ezt is továbbítjuk vásárlásra
  // a checkout létrehozáskor
  addedCrossSellPackages: Array<PurchasablePackage> = [];

  @ViewChildren("crossSellingRow", { read: ElementRef }) crossSellingRowElements: QueryList<ElementRef>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PurchaseDialogComponentInputData,
    private dialogService: MatDialog,
    private ngZone: NgZone,
  ) { }


  // type safe opening
  static open(dialogService: MatDialog, data: PurchaseDialogComponentInputData): MatDialogRef<PurchaseDialogComponent, void> {
    const dialogRef = dialogService.open(PurchaseDialogComponent, {
      data: data,
      maxHeight: 'calc(100vh - 50px)',
      maxWidth: '100vw',
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      hasBackdrop: true,
      backdropClass: 'invisible-backdrop'
    });

    return dialogRef;
  }

  ngOnInit(): void {
    this.emailFC = new FormControl<string>("", [Validators.required, CustomValidators.email]);
  }

  ngAfterViewInit(): void {

  }


  crossSellCheckboxChange(isChecked: boolean, packageDefinitionUuidOfCrossSellPackage: string) {
    console.log(isChecked, packageDefinitionUuidOfCrossSellPackage);
    if (!isChecked) {
      this.addedCrossSellPackages = this.addedCrossSellPackages.filter(p => p.packageDefinitionUuid != packageDefinitionUuidOfCrossSellPackage);
    } else {
      const packageToCrossSell = this.data.crossSellingPackages.find(p => p.packageDefinitionUuid == packageDefinitionUuidOfCrossSellPackage);
      this.addedCrossSellPackages.push(packageToCrossSell);
    }

    // Nézzük meg, hogy az összes be van-e pipálva
    // ha igen ,akkor confetti!
    if (isChecked) {
      const allChecked = this.data.crossSellingPackages.some(p => !this.addedCrossSellPackages.some(p2 => p2.packageDefinitionUuid == p.packageDefinitionUuid)) == false;

      setTimeout(() => {
        this.ngZone.runOutsideAngular(() => {
          confetti({
            particleCount: 30,
            spread: 50,
            ticks: 150,
            zIndex: 100000000
          });
        });
      }, 100);



    }
  }


  /**
   * Requests the server to create a Stripe checkout. If everything is allright, the service redirects to the Stripe's page.
   *
   * @returns nothing
   */
  public async handleCheckoutButtonClick(): Promise<void> {
    // ha valid akkor fogok kuldeni
    if (this.emailFC.invalid) {
      this.emailFC.markAsTouched();
      return;
    }

    this.isCheckoutRequestLoading = true;

    const normalizedEmail = this.emailFC.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    try {
      await this.data.goToCheckoutHandler(this.data.package, this.addedCrossSellPackages,normalizedEmail, this.isGift, this.data.initCustomerFacingPromotionCode);
    } catch (exception: any) {
      console.error(exception);
      let msg = "";

      if (exception.error?.error == "PROMOTION_CODE_INACTIVE") {
        msg = "A promóciós kódot már beváltották vagy lejárt!";
      } else if (exception.error?.error == "PROMOTION_CODE_EXPIRED") {
        msg = "A promóciós kód lejárt!";
      } else if (exception.error?.error == "NOT_FOUND_PROMOTION_CODE") {
        msg = "A promóciós kód nem található!";
      } else if (exception.error?.error == "VENDOR_NOT_READY_FOR_SALES") {
        msg = "Az oktató/autósiskola még nem regisztrált be teljesen! Kérlek hívd fel a figyelmét erre, mert csak utána fogsz tudni vásárolni tőle!";
      }

      else {
        msg = exception === BackendServiceException.NO_INTERNET_CONNECTION ?
          "Ellenőrizd az internetkapcsolatod!" : "Nézz vissza kicsit később!";
      }

      this.dialogService.open(
        PackageResolvingErrorDialogComponent,
        {
          data: {
            line1: "Hiba történt a fizetés létrehozása közben. Töltsd újra az oldalt!",
            line2: msg
          },
          disableClose: true,
          scrollStrategy: new NoopScrollStrategy(),
          hasBackdrop: true,
          backdropClass: 'invisible-backdrop'
        }
      );
      this.isCheckoutRequestLoading = false;
    }
  }
}
