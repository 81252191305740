<div class="main-container">
  <div class="text-wrapper">

    <span class="headline">
      Szakoktatók véleménye
    </span>
  
  
    <div style="margin-top:20px;">
      <span class="subtext">
        <span class="highlight">Mit gondolnak</span> az applikáció forgalmi moduljáról a <span
          class="highlight">tapasztalt szakoktatók</span>?
      </span>
    </div>
  </div>
  
  
  <div #listsWrapper class="lists-wrapper">
    <app-instructor-review-horizontal-list #reviewList1 style="margin-top:30px;" class="horizontal-list-1"
      [reviewItems]="this.reviews" [scrollPositionLeft]="scrollProperties.scrollPositionList1">
  
    </app-instructor-review-horizontal-list>
  
    <app-instructor-review-horizontal-list #reviewList2 style="margin-top:30px;" class="horizontal-list-1"
      [reviewItems]="this.reviews2" [scrollPositionLeft]="scrollProperties.scrollPositionList2">
  
    </app-instructor-review-horizontal-list>
  </div>
</div>

