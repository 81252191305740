<a
  [href]="tiktokVideo.videoUrl"
  target="_blank"
  class="link"
  draggable="false"
>
  <div class="card">
    <div
      class="main-container" 
    >
      <img
        alt="TikTok Mr. Kresz borítókép"
        [src]="tiktokVideo.thumbnailUrl"
        class="thumbnail-image"
        draggable="false"
      />

      <div class="opacity-overlay">
        <!-- Empty div to make the thumbnail image darker -->
      </div>

      <!-- Right side: profile, likes, comments, saves -->
      <div #rightSideContainer class="right-side-container">
        <!-- Profile -->
        <div
          class="profile-image-container"
          [style.width]="elementSizes.profileImageSizeInPx + 'px'"
          [style.height]="elementSizes.profileImageSizeInPx + 'px'"
        >
          <img
            alt="TikTok Mr. Kresz profilkép"
            src="assets/shared/mrkresz-logo.webp"
            class="profile-image"
            draggable="false"
          >
        </div>

        <!-- Likes -->
        <div class="likes-container">
          <img
            alt="Lájk ikon"
            src="assets/shared/svg/tiktok/likes.svg"
            class="likes-icon"
            [style.width]="elementSizes.likesIconSizeInPx + 'px'"
          >
          <div

            class="likes-counter"
            [style.font-size]="elementSizes.likesCounterFontSizeInPx + 'px'"
          >
            {{ tiktokVideo.numberOfLikes | numberShortFormNotation }}
          </div>
        </div>

        <!-- Comments -->
        <div class="comments-container">
          <img
            alt="Komment ikon"
            src="assets/shared/svg/tiktok/comments.svg"
            class="comments-icon"
            [style.width]="elementSizes.commentsIconSizeInPx + 'px'"
          >
          <div
            class="comments-counter"
            [style.font-size]="elementSizes.commentsCounterFontSizeInPx + 'px'"
          >
            {{ tiktokVideo.numberOfComments | numberShortFormNotation }}
          </div>
        </div>

        <!-- Saves -->
        <div class="saves-container">
          <img
            alt="Mentés"
            src="assets/shared/svg/tiktok/saves.svg"
            class="saves-icon"
            [style.width]="elementSizes.savesIconSizeInPx + 'px'"
          >
          <div
            class="saves-counter"
            [style.font-size]="elementSizes.savesCounterFontSizeInPx + 'px'"
          >
            {{ tiktokVideo.numberOfSaves | numberShortFormNotation }}
          </div>
        </div>
      </div>

      <!-- Bottom: title, description -->
      <div #textContainer class="text-container">
        <div class="title-and-date-container">
          <div
            class="title"
            [style.font-size]="elementSizes.titleFontSizeInPx + 'px'"
          >
            {{ tiktokVideo.title }}
          </div>
          <div
            class="publication-date"
            [style.font-size]="elementSizes.publicationDateFontSizeInPx + 'px'"
          >
            {{ tiktokVideo.publicationTimestamp | date: "YYYY-MM-dd" }}
          </div>
        </div>
        <div
          class="description"
          [style.font-size]="elementSizes.desciptionFontSizeInPx + 'px'"
        >
          {{ tiktokVideo.description }}
        </div>
      </div>
    </div>

    <!-- Views and shares -->
    <div
      class="views-and-shares-container"
      [style.height]="elementSizes.bottomBarHeightInPx + 'px'"
    >
      <!-- Views -->
      <div class="views-container">
        <img
          alt="Megtekintés ikon"
          src="assets/shared/svg/tiktok/views.svg"
          class="views-icon"
          [style.width]="elementSizes.viewsIconSizeInPx + 'px'"
        />
        <div
          class="views-counter"
          [style.font-size]="elementSizes.viewsCounterFontSizeInPx + 'px'"
        >
          {{ tiktokVideo.numberOfViews | numberShortFormNotation }}
        </div>
      </div>

      <!-- Shares -->
      <div class="shares-container">
        <img
          alt="Megosztás ikon"
          src="assets/shared/svg/tiktok/shares.svg"
          class="shares-icon"
          [style.width]="elementSizes.sharesIconSizeInPx + 'px'"
        />
        <div
          class="shares-counter"
          [style.font-size]="elementSizes.sharesCounterFontSizeInPx + 'px'"
        >
          {{ tiktokVideo.numberOfShares | numberShortFormNotation }}
        </div>
      </div>
    </div>
  </div>
</a>