<div class = "title">
    Fejlesztés <br class = "title-break" /> alatt
</div>

<div class = "animation-container">
    <app-lottie-animation
        [animationPath] = "'assets/shared/lottie/under-construction.json'"
        [scale] = "1.2"
        [loop] = "true"
    ></app-lottie-animation>
</div>
