<ng-template [ngIf] = "displayedMediaType === 'NONE'">
    <div class = "none-text">
        {{ noneText }}
    </div>
</ng-template>

<ng-template [ngIf] = "displayedMediaType === 'IMAGE'">
    <img alt="" [src] = "getSanitizedUrl(fileUrl)" class = "image-preview" />
</ng-template>

<ng-template [ngIf] = "displayedMediaType === 'PDF'">
    <b style="white-space:break-spaces;">Kattints a csatlakozásra! </b> ✅ 
<!--     <pdf-viewer
        [src] = "fileUrl"
        [original-size]="true"
        [fit-to-page]="true"
        [zoom]="1"
        [zoom-scale] = "'page-fit'"
        [show-all]="false"
        class = "pdf-viewer"
    ></pdf-viewer> -->
</ng-template>

<ng-template [ngIf] = "displayedMediaType === 'UNSUPPORTED'">
    <div class = "unsupported-text">
        Nem támogatott fájl típus.
    </div>
</ng-template>

<input #fileInput style = "display: none;" type = "file" [accept] = "this.acceptableFileTypes | joinArrayString:','" />
