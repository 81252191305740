import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-exam-guarantee-section',
  templateUrl: './exam-guarantee-section.component.html',
  styleUrls: ['./exam-guarantee-section.component.scss']
})
export class ExamGuaranteeSectionComponent implements OnInit {

  @Output()
  purchaseClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
