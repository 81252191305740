import { Component, OnInit } from '@angular/core';
import { companyTelephoneNumber, companyTelephoneNumberDisplayed, infoEmailAddress } from 'src/app/constants/company-data';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  emailAddress: string = infoEmailAddress;

  phoneNumber: string = companyTelephoneNumber;

  displayedPhoneNumber: string = companyTelephoneNumberDisplayed;

  year: number;

  constructor() { }

  ngOnInit(): void {
    this.emailAddress = "info@mrkresz.hu"
    this.year = new Date().getFullYear();
  }

}
