import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, SkipSelf, ViewChild } from '@angular/core';
import { TidioChatService } from 'src/app/services/common/tidio-service';
import { awaitFor } from '../../shared/functions/misc-functions';
import gsap from 'gsap';

// import scroll trigger and register
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { HeaderService } from 'src/app/components/header/header.component.service';
import { Subscribable, Subscription } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { PromotionCodeApiService } from '../pricing-page/services/promotion-code-api.service';
import { PromotionCode } from '../pricing-page/models/promotion-code';
import { ToastrService } from 'ngx-toastr';
import { PlatformService } from 'src/app/services/common/platform-service';
import { MatDialog } from '@angular/material/dialog';
gsap.registerPlugin(ScrollTrigger);

@Component({

  selector: 'app-practical-sales-page',
  templateUrl: './practical-sales-page.component.html',
  styleUrls: ['./practical-sales-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PracticalSalesPageComponent implements OnInit, OnDestroy {
  shouldVisibleLoadingScreen: boolean = true;

  @ViewChild("purchaseSection", { read: ElementRef }) purchaseSection: ElementRef;
  @ViewChild('dontTeachComponent', { read: ElementRef }) dontTeachComponent: ElementRef;
  @ViewChild("couponSection", { read: ElementRef }) couponSection: ElementRef;


  appliedPromotionCode: PromotionCode | null;

  private subscriptions: Subscription[] = [];



  constructor(
    protected tidioService: TidioChatService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected headerService: HeaderService,
    protected viewportScroller: ViewportScroller,
    protected promoCodeApi: PromotionCodeApiService,
    private toastr: ToastrService,
    protected platformService: PlatformService,
    private matDialog: MatDialog
  ) {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    if (this.platformService.getIsPlatformBrowser()) {
      window.removeEventListener("resize", this.onResizeWindow);
    }

    // AZ ÖSSZES SCROLL TRIGGER-T töröljük
    // fontos, hogy ha máshol használunk scroll trigger-t (sales page-en kívül)
    // akkor ez törölni fogja
    ScrollTrigger.getAll().forEach(t => t.kill());
  }



  // Valahol a MEGVESZEM gombra kattintott
  // navigáljuk legalulra az ajándék kaparáshoz (alatta van egyből a vásárlás)
  onTapPurchaseButton() {
    // TODO: átírni kaparósra
    const target = this.couponSection.nativeElement as HTMLElement;

    gsap.to(window, {
      duration: 2,
      scrollTo: {
        y: target,
        offsetY: 30
      },
      ease: "power2.out"
    });

  }

  // Ha elhagyjuk a route-ot visszaállítjuk light-ra a menü ikont
  addNavigatorSubscription() {
    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.headerService.setColorMode("light");
        }
      }));
  }

  changeHeaderColorToDark() {
    this.headerService.setColorMode("dark");
  }

  ngOnInit() {
    if (this.platformService.getIsPlatformBrowser()) {
      this.addWindowResizeListener();
    }

    this.headerService.setColorMode("dark");
  }


  ngAfterViewInit() {
    this.addNavigatorSubscription();
  }

  onResizeWindow = () => {
    this.changeDetectorRef.detectChanges();
    // Ha változik a layout meg kell hívni, hogy újra kiszámolja a start-end position-t
    ScrollTrigger.refresh(true);
  };

  addWindowResizeListener() {
    window.addEventListener('resize', this.onResizeWindow);
  }



  async promotionCodeRedeemRequest(promoCode: string) {
    console.log("promotionCodeRedeemRequest");

    const fetchedPromotionCode = await this.promoCodeApi.fetchPromotionCode(promoCode);

    if (this.promoCodeApi.isPromotionCodeExpired(fetchedPromotionCode)) {
      this.toastr.error('Ez a kód sajnos lejárt, de írj az ügyfélszolgálatra és adunk egy másikat! 😉', 'Lejárt kód');
      return;
    } else {
      this.appliedPromotionCode = fetchedPromotionCode;
      this.toastr.success('Már csak egy lépés, és garantáltan magabiztosan mész a vizsgádra! 😉', 'Sikeres beváltás 🎉');
    }

    this.changeDetectorRef.detectChanges();
  }


  changeDet() {
    console.info("Change detection: PracticalSalesPageComponent");
  }

}
