import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { PlatformService } from 'src/app/services/common/platform-service';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);


declare let Zone: any;

@Component({
  selector: 'app-intro-section',
  templateUrl: './intro-section.component.html',
  styleUrls: ['./intro-section.component.scss']
})
export class IntroSectionComponent implements OnInit {
  @ViewChild("headlineText", { read: ElementRef }) headlineText: ElementRef<HTMLElement>;
  @ViewChild("descriptionText", { read: ElementRef }) descriptionText: ElementRef<HTMLElement>;
  @ViewChild("actionButton", { read: ElementRef }) actionButton: ElementRef;

  @ViewChild("mockup", { read: ElementRef, static: true }) mockup: ElementRef<HTMLElement>;


  @ViewChild("video", { read: ElementRef, static: true })
  video: ElementRef<HTMLVideoElement>;


  constructor(
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private platformService: PlatformService
  ) { }

  ngOnInit(

  ): void {

  }


  printCurrentZone() {
    if (this.platformService.getIsPlatformBrowser()) {
      console.log("Current zone", (Zone as any).current.name);
    }
  }

  async ngAfterViewInit() {
    // run outside angular zone, scroll trigger túl sokszor hívna change detection-t
    if (this.platformService.getIsPlatformBrowser()) {
      this.ngZone.runOutsideAngular(() => {
        this.addScrollTriggerToPhone();
        this.addScrollTriggerToVideo();
      });
      setTimeout(() => {
        ScrollTrigger.refresh(false);
      }, 100);
    }
  }

  // A video meg van jelenítve (tehát nem laptop-on vagyunk)
  isVideoDisplayed() {
    if (this.platformService.getIsPlatformServer()) return false;
    const doc = document.querySelector('.video-below-gradient') as HTMLElement;
    const computedStyle = window.getComputedStyle(doc);
    return computedStyle.display != "none";
  }

  async onTapShow() {
    const isVideoDisplayed = this.isVideoDisplayed();

    if (isVideoDisplayed) {
      const videoHeight = this.video.nativeElement.getBoundingClientRect().height;

      gsap.to(window, { duration: 1.2, scrollTo: { y: this.video.nativeElement.getBoundingClientRect().top + window.scrollY + videoHeight * 0.15, autoKill: false }, ease: "power3.out" });
      this.playVideo();
    } else {
      // egyébként ha nincs videó akkor a következő szekcióra ugrunk
      const alternative = document.querySelector('app-exam-guarantee-section');
      console.log(alternative);
      gsap.to(window, { duration: 1.2, scrollTo: { y: alternative.getBoundingClientRect().top + window.scrollY + -30, autoKill: false }, ease: "power3.out" });
    }
  }

  playVideo() {
    this.video.nativeElement.play();
    this.changeDetectorRef.detectChanges(); // ngIf Frissüljön, nem kell play button már
  }

  addScrollTriggerToVideo() {
    console.log(this.video.nativeElement);
    const scrollTrigger = ScrollTrigger.create({
      trigger: this.video.nativeElement,
      start: "top top",
      onEnter: () => {
        // ezt a triggert angular zone-on kívül adjuk hozzá, ezért vissza kell jönnünk
        this.ngZone.run(() => {
          this.playVideo();
        });
      }
    });
  }

  addScrollTriggerToPhone() {
    console.log("add phone scroll trigger");
    // create new scroll trigger
    // start after end of object
    const scrollTrigger = ScrollTrigger.create({
      trigger: this.mockup.nativeElement,
      // Akkor kezdődik, amikor a trigger-nek az alja a viewport 20%-nál van
      start: "bottom 50%",
      onLeaveBack: () => {
        gsap.to(this.mockup.nativeElement, {
          ease: "power3.out", opacity: 1.0,
          duration: 0.8,
          x: 0,
          y: 0,
        });
      },
      onEnter: () => {
        console.log("ENTER!");
        gsap.to(this.mockup.nativeElement, {
          ease: "power3.out", opacity: 0.0,
          duration: 0.85,
          x: 45,
          y: -5
        });
      },
      toggleActions: "none none none none",
    });
  }
}
