<div #mainWrapper class="main-wrapper">
  <!-- 
  
  -->

  <div class="grid">
    <img loading="lazy" alt="Félsz a vizsgától?" class="img on-mobile" src="assets/practical-sales-page/scared.webp">
    <img loading="lazy" alt="Félsz a vizsgától?" class="img on-laptop" src="assets/practical-sales-page/laptop/women_laptop.webp">



    <div class="headline-button-wrapper">

      <h2 class="headline" #headlineInactive>
        Félsz, hogy a<br>vizsgán olyan helyre<br>mentek, ahol még nem jártál?
      </h2>

      <p class="subtext">
        Egy városban 700+ forgalmi vizsga buktató van... 
        Nyugi, a <b>videókban mindent elmagyarázunk</b>, nem kell aggódnod! 😌
      </p>


      <button (click)="purchaseClicked.emit();" class="purchase-button">
        MEGVESZEM
      </button>

    </div>
  </div>




</div>