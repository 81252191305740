export function MockedImplementation() {
  return (target: Function) => {
      for (const propertyName of Object.getOwnPropertyNames(target.prototype)) {

          const descriptor = Object.getOwnPropertyDescriptor(target.prototype, propertyName);

          if (!descriptor) {
              continue;
          }

          const originalMethod = descriptor.value;

          const isMethod = originalMethod instanceof Function;

          if (!isMethod) {
              continue;
          }

          descriptor.value = function(...args: any[]) {
              console.log(`[${target.name}][${propertyName}] Entering mocked function 🤖`);
              const result = originalMethod.apply(this, args);
              return result;
          };

          Object.defineProperty(target.prototype, propertyName, descriptor);
      }
  };
}