<div class = "animation-container">
    <app-lottie-animation
        [animationPath] = "'assets/shared/lottie/fail.json'"
        [scale] = "2"
    ></app-lottie-animation>
</div>
<div class = "text">
    {{ data.displayedText }}
</div>
<div class = "close-button-container">
    <button
        blackButton
        class = "dialog-close-button"
        mat-dialog-close
    >
        Bezár
    </button>
</div>