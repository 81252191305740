
<div class="main-container">
  <div class="grid">
    <div class="text-part-wrapper">
      <div style="margin-top:40px;">
        <h2 #headlineText class="headline">
          Mit várhatsz az applikációtól?
        </h2>
    
        <div style="margin-top:30px;">
          <span class="subtext">
            Bár a kuplungkezelést nem tudjuk neked megmutatni, de
          </span>
        </div>
    
    
    
        <div class="text-items-wrapper">
          <div class="text-item" *ngFor="let item of this.textLineItems">
            <div class="text-item-checkmark">
              <mat-icon>check</mat-icon>
            </div>
            <span class="subtext" [innerHTML]="item">
            </span>
          </div>
        </div>
      </div>
  
      <button (click)="purchaseClicked.emit()" class="purchase-button">
        MEGVESZEM
      </button>
    </div>
  
  
    
    <div class="girl-image-wrapper visible-max-tablet">
      <img loading="lazy" alt="Vizsga garancia" src="/assets/practical-sales-page/girls.webp" class="img">
    </div>
    <div class="girl-image-wrapper visible-min-laptop">
      <img loading="lazy" alt="Vizsga garancia" src="/assets/practical-sales-page/laptop/cabrio.webp" class="img">
    </div>

  </div>
  
</div>



