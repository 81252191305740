import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PracticalSalesPageComponent } from './practical-sales-page.component';

const routes: Routes = [
  { path: '', component: PracticalSalesPageComponent }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class PracticalSalesPageRoutingModule { }
