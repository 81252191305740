{{changeDet()}}


<div>
  <app-intro-section>
  </app-intro-section>


  <app-exam-guarantee-section (purchaseClicked)="this.onTapPurchaseButton()">
  </app-exam-guarantee-section>


  <app-showcase-with-phone-section *ngIf="this.platformService.getIsPlatformBrowser()"
    (purchaseClicked)="this.onTapPurchaseButton()">
  </app-showcase-with-phone-section>

  <app-dont-teach-but #dontTeachComponent (purchaseClicked)="this.onTapPurchaseButton()">

  </app-dont-teach-but>

  <app-instructor-reviews style="margin-top:50px;">
  </app-instructor-reviews>

  <app-tiktok *ngIf="this.platformService.getIsPlatformBrowser()">
  </app-tiktok>

  <app-are-you-scared (purchaseClicked)="this.onTapPurchaseButton()">
  </app-are-you-scared>

  <app-available-cities ngSkipHydration>
  </app-available-cities>

  <app-coupon-section (onPromotionCodeRedeemRequest)="promotionCodeRedeemRequest($event)" #couponSection>
  </app-coupon-section>

  <app-purchase-section *ngIf="platformService.getIsPlatformBrowser()" [promotionCode]="appliedPromotionCode"
    #purchaseSection>
  </app-purchase-section>

</div>